import React from 'react';
import TranslateQuestion from './TranslateQuestion.js';
import './TranslateQuiz.css';
import Progress  from '../Progress.js';
import { Link } from "react-router-dom";

export default class TranslateQuiz extends React.Component {
	constructor(props) {
		super(props);

		this.updateQuizProgress = this.updateQuizProgress.bind(this);
		this.nextQuestion = this.nextQuestion.bind(this);
		this.renderQuestion = this.renderQuestion.bind(this);
		this.restartQuiz = this.restartQuiz.bind(this);

		this.state = {
			questions : [{
	sentence : "Me firi Ghana",
	correctAnswer : ["I", "am", "from", "Ghana"],
	choices : [
			{text: "I", position: 0, audio_url:""},
			{text: "from", position: 1, audio_url:""},
			{text: "when", position: 2, audio_url:""},
			{text: "can", position: 3, audio_url:""},
			{text : "am", position: 4, audio_url:""},
			{text: "Ghana", position: 5, audio_url:""}],
},
{
	sentence : "Me din de George",
	correctAnswer : ["My", "name", "is", "George"],
	choices : [
			{text: "I", position: 0, audio_url:""},
			{text: "is", position: 1,  audio_url:""},
			{text: "name", position: 2,  audio_url:""},
			{text: "My", position: 3, audio_url:""},
			{text : "am", position: 4,  audio_url:""},
			{text: "George", position: 5,  audio_url:""}],
},
{
	sentence : "Wo firi he?",
	correctAnswer : ["Where", "are", "you", "from", "?"],
	choices : [
			{text: "where", position: 0 , audio_url:""},
			{text: "from", position: 1 , audio_url:""},
			{text: "when", position: 2 , audio_url:""},
			{text: "are", position: 3 , audio_url:""},
			{text: "you", position: 4 , audio_url:""},
			{text: "we", position: 5,  audio_url:""},
			{text: "?", position: 6, audio_url:""}],
},
{
	sentence : "Me firi DR",
	correctAnswer : ["I", "am", "from", "DR"],
	choices : [
			{text: "I", position: 0,  audio_url:""},
			{text: "from", position: 1, audio_url:""},
			{text: "when", position: 2, audio_url:""},
			{text: "can", position: 3, audio_url:""},
			{text : "am", position: 4, audio_url:""},
			{text: "DR", position: 5, audio_url:""}],
},],
			progress : 0,
			numCorrect: 0,
			currentQuestion : 0,
			bogusState: false,
		}
	}

	restartQuiz() {
		this.setState({
			progress: 0,
			numCorrect: 0,
			currentQuestion: 0,
		})
	}

	renderQuestion(question) {
		return (<TranslateQuestion
			sentence = { question.sentence }
			correctAnswer = { question.correctAnswer }
			selectableChoices = { question.choices }
			submitAnswer = { this.updateQuizProgress }
			skipQuestion = { this.nextQuestion }
		/>)
	}

	nextQuestion() {
		this.setState({
			currentQuestion : this.state.currentQuestion + 1,
			progress: this.state.progress + 1,
		});
	}

	updateQuizProgress(isCorrect) {

		if (isCorrect) {
			this.setState({numCorrect : this.state.numCorrect + 1});
			// this.setState({bogusState : true});
		}

		this.setState({
			progress : this.state.progress + 1,
			currentQuestion : this.state.currentQuestion + 1,
		});
	}



	render() {
		const currentQuestion = this.state.currentQuestion;
		const totalQuestions = this.state.questions.length;
		if (currentQuestion > this.state.questions.length - 1) {
			return (
				<div id="quiz-container">
					<h1>Quiz completed!</h1>
					<div id="button_options">
					<button id="buttonstyle" onClick={this.restartQuiz} > Retry </button>
					<button id="buttonstyle" ><Link to={"select_course"} style={{ textDecoration: 'none' }}> Continue </Link></button>
					</div>
				</div>
				)
		}

		const question = this.renderQuestion(this.state.questions[currentQuestion]);
		return (
			<div id="quiz-container">
			<div id="quiz-header">
				<Progress totalQuestions = {totalQuestions} currentQuestion = {currentQuestion}/>

				<h4> Correct: {this.state.numCorrect} of {this.state.questions.length} </h4>
			</div>
			<div id="quiz-question">
				{ question }
			</div>

			</div>
		)

		}
	}
