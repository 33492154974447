import React, { Component } from "react";
import "./Choicesthree.css";

class ChoicesMCSentence extends Component {
  constructor(props) {
    super(props);
    this.playChoiceAudio = this.playChoiceAudio.bind(this);
    this.putSelectedChoice = this.putSelectedChoice.bind(this);
    this.getidstyle = this.getidstyle.bind(this);
    this.getbuttonstyle = this.getbuttonstyle.bind(this);
    this.getChoiceText = this.getChoiceText.bind(this);
    this.getChoiceAudio = this.getChoiceAudio.bind(this);

    this.audio = new Audio();
    this.state = {
      using_african_word: this.props.using_african_word,
      cans: true,
      choices: !this.props.auto_generated_choices
      ? this.props.questionObject.choices
      : this.props.questionObject.auto_generated_choices.choices,
      correct_answer: !this.props.auto_generated_choices
        ? this.props.questionObject.correct_answer
        : this.props.questionObject.auto_generated_choices
            .correct_answer_option,
      selectedChoiceObject: "",
      selectedChoiceId: "",
    };
  }
  putSelectedChoice(choice) {
    this.setState({ selectedChoiceObject: choice });
  }
  handleChange(e) {
    // console.log(e.target);
    const selected = e.target.value;
    this.props.setSelectedChoice(selected);
    this.props.setCurrentQuestionObject(this.props.questionObject);
    if (this.props.answerSubmitted == false) {
      this.setState({ selectedChoiceId: selected });
      var correctAnsChoice = this.state.correct_answer;
      this.playChoiceAudio(correctAnsChoice);
    } else {
      this.playChoiceAudio(selected);
    }
  }
  playChoiceAudio(selected) {
    this.audio.pause();
    // console.log(this.state.selectedChoiceObject);
    this.state.choices.map((choice) => {
      if (selected === choice.multiple_choice_option) {
        this.audio.src = this.getChoiceAudio(choice);
        this.audio.play();
      }
    });
    // console.log(this.state.selectedChoiceObject);
  }
  getidstyle(choiceId) {
    var correcta = this.props.questionObject.correct_answer === choiceId;
    var submitted = this.props.answerSubmitted === true;
    var selecteda = this.state.selectedChoiceId === choiceId;
    var anscorrect = submitted && correcta;
    var answrong = submitted && !correcta && selecteda;
    if (answrong) {
      return "wrongansbutton";
    } else if (anscorrect) {
      return "correctansbutton";
    } else {
      if (choiceId == this.state.selectedChoiceId) {
        return "sentence_selectedchoicebuttonstyle";
      } else {
        return "sentence_choicebuttonstyle";
      }
    }
  }

  getbuttonstyle(choiceId) {
    var correcta = this.state.correct_answer === choiceId;
    var submitted = this.props.answerSubmitted === true;
    var selecteda = this.state.selectedChoiceId === choiceId;
    var anscorrect = submitted && correcta;
    var answrong = submitted && !correcta && selecteda;
    var ret_str = "";
    if (answrong) {
      ret_str = "sentence_wrongansbutton";
    } else if (anscorrect) {
      ret_str = "sentence_correctansbutton";
    } else {
      if (choiceId == this.state.selectedChoiceId) {
        ret_str = "selectedchoicebuttonstyle";
      } else {
        ret_str = "sentence_choicebuttonstyle";
      }
    }
    return ret_str + "_ttt";
  }
  getChoiceAudio(choice) {
    var choiceword = "";
    var audio_url = "";
    if (this.state.using_african_word) {
      choiceword = choice.african_word;
      if (choiceword.has_native_audio == true) {
        audio_url = choiceword.native_audio_url;
      }
    } else {
      choiceword = choice.word;
      audio_url = choiceword.twi_audio_url;
    }
    return audio_url;
  }
  getChoiceText(choice) {
    var text = "";
    var choiceword = "";
    if (this.state.using_african_word) {
      choiceword = choice.african_word;
      text = choiceword.native_text;
    } else {
      choiceword = choice.word;
      text = choiceword.twi_text;
    }
    return text;
  }

  render() {
    return (
      <div>
        <div className="choicethreeitemcollection">
          <label className="sentence_choicethreeitem">
            {this.state.choices.map((choice) => {
              if ("a" === choice.multiple_choice_option) {
                // this.state.selectedChoice = choice;
                return (
                  <div id={this.getbuttonstyle("a")}>
                    <input
                      className="radiostylethree"
                      onChange={this.handleChange.bind(this)}
                      type="radio"
                      name={this.props.questionObject.question_number}
                      value={choice.multiple_choice_option}
                    />
                    {this.getChoiceText(choice)}
                  </div>
                );
              }
            })}
          </label>
          <label className="sentence_choicethreeitem">
            {this.state.choices.map((choice) => {
              if ("b" === choice.multiple_choice_option) {
                // this.state.selectedChoice = choice;
                return (
                  <div id={this.getbuttonstyle("b")}>
                    <input
                      className="radiostylethree"
                      onChange={this.handleChange.bind(this)}
                      type="radio"
                      name={this.props.questionObject.question_number}
                      value={choice.multiple_choice_option}
                    />
                    {this.getChoiceText(choice)}
                  </div>
                );
              }
            })}
          </label>
          <label className="sentence_choicethreeitem">
            {this.state.choices.map((choice) => {
              if ("c" === choice.multiple_choice_option) {
                // this.state.selectedChoice = choice;
                return (
                  <div id={this.getbuttonstyle("c")}>
                    <input
                      className="radiostylethree"
                      onChange={this.handleChange.bind(this)}
                      type="radio"
                      name={this.props.questionObject.question_number}
                      value={choice.multiple_choice_option}
                    />
                    {this.getChoiceText(choice)}
                  </div>
                );
              }
            })}
          </label>
        </div>
      </div>
    );
  }
}

export default ChoicesMCSentence;
