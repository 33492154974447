import React, { useState, useEffect, useContext } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import { Image, Modal } from "semantic-ui-react";
import "./CheckoutFormThree.css";
import { UserConsumer } from "./components/User/User.js";

// Payment status enums for managing payment confirmation logic
const payment_status = {
  UNPAID: "unpaid",
  JUST_SUCCEEDED: "just_succeeded",
  CANCELED: "canceled",
  ALREADY_PAID: "already_paid",
};

const checkout_type = {
  MONTHLY: "monthly",
  YEARLY: "yearly",
  QUARTERLY: "quarterly",
};

// Modal component displaying payment confirmation status
const PaymentConfirmModal = ({
  isOpen,
  onClickFunc,
  closeFunc,
  status,
  header,
  body,
  button_text,
}) => {
  let isPaid =
    status === payment_status.JUST_SUCCEEDED ||
    status === payment_status.ALREADY_PAID;
  const uiStyleColor = isPaid ? "positive" : "red";
  const headerStyle = isPaid
    ? "checkoutConfirmModalHeaderGreen"
    : "checkoutConfirmModalHeaderRed";
  return (
    <Modal
      style={{ top: "30%", background: "none", boxShadow: "none" }}
      open={isOpen}
      centered={true}
      size={"medium"}
      onClose={closeFunc}
      closeOnDimmerClick={false}
    >
      <div
        id={headerStyle}
        className="header feedbackModalInfo feedbackModalHeader"
      >
        {header}
      </div>
      {/* <Modal.Content image> adds a display: "flex" styling to the component. Alternatively <Modal.Content style={{ display: "flex" }}> works */}
      <Modal.Content style={{ display: "flex" }}>
        <Image
          wrapped
          size="medium"
          src="https://www.dialogue-africa.com/Images/communication-favicon.png"
        />
        <Modal.Description>
          <div className="feedbackParent feedbackModalInfo">
            <div>
              <div id="checkoutConfirmModalSubheader"> {body}</div>
              <button
                onClick={() => onClickFunc(status)}
                id="checkoutConfirmModalButton"
                className={"ui button " + uiStyleColor}
              >
                {button_text}
              </button>
            </div>
          </div>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

// Component handling logic and redirects to stripe
const CheckoutRedirect = (router_props) => {
  const ctx = useContext(UserConsumer);
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState(payment_status.UNPAID);

  // was_redirected accounts for cases where /checkout-redirect was known programmatic way instead of an unknown or manual way.
  const was_redirected = router_props.location && router_props.location.state;

  // Check to see if this is a redirect back from Checkout
  const query = new URLSearchParams(window.location.search);
  const stripe = useStripe();
  const load_delay_secs = 3;
  const payment_option = was_redirected
    ? router_props.location.state.payment_option
    : "";

  // Make sure stripe has loaded before calling stripe-checkout
  useEffect(() => {
    const iife = async () => {
      // Query backend for subscription provisioning confirmation.
      // if provisioned, branch and render Already Subscribed modal.
      // else.. proceed to checkout session redirect
      let is_provisioned = !(await ctx.fetch_premium_expiry());
      // set status based on redirection, url and provisioning
      if (was_redirected && !is_provisioned) {
        setStatus(payment_status.UNPAID);
        if (payment_option == "yearly") {
          ctx.checkout_session_redirect(stripe, checkout_type.YEARLY);
        } else {
          ctx.checkout_session_redirect(stripe, checkout_type.MONTHLY);
        }
      } else if (query.get("success") == "true") {
        // Query backend for subscription provisioning confirmation.
        // wait for expected provisioning loadtime as needed and test
        if (is_provisioned) {
          setStatus(payment_status.JUST_SUCCEEDED);
        } else {
          setStatus(payment_status.CANCELED);
        }
      } else if (query.get("canceled") == "true") {
        setStatus(payment_status.CANCELED);
      } else {
        // case: !was_redirected && !query_success && !query_canceled
        if (is_provisioned) {
          setStatus(payment_status.ALREADY_PAID);
        } else {
          setStatus(payment_status.CANCELED);
        }
      }
      setLoading(false);
    };

    const timeout = setTimeout(
      () => {
        iife();
      },
      was_redirected ? 1000 : load_delay_secs * 1000
    );
    return () => {
      clearTimeout(timeout);
    };
  }, [setLoading, setStatus, was_redirected, stripe, payment_option]);

  // Function that takes in a number of days and returns "X" days if it's less than a week, "X" weeks if it's less than a month or "X" months if it's less than a year. round to the lowest periood (e.g. for 3.5 weeks say 3 weeks.)

  let daysToPeriodString = days => {
    let period = '';
    let num = 0;
    let unit = '';

    if (days < 7) {
      num = Math.floor(days);
      unit = 'day';
    } else if (days < 30) {
      num = Math.floor(days / 7);
      unit = 'week';
    } else if (days < 365) {
      num = Math.floor(days / 30);
      unit = 'month';
    } else {
      num = Math.floor(days / 365);
      unit = 'year';
    }
    period = `${num} ${unit}`;

    return period + (num == 1 ? '' : 's');
  };

  // Generate description based on offer days
  let signupTypeToDays = (signupType) => {
    // non-promo signup types should not lead to this component
    // thus we will only check for promo types.
    let offerDays = 0;
    if (signupType == "3-day-errpu"){
      offerDays = 3;
    } else if (signupType == "promo_referral"){
      offerDays = 21;
    } else {
      // checks if it's a number by checking if Not-a-Number is false
      if (!isNaN(signupType)) {
        offerDays = Number(signupType);
      }
    }

    return offerDays
  }

  const confirmationModal = (status) => {
    let hdr_str = "";
    let body_str = "";
    let btn_txt = "";
    const ctx = useContext(UserConsumer);
    switch (status) {
      case payment_status.JUST_SUCCEEDED:
        hdr_str = "Payment Successful!";
        body_str =
          "You now have premium access. Please enjoy & remember to tell us what you think!";
        btn_txt = "Proceed";
        break;
      case payment_status.ALREADY_PAID:
        hdr_str = "You're plugged in!";
        const redirected = router_props.location && router_props.location.state;
        const signup_type = redirected
          ? router_props.location.state.signup_type
          : "";

        let offerDays = signupTypeToDays(signup_type)
        let period_str = daysToPeriodString(offerDays);
        const description = `Get started with ${period_str} of premium access! We're excited to have you on this journey with us.`;

        body_str = description;
        // TODO: Handle new signup types. e.g. with numbers.

        btn_txt = "Proceed";
        break;
      default:
        // case payment_status.CANCELED or any other
        hdr_str = "Payment Canceled";
        body_str =
          "Your checkout payment was not successful. Please re-visit content and try again, continue with free tier or contact us at info@dialogue-africa.com.";
        btn_txt = "Continue";
        break;
    }
    return (
      <PaymentConfirmModal
        isOpen={true}
        onClickFunc={async (status) => {
          if (status == payment_status.JUST_SUCCEEDED) {
            await ctx.recent_select_course_redirect();
          } else if (status == payment_status.ALREADY_PAID) {
            ctx.redirect("/#choose_language_jump_link");
          } else {
            // ctx.redirect("/subscription-options");
            ctx.recent_select_course_redirect();
          }
        }}
        closeFunc={() => {}}
        status={status}
        header={hdr_str}
        body={body_str}
        button_text={btn_txt}
      />
    );
  };
  const render = (status, was_redirected) => {
    if (was_redirected && status === payment_status.UNPAID) {
      // blank screen before stripe redirect
      return <div></div>;
    } else {
      return <div>{confirmationModal(status)}</div>;
    }
  };

  return loading ? (
    <i id="spinner" className="far fa-sun fa-spin"></i>
  ) : (
    <div>{render(status, was_redirected)}</div>
  );
};

export default CheckoutRedirect;
