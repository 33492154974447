import React, { Component } from "react";
import "./Welcomeculture.css";
import { Link } from "react-router-dom";
import { UserConsumer } from "./components/User/User.js";
import greetings_image from "./components/app_content/navigation_pages_content/images/greetings.jpg";
import ohSound from "./components/app_content/greetings_welcome_content/audio/oh.wav";
import ehSound from "./components/app_content/greetings_welcome_content/audio/eh.wav";
import twiAlphabetSound from "./components/app_content/greetings_welcome_content/audio/twi_alphabet.wav";
import dialogue_twi_logo from "./Images/dialogue-africa-ghana-twi-logo.png";
class Welcomeculture extends Component {
  constructor(props) {
    super(props);
    this.myaudio = new Audio();
    this.playOhSound = this.playOhSound.bind(this);
    this.playEhSound = this.playEhSound.bind(this);
    this.playNativeAlphabet = this.playNativeAlphabet.bind(this);
    this.stopAudio = this.stopAudio.bind(this);
    this.getProverbPointCount = this.getProverbPointCount.bind(this);
    this.fetchUserProfileDetails = this.fetchUserProfileDetails.bind(this);
    this.fetchProverbPointCount = this.fetchProverbPointCount.bind(this);
    this.state = {
      proverb_point_count: 0,
    };
  }
  componentDidMount() {
    //https://stackoverflow.com/questions/28241912/bootstrap-modal-in-react-js
    //check out last but 1 answer
    // $(this.getDOMNode()).modal('show');
    this.fetchUserProfileDetails();
  }
  fetchUserProfileDetails() {
    var pp = this.fetchProverbPointCount();
    this.setState({
      proverb_point_count: pp,
    });
  }
  proverbCount() {
    var path = "proverb_point_count/";
    let value = this.props.contextapivalue;
    return value.getUsingToken(path);
  }
  fetchProverbPointCount() {
    this.proverbCount()
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(response.status);
        }
        response.json().then((proverb_points) => {
          this.setState({ proverb_point_count: proverb_points });
        });
      })
      .catch((error) => {
        console.error("error retrieving proverb points");
      });
  }
  getProverbPointCount() {
    return this.state.proverb_point_count;
  }
  playOhSound() {
    this.myaudio.src = ohSound;
    var promise = this.myaudio.play();
    if (promise !== undefined) {
      promise
        .catch((error) => {
          // Auto-play was prevented
          // Show a UI element to let the user manually start playback
        })
        .then(() => {
          // Auto-play started
        });
    }
  }
  playEhSound() {
    this.myaudio.src = ehSound;
    var promise = this.myaudio.play();
    if (promise !== undefined) {
      promise
        .catch((error) => {
          // Auto-play was prevented
          // Show a UI element to let the user manually start playback
        })
        .then(() => {
          // Auto-play started
        });
    }
  }
  stopAudio() {
    this.myaudio.src = ehSound;
    var promise = this.myaudio.pause();
    if (promise !== undefined) {
      promise
        .catch((error) => {
          // Auto-play was prevented
          // Show a UI element to let the user manually start playback
        })
        .then(() => {
          // Auto-play started
        });
    }
    this.myaudio.currentTime = 0;
  }
  playNativeAlphabet() {
    this.myaudio.src = twiAlphabetSound;
    var promise = this.myaudio.play();
    if (promise !== undefined) {
      promise
        .catch((error) => {
          // Auto-play was prevented
          // Show a UI element to let the user manually start playback
        })
        .then(() => {
          // Auto-play started
        });
    }
  }
  render() {
    return (
      <UserConsumer>
        {(value) => (
          <div>
            <head>
              <meta charset="utf-8" />
              <meta http-equiv="X-UA-Compatible" content="IE=edge" />
              <meta
                name="viewport"
                content="width=device-width,initial-scale=1,shrink-to-fit=no"
              />
              <meta
                name="description"
                content="Dialogue-Africa is an online platform for learning African languages including Twi, Fante, Ga, Hausa, Yoruba and More "
              />
              <title>
                {" "}
                Dialogue-Africa: Learn African Languages like Twi, Fante, Ga,
                Igbo, Hausa, Yoruba, Shona, Amharic and More{" "}
              </title>

              <link
                rel="stylesheet"
                href="https://stackpath.bootstrapcdn.com/bootstrap/4.2.1/css/bootstrap.min.css"
                integrity="sha384-GJzZqFGwb1QTTN6wy59ffF1BuGJpLSa9DkKMp0DgiMDm4iYMj70gZWKYbI706tWS"
                crossorigin="anonymous"
              />
              <script type="text/javascript"></script>
              <script
                src="https://stackpath.bootstrapcdn.com/bootstrap/4.2.1/js/bootstrap.min.js"
                integrity="sha384-B0UglyR+jN6CkvvICOB2joaf5I4l3gm9GU6Hc1og6Ls7i6U/mkkaduKaBhlAXv9k"
                crossorigin="anonymous"
              ></script>
              <link
                rel="stylesheet"
                href="./styleshannon1.css"
                type="text/css"
              />
              <link
                href="https://fonts.googleapis.com/css?family=Open+Sans|Raleway"
                rel="stylesheet"
              />
              <link
                href="https://fonts.googleapis.com/css?family=Montserrat"
                rel="stylesheet"
              />
              <link
                rel="stylesheet"
                type="text/css"
                href="//fonts.googleapis.com/css?family=Varela+Round"
              />
            </head>
            <nav class="navbar fixed-top navbar-expand-lg navbar-light bg-light">
              <Link class="navbar-brand" to="/select_course">
                <img
                  class="favicon dialoguelogobar"
                  alt="Brand"
                  src={dialogue_twi_logo}
                />
              </Link>
              <button
                class="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-backdrop="static"
                data-target="#navbarTogglerDemo02"
                aria-controls="navbarTogglerDemo02"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="navbar-toggler-icon"></span>
              </button>

              <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
                <ul class="navbar-nav ml-auto">
                  <li class="nav-item active">
                    <p id="provcount">
                      <i id="seedlinglarge" class="fas fa-seedling"></i>x{" "}
                      {this.getProverbPointCount()}{" "}
                    </p>{" "}
                    <span class="sr-only">(current)</span>
                  </li>
                  <li class="nav-item active">
                    {value.isGuest ? (
                      <Link
                        class="btn btn-success my-2 my-sm-0"
                        to="/create_profile"
                      >
                        <span class="fa fa-user-plus"></span> Create Profile{" "}
                        <span class="sr-only">(current)</span>
                      </Link>
                    ) : (
                      <a class="btn btn-outline-success my-2 my-sm-0" href="#">
                        <span class="fa fa-user-circle"></span> Hi{" "}
                        {value.isLoggedIn
                          ? value.get_first_name_or_username()
                          : "Guest"}{" "}
                        <span class="sr-only">(current)</span>
                      </a>
                    )}
                  </li>
                  <li class="nav-item active">
                    <a class="nav-link" href="blog-main.html">
                      <span class="fa fa-book"></span> Blog{" "}
                      <span class="sr-only"> (current) </span>{" "}
                    </a>
                  </li>
                  <li class="nav-item active">
                    <a
                      class="btn btn-outline-info my-2 my-sm-0"
                      href={
                        "https://brown.co1.qualtrics.com/jfe/form/SV_cOMTf4CUsSf5iyp"
                      }
                    >
                      <span class="fa fa-comment"></span> Talk to us!{" "}
                      <span class="sr-only"> (current) </span>{" "}
                    </a>
                  </li>
                  <li class="nav-item active">
                    <Link class="nav-link " to="/select_course">
                      <span class="fa fa-th-list"></span> Select Course
                    </Link>
                  </li>
                  <li id="dialog-confirm" class="nav-item active">
                    <a
                      className="delete-button"
                      onClick={(e) => {
                        if (
                          window.confirm(
                            "You can save your guest progress first. Still want to logout?"
                          )
                        )
                          value.logout();
                      }}
                      class="nav-link"
                      href="javascript:void(0);"
                    >
                      <span class="fa fa-sign-out"></span> Logout
                      <span class="sr-only">(current)</span>
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
            <br />
            <br />
            <br />
            {/*<!-- Modal -->*/}
            <div
              class="modal fade"
              id="exampleModalLong"
              tabindex="-1"
              role="dialog"
              data-show="true"
              aria-labelledby="exampleModalLongTitle"
              aria-hidden="true"
            >
              <div
                class="modal-dialog modal-dialog-centered modal-lg"
                role="document"
              >
                <div class="modal-content">
                  <div class="modal-header">
                    <h1 id="notessubheading">
                      {" "}
                      <i class="fas fa-crosshairs fa-spin"></i> LESSON NOTES{" "}
                    </h1>
                  </div>
                  <div class="modal-body">
                    <div id="lessonnotes">
                      <h3 id="notessubheading">Greetings Culture</h3>
                      <p id="goals2">
                        It is widely expected and considered a sign of respect
                        to begin with greetings when you meet someone or enter a
                        room with people.
                      </p>
                      <p id="goals2">
                        {" "}
                        {/* In Ghanaian culture,  */}
                        Always use the right hand to greet{" "}
                        <i id="myhandshakeicon" class="far fa-handshake"></i>,
                        give and receive items.
                        {/* {" "}
                        </b>{" "} */}
                        {/* from , - since the left-hand is often considered
                        unsanitary / designated for use in the bathroom. fa fa-long-arrow-left*/}
                      </p>
                      {/* <p id="goals2">
                        In traditional settings, greet a row of elders from
                        right to left{" "}
                        <i id="myhandshakeicon" class="far fa-handshake"></i>
                        <i id="myarrow" class="fa fa-long-arrow-left"></i>
                        <i id="myhandshakeicon" class="fa fa-users"></i>
                        <i id="myarrow" class="fa fa-long-arrow-left"></i>
                        <i id="myhandshakeicon" class="far fa-handshake"></i>.
                        This naturally aligns the palms for a right-hand shake.
                      </p>{" "} */}
                      <h3 id="notessubheading">
                        Grammar Note
                        {/* : */}
                        {/* Subject + Verb + Object */}{" "}
                        {/* <span id="word-color1">Subject</span> +{" "}
                        <span id="word-color2">Verb</span> +{" "}
                        <span id="word-color3">Object</span> */}
                      </h3>
                      <p id="goals2">
                        Note the <span id="word-color1">subject</span> +{" "}
                        <span id="word-color2">verb</span> +{" "}
                        <span id="word-color3">object</span> pattern in Twi,
                        just like in English. We will expand on this in later
                        lessons. E.g.
                      </p>
                      <p id="goals2">
                        {" "}
                        {/* In Ghanaian culture,  */}
                        <span id="word-color1">I</span>{" "}
                        <span id="word-color2">give</span>{" "}
                        <span id="word-color3">you</span> {"  "}
                        <span> . . .</span>{" "}
                        <i
                          id="myarrownocolor"
                          class="fa fa-long-arrow-right"
                        ></i>{" "}
                        <span id="word-color1">Me</span>{" "}
                        <span id="word-color2">ma</span>{" "}
                        <span id="word-color3">wo</span>
                        {"  "} <span> . . .</span>{" "}
                      </p>
                      <p id="goals2">
                        <span id="word-color1">I</span>{" "}
                        <span id="word-color2">greet</span>{" "}
                        <span id="word-color3">you</span>{" "}
                        <i
                          id="myarrownocolor"
                          class="fa fa-long-arrow-right"
                        ></i>{" "}
                        <span id="word-color1">Me</span>{" "}
                        <span id="word-color2">kyea</span>{" "}
                        <span id="word-color3">wo</span>{" "}
                      </p>{" "}
                      <h3 id="notessubheading">Intro to Twi Alphabet</h3>
                      <p id="goals2">
                        The Twi alphabet is similar to the English alphabet,
                        both in the number of common letters and in
                        pronunciation.
                      </p>
                      <p id="goals2">
                        {" "}
                        It also has additional vowels such as{" "}
                        <i
                          onClick={this.playEhSound}
                          id="myletteraudio"
                          class="fas fa-volume-up"
                        >
                          {" "}
                        </i>{" "}
                        'ɛ' (eh) and{" "}
                        <i
                          onClick={this.playOhSound}
                          id="myletteraudio"
                          class="fas fa-volume-up"
                        >
                          {" "}
                        </i>{" "}
                        'ɔ' (ô). Unlike the English alphabet, the Twi alphabet
                        does not have the letters [ c, j, q, v, x, z ].
                      </p>
                      <p id="goals2">
                        {" "}
                        Twi alphabet{" "}
                        <i
                          onClick={this.playNativeAlphabet}
                          id="myletteraudio"
                          class="fas fa-volume-up"
                        ></i>{" "}
                        : [ a, b, d, e, ɛ, f, g, h, i, k, l, m, n, o, ɔ, p, r,
                        s, t, u, w, y ]
                      </p>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button
                      onClick={this.stopAudio}
                      type="button"
                      data-dismiss="modal"
                      id="closetips"
                    >
                      <p>
                        <i class="fas fa-door-open"></i> Close{" "}
                      </p>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <h2 id="heading">Nkyea | Greetings</h2>

            <section className="flexbox-container">
              <div id="leftcard">
                <div id="gc1a"></div>
                <div id="leftcardbase">
                  <div className="greetingscard1b">
                    <Link id="nodecor" to="/greetings_quiz">
                      <button
                        id="enterlesson"
                        class="btn btn-outline-orange my-2 my-sm-0"
                      >
                        <p id="entersentence">
                          <span
                            class="fa fa-play-circle"
                            aria-hidden="true"
                          ></span>
                          Ma Yensua Nkyea
                        </p>
                      </button>
                    </Link>
                    <img src={greetings_image} />
                  </div>
                </div>
              </div>

              <div id="rightcard">
                <div className="greetingscard2a"></div>
                <div className="greetingscard2b">
                  <div className="playandimage">
                    <img src={greetings_image} />
                    <Link id="nodecor2" to="/greetings_quiz">
                      <button class="btn btn-outline-orange my-2 my-sm-0 highenterlesson">
                        <p id="entersentence">
                          <span
                            class="fa fa-play-circle"
                            aria-hidden="true"
                          ></span>{" "}
                          Learn Greetings
                        </p>
                      </button>
                    </Link>
                  </div>
                  <div id="descriptionTips">
                    <h1 id="subheadingA"> Lesson Description</h1>
                  </div>
                  <hr class="hrid" />
                  <p id="goals">
                    <span class="fas fa-chevron-rightd"></span>In this lesson
                    you will learn how to properly greet others and understand
                    the meaning behind those greetings in Twi.
                  </p>{" "}
                  <button
                    type="button"
                    id="entertips"
                    data-toggle="modal"
                    data-target="#exampleModalLong"
                    data-backdrop="static"
                  >
                    <p>
                      {" "}
                      <i class="fas fa-crosshairs fa-spin"></i> Lesson Notes
                    </p>
                  </button>
                  <h1 id="subheading">
                    {" "}
                    After completing this short quiz, you'll be able to{" "}
                  </h1>
                  <ul id="goals">
                    <li>
                      <span class="fas fa-chevron-rightd"></span>Greet people in
                      the morning, afternoon and in the evening.
                    </li>
                    <li>
                      <span class="fa fa-chevron-rightd"></span>Exercise the
                      basic use of the 1st and 2nd person pronouns in Twi
                    </li>
                    {/* <li>
                      <span class="fa fa-chevron-rightd"></span>Exercise the use
                      of digraphs in Twi such as "ky"
                    </li> */}
                    <li>
                      <span class="fa fa-chevron-rightd"></span>Recognize some
                      basic grammar rules and patterns used in sentences.
                    </li>

                    <li>
                      <span class="fa fa-chevron-rightd"></span>Understand the
                      underlying meaning behind common words for greetings
                    </li>
                  </ul>
                </div>
              </div>
            </section>
          </div>
        )}
      </UserConsumer>
    );
  }
}

export default Welcomeculture;
