import React, { Component } from "react";
import bodyparts from "./components/app_content/navigation_pages_content/images/bodyparts.jpg";
import profession from "./components/app_content/navigation_pages_content/images/pharmacist.jpg";
import homenouns_image from "./components/app_content/navigation_pages_content/images/homenouns.jpg";
import homeverbs_image from "./components/app_content/navigation_pages_content/images/homeverbs.jpg";
import animals_image from "./components/app_content/navigation_pages_content/images/animals.jpg";
import colors_image from "./components/app_content/navigation_pages_content/images/colors.jpg";
import family_image from "./components/app_content/navigation_pages_content/images/family.jpg";
import chores_image from "./components/app_content/navigation_pages_content/images/chores.jpg";
import medicalnouns_image from "./components/app_content/navigation_pages_content/images/medicine_welcome1.png";
import romance_image from "./components/app_content/navigation_pages_content/images/romance.jpg";
import market from "./components/app_content/navigation_pages_content/images/market.png";
import greetings_image from "./components/app_content/navigation_pages_content/images/greetings.jpg";
import farmer_image from "./components/app_content/navigation_pages_content/images/okuani.png";
import at_school_2_image from "./components/app_content/navigation_pages_content/images/teacher.png";
import i_subject from "./components/app_content/navigation_pages_content/images/i-subject.png";

import ReactPixel from "react-facebook-pixel";
if (process.env.REACT_APP_DEV_PIPELINE_STAGE == "production") {
  const options = {
    autoConfig: true, // set pixel's autoConfig
    debug: false, // enable logs
  };
  ReactPixel.init(process.env.REACT_APP_FB_PIXEL_CODE, options);
  ReactPixel.pageView();
}

const GroupItem = ({ info, ctx }) => {
  const quiz_info = ctx.state.moore_quiz_association_info[info];
  const url_route = quiz_info[0];
  const image = quiz_info[1];
  const name = quiz_info[2];
  const display = ctx.quiz_info(info);
  return ctx.props.returnQuiz(url_route, image, name, display);
};
const DiamondDisplay = (group_items, ctx) => {
  var top = "";
  var left = "";
  var right = "";
  var down = "";
  if (group_items.length > 0) {
    top = <GroupItem info={group_items[0]} ctx={ctx} />;
  }
  if (group_items.length > 1) {
    left = <GroupItem info={group_items[1]} ctx={ctx} />;
  }
  if (group_items.length > 2) {
    right = <GroupItem info={group_items[2]} ctx={ctx} />;
  }
  if (group_items.length > 3) {
    down = <GroupItem info={group_items[3]} ctx={ctx} />;
  }
  return (
    <label id="courserow">
      <label>{top}</label>
      <label>
        {left}
        {right}
      </label>
      <label>{down}</label>
    </label>
  );
};
const ListOfGroupItems = ({ group_items, ctx }) => {
  return <ul>{DiamondDisplay(group_items, ctx)}</ul>;
};
const ListOfGroups = ({ groups, ctx }) => {
  return (
    <ul>
      {groups.map((single_group) => (
        <ListOfGroupItems group_items={single_group} ctx={ctx} />
      ))}
    </ul>
  );
};
class MooreSkillTree extends Component {
  constructor(props) {
    super(props);
    this.returnMooreSkillTree = this.returnSkillTree.bind(this);
    this.getCompletionStats = this.getCompletionStats.bind(this);
    this.state = {
      language: this.props.language,
      somali_lesson_display_groups: [
        ["home_nouns_1"],
        ["greetings_vocab", "medical_nouns_1_vocab"],
        ["professions", "market_food_1_vocab", "home_verbs_1"],
        ["family", "doing_chores"],
        ["body_parts"],
        ["market_food_1", "colors"],
        ["animals", "at_school_1", "colors_2"],
        ["medical_nouns_1", "body_parts_2"],
        ["professions_2"],
        ["doing_chores_2", "animals_2", "at_school_2"],
      ],
      moore_lesson_display_groups: [
        ["family"],
        ["home_nouns_1", "greetings_vocab"],
        ["professions", "market_food_1_vocab", "home_verbs_1"],
        ["medical_nouns_1_vocab", "doing_chores"],
        ["body_parts"],
        ["romance", "colors"],
        ["animals", "at_school_1", "colors_2"],
        ["romance_2", "body_parts_2"],
        ["professions_2"],
        ["doing_chores_2", "animals_2", "at_school_2"],
      ],
      shona_lesson_display_groups: [
        ["family"],
        ["home_nouns_1", "greetings_vocab"], // my name is
        ["professions"], //major cities
        ["colors"],
        ["market_food_1_vocab","doing_chores"],
        ["subject_pronouns_1_vocab"],
        ["home_verbs_1"],
        ["body_parts"],
        ["romance", "medical_nouns_1_vocab"],
        ["animals", "at_school_1", "colors_2"],
        ["romance_2", "body_parts_2"],
        ["professions_2"],
        ["doing_chores_2", "animals_2", "at_school_2"],
      ],
      moore_quiz_association_info: {
        // key must match quiz name in
        // moore_lesson_display_groups.
        subject_pronouns_1_vocab: [
          "subject_pronouns_1_vocab_quiz",
          i_subject,
          "I-You-It",
        ],
        romance_2: ["moore-romance-2-quiz", romance_image, "Romance 2"],
        colors_2: ["moore-colors-2-quiz", colors_image, "Colors 2"],
        professions_2: [
          "moore-professions-2-quiz",
          farmer_image,
          "Professions 2",
        ],
        at_school_1: [
          "moore-at-school-1-quiz",
          at_school_2_image,
          "Education 1",
        ],
        body_parts_2: ["moore-bodyparts-2-quiz", bodyparts, "Body Parts 2"],
        doing_chores_2: ["moore-chores-2-quiz", chores_image, "Doing Chores 2"],
        animals_2: ["moore-animals-2-quiz", animals_image, "Animals 2"],
        at_school_2: [
          "moore-at-school-2-quiz",
          at_school_2_image,
          "Education 2",
        ],
        medical_nouns_1_vocab: [
          "moore-medicalnouns-vocab-quiz",
          medicalnouns_image,
          "Medical Nouns",
        ],
        medical_nouns_1: [
          "moore-medicalnouns-quiz",
          medicalnouns_image,
          "Medicine",
        ],

        professions: [
          "/moore-course-professions_quiz",
          profession,
          "Professions",
        ],

        market_food_1_vocab: [
          "moore-marketfood-vocab-quiz",
          market,
          "At The Market",
        ],
        market_food_1: ["moore-marketfood-quiz", market, "Market Food"],

        home_nouns_1: ["course-homenouns-quiz", homenouns_image, "Home Nouns"],

        home_verbs_1: [
          "moore-course-homeverbs-quiz",
          homeverbs_image,
          "Home Verbs",
        ],

        greetings_vocab: [
          "course-greetings-vocab-quiz",
          greetings_image,
          "Greetings",
        ],
        greetings_language: [
          "language-greetings-quiz",
          greetings_image,
          "Greetings 1",
        ],

        romance: ["romance_quiz", romance_image, "Romance"],

        family: ["course-family-quiz", family_image, "Family"],

        animals: ["animals_quiz", animals_image, "Animals"],

        body_parts: ["moore-bodyparts-quiz", bodyparts, "Body Parts"],

        doing_chores: ["moore-chores-quiz", chores_image, "Doing Chores"],

        colors: ["colors_quiz", colors_image, "Colors"],
      },
      // language_name : 'moore' // this.props.language_name
      // skill tree state info

      // Adding a general set of quizzes nonlimited to support
      // moore, ewe, shona, somali temporarily
      moore_non_limited_quizzes: new Set([
        "home_nouns_1",
        "family",
        "greetings_vocab",
      ]),
      moore_playable_quizzes_set: new Set(["home_nouns_1", "family"]),
      shona_playable_quizzes_set: new Set(["home_nouns_1", "family","greetings_vocab"]),
      somali_playable_quizzes_set: new Set(["home_nouns_1", "greetings_vocab"]),
      lesson_stats: {},
    };
  }

  componentDidMount() {
    this.getCompletionStats(
      this.state.lesson_stats,
      this.getPlayableQuizSet(this.state.language)
    );
  }

  getCompletionStats(lesson_stats, quiz_names) {
    this.props
      .getCompletionStats(quiz_names)
      .then((newItems) => this.setState({ lesson_stats: newItems }));
  }
  getPlayableQuizSet(language){
    if (language == "somali") {
      return this.state.somali_playable_quizzes_set;
    } else if (language == "shona") {
      return this.state.shona_playable_quizzes_set;
    } else {
      return this.state.moore_playable_quizzes_set;
    }
  }
  getLessonDisplay(language) {
    if (language == "somali") {
      return this.state.somali_lesson_display_groups;
    } else if (language == "shona") {
      return this.state.shona_lesson_display_groups;
    } else {
      return this.state.moore_lesson_display_groups;
    }
  }
  quiz_info(quiz_name) {
    let pq_set = this.getPlayableQuizSet(this.props.language)
    const in_tree = pq_set.has(quiz_name);
    const stats = this.state.lesson_stats[quiz_name];
    const limited = !this.state.moore_non_limited_quizzes.has(quiz_name);
    var quiz_info_arr = [quiz_name, in_tree, stats, limited];
    return quiz_info_arr;
  }

  returnSkillTree() {
    let gld = this.getLessonDisplay(this.state.language)
    return (
      <div className="courselineditems">
        <ListOfGroups
          groups={gld}
          ctx={this}
        />
      </div>
    );
  }

  render() {
    return <div>{this.returnSkillTree()}</div>;
  }
}
export default MooreSkillTree;
