import React, { Component } from "react";
import ChoicesMCSentence from "./ChoicesMCSentence.js";
import ChoicesAlternative from "./ChoicesAlternative.js";
import sbimage from "./app_content/navigation_pages_content/images/speakerwhitebg.png";
import "./Choices.css"

class QuestionMCSentence extends Component {
  constructor(props) {
    super(props);
    this.myaudio = new Audio();
    this.playQuestionAudio = this.playQuestionAudio.bind(this);
    this.state = {
      question_type_text: this.props.question_type_text,
      stateaudio: "",
    }; //state close
  }

  componentDidMount() {
    return setTimeout(
      function () {
        this.playQuestionAudio();
      }.bind(this),
      850
    );
  }

  returnSoundButton() {
    let type_str = this.props.question_type_str
    if (type_str == "translate-native-text") {
      return (
        <img
          id={"sbitranslate"}
          onClick={this.playQuestionAudio}
          src={sbimage}
        />
      );
    } else {
      return <div></div>;
    }
  }

  playQuestionAudio() {
    let type_str = this.props.question_type_str
    if (type_str == "translate-native-text") {
      this.state.stateaudio = this.props.question.phrase.underlying_native_phrase_audio_url;
    }
    this.myaudio.src = this.state.stateaudio;

    var promise = this.myaudio.play();
    if (promise !== undefined) {
      promise
        .catch((error) => {
          // Auto-play was prevented
          // Show a UI element to let the user manually start playback
        })
        .then(() => {
          // Auto-play started
        });
    }
  }

  smartAddPeriod(phrase){
    if (phrase.length > 0 && phrase[phrase.length - 1] !== "." && phrase[phrase.length - 1] !== "?") {
      return phrase + ".";
    }
    return phrase;
  }

  render() {
    // Set default question text & question type text based on type_str (e.g. "translate-native-text")
    let type_str = this.props.question_type_str
    let question_type_text = "Select the correct translation for : "
    let question_text = this.props.question.text
    if (this.props.auto_generated_choices) {
      question_text = this.props.question.question_instruction
    }
    let ChoicesComponent = ChoicesMCSentence

    // update question text & question type based on type_str
    if (type_str == "translate-native-text") {
      question_type_text = "The following statement means : "
      question_text = this.smartAddPeriod(this.props.question.phrase.underlying_native_phrase);
      ChoicesComponent = ChoicesAlternative
    } else if (type_str == "match-text-with-response") {
      question_type_text = "Select the correct response"
    }
    return (
      <div>
        <p class="questiontext_three">{question_type_text}</p>
        <br />
        <div className="arrange_sentence_question">
          <div className="questionimagesMC">
            <div style={{
                  // backgroundColor: "orange",
                  marginLeft: "-30px",
                }}>
              <p class="horizontal"
              >{this.returnSoundButton()}{" "}</p>
              <label>
                <p
                style={{
                  // backgroundColor: "orange",
                  paddingLeft: "10px",
                }}
                onClick={this.playQuestionAudio} id="question_translate_text"> {question_text} </p>
              </label>
            </div>
          </div>
          <div id="choiceslist">
            <ChoicesComponent
              incQN={this.props.incQN}
              incS={this.props.incS}
              currentQuestion={this.props.currentQuestion}
              questionObject={this.props.question}
              questionText={this.state.question_type_text}
              idx={this.props.question.id}
              setSelectedChoice={this.props.setSelectedChoice}
              setCurrentQuestionObject={this.props.setCurrentQuestionObject}
              answerSubmitted={this.props.answerSubmitted}
              auto_generated_choices={this.props.auto_generated_choices}
              using_african_word={this.props.using_african_word}
            />
          </div>
          <br />
        </div>
      </div>
    );
  }
}

export default QuestionMCSentence;
