import React, { Component } from "react";
import { Link } from "react-router-dom";
import one from "./Images/onelineicon.svg";
import two from "./Images/twolineicon.svg";
import three from "./Images/threelineicon.svg";
import facebookicon from "./Images/newfacebookicon.png";
import ghanaflaglineicon from "./Images/flag-ghana.png";
import tanzaniaflaglineicon from "./Images/flag-tanzania.png";
import naijaflaglineicon from "./Images/flag-nigeria.png";
import togoflaglineicon from "./Images/togo.png";
import southafricaflaglineicon from "./Images/south-africa.png";
import zimbabweflaglineicon from "./Images/zimbabwe.png";
import senegalflaglineicon from "./Images/senegal.png";
import maliflaglineicon from "./Images/mali.png";
import egyptflaglineicon from "./Images/egypt.png";
import burkinafasoflaglineicon from "./Images/burkina-faso.png";
import somaliaflaglineicon from "./Images/somalia.png";
import zambiaflaglineicon from "./Images/zambia.png";
import ugandaflaglineicon from "./Images/uganda.png";
import kenyaflaglineicon from "./Images/kenya.png";
import ethiopiaflaglineicon from "./Images/ethiopia.png";
import rwandaflaglineicon from "./Images/rwanda.png";
import blogicon from "./Images/blog-icon.jpeg";
import { UserConsumer } from "./components/User/User.js";
import ReactPixel from "react-facebook-pixel";
import ScrollableAnchor from "react-scrollable-anchor";
import { configureAnchors } from "react-scrollable-anchor";
import chooselangimg from "./Images/choose-language.svg";
import favicon from "./Images/communication-favicon.png";
import appStoreBadge from "./Images/app-store-badge.png";
// import { Dropdown } from "semantic-ui-react";
// Offset all anchors by -60 to account for a fixed header
// and scroll more quickly than the default 400ms
configureAnchors({
  offset: -60,
  scrollDuration: 100,
  keepLastAnchorHash: true,
});
if (process.env.REACT_APP_DEV_PIPELINE_STAGE == "production") {
  const options = {
    autoConfig: true, // set pixel's autoConfig
    debug: false, // enable logs
  };
  ReactPixel.init(process.env.REACT_APP_FB_PIXEL_CODE, options);
  ReactPixel.pageView();
}

/**
 * Can add more routes: <Route path={"user"} />
 * <Route path='/multiplechoice' component={Multiplechoice } />
 * example.com/#/multiplechoice
 * html5 can automatically can handle different url start, deep links or hashtag links.
 */

class Selectlanguage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visited_login_func: false,
      display_name_ret: false,
    };
    this.displayName = this.displayName.bind(this);
  }
  // componentDidMount() {
  //   this.setState({
  //     visited_login_func: true,
  //   })
  // }
  displayMoreLanguages(value) {
    return (
      <>
        <li>
          <a class="buttonmargin fontB fontregular text-secondary my-2 my-sm-0 text-center">
            SIGN UP{" "}
          </a>
        </li>
        <div class="dropdown-divider"></div>
        {/* {this.languageListItem(
          value,
          "Somali",
          somaliaflaglineicon,
          "text-info",
          "",
          ""
        )}
        <div class="dropdown-divider"></div> */}
        {/* {this.languageListItem(
          value,
          "Moore",
          burkinafasoflaglineicon,
          "text-danger",
          "",
          ""
        )}
        <div class="dropdown-divider"></div> */}
        {/* <li>
          <a
            class="buttonmargin fontB fontregular text-success my-2 my-sm-0"
            onClick={() => (window.location = "/subscribe?langculture=EWE")}
            // href="/subscribe?langculture=EWE"
            style={{ cursor: "pointer" }}
          >
            <img id="selectlangimage" src={togoflaglineicon}></img>
            <img
              id="selectlangimagepair2"
              src={ghanaflaglineicon}
            ></img> EWE{" "}
          </a>
        </li>
        <div class="dropdown-divider"></div> */}

        {/* <li>
          <a class="buttonmargin fontB fontregular text-secondary my-2 my-sm-0 text-center">
            SIGN UP{" "}
          </a>
        </li>
        <div class="dropdown-divider"></div>
         */}
        {this.languageListItem(
          value,
          "Zulu",
          southafricaflaglineicon,
          "text-secondary",
          "",
          ""
        )}
        {/* <div class="dropdown-divider"></div>
        {this.languageListItem(
          value,
          "Shona",
          zimbabweflaglineicon,
          "text-success",
          "",
          ""
        )} */}
        <div class="dropdown-divider"></div>
        {this.languageListItem(
          value,
          "Bemba",
          zambiaflaglineicon,
          "text-success",
          "",
          ""
        )}
        <div class="dropdown-divider"></div>
        {this.languageListItem(
          value,
          "Luganda",
          ugandaflaglineicon,
          "text-secondary",
          "",
          ""
        )}
        <div class="dropdown-divider"></div>
        {this.languageListItem(
          value,
          "Kinyarwanda",
          rwandaflaglineicon,
          "text-info",
          "",
          ""
        )}
        <div class="dropdown-divider"></div>
        {this.languageListItem(
          value,
          "Hausa",
          naijaflaglineicon,
          "text-success",
          "",
          ""
        )}
        <div class="dropdown-divider"></div>
        {this.languageListItem(
          value,
          "Amharic",
          ethiopiaflaglineicon,
          "text-danger",
          "",
          ""
        )}

        <div class="dropdown-divider"></div>
        {this.languageListItem(
          value,
          "Fante",
          ghanaflaglineicon,
          "text-success",
          "",
          ""
        )}
        <div class="dropdown-divider"></div>
        {this.languageListItem(
          value,
          "Wolof",
          senegalflaglineicon,
          "text-success",
          "",
          ""
        )}
        <div class="dropdown-divider"></div>
        {this.languageListItem(
          value,
          "Bambara",
          maliflaglineicon,
          "text-danger",
          "",
          ""
        )}
        <div class="dropdown-divider"></div>
        {this.languageListItem(
          value,
          "Xhosa",
          southafricaflaglineicon,
          "text-secondary",
          "",
          ""
        )}
        <div class="dropdown-divider"></div>
        {this.languageListItem(
          value,
          "Arabic",
          egyptflaglineicon,
          "text-secondary",
          "",
          ""
        )}
        <div class="dropdown-divider"></div>
        <li>
          <a
            class="buttonmargin fontB fontregular text-success my-2 my-sm-0"
            onClick={() => {
              window.location.href =
                "https://docs.google.com/forms/d/e/1FAIpQLSfsNTLlPYQNYdXVwOdXfoYwW_sqMe_7Z7H4ZNLPJT1WhrkMEw/viewform";
            }}
          >
            <img id="selectlangimage" src={favicon}></img> Add Request{" "}
          </a>
        </li>
      </>
    );
  }
  languageListItem(
    value,
    languageString,
    flag,
    buttonStyleString,
    buttonId,
    lang_str,
    learn
  ) {
    let classStr =
      buttonStyleString +
      " buttonmargin" +
      " langitem" +
      " fontB fontregular" +
      // " dropdown-item";
      // +" my-2 my-sm-0";
      "";
    // lang_str = languageString.toLowerCase();
    let text = "Learn " + languageString;
    if (learn != true) {
      languageString = languageString.toUpperCase();
      text = languageString; // + " Signup";
    }
    return (
      <li
        // class="nav-item active"
        class="langlistitem"
      >
        <a
          id={buttonId}
          class={classStr}
          onClick={(e) => {
            if (learn == true) {
              value.guestlogin(e, languageString.toLowerCase());
            } else {
              window.location = "/subscribe?langculture=" + languageString;
            }
          }}
          href="javascript:void(0);"
          // style={{ textDecoration: "none" }}
        >
          <img id="selectlangimage0" src={flag}></img>
          {text}
          {/* {" "} */}
        </a>
      </li>
    );
  }
  displayName(myctx) {
    if (this.state.visited_login_func == true) {
      // return precomputed answer
      return this.state.display_name_ret;
    }
    var loggedIn = true;
    // compute answer
    if (!myctx.isLoggedIn) {
      loggedIn = false;
    }
    this.setState({
      display_name_ret: loggedIn,
      visited_login_func: true,
    });
    return loggedIn;
  }

  displayDemosList(value) {
    return (
      <div class="btn-group">
        <button
          id="try-demo"
          type="button"
          class="btn btn-lg btn-outline-danger dropdown-toggle dropdown-toggle-split"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i class="fas fa-laptop-code">&nbsp;</i> Try Course &nbsp;&nbsp;
          {"  "}
          <span class="sr-only"> Toggle Dropdown</span>
        </button>{" "}
        <div class="dropdown-menu">
          <ul>
            <li class="nav-item active">
              <button
                id="twi-demo-1"
                class="textdarkorange dropdown-item fontB fontregular  my-2 my-sm-0"
                onClick={(e) => value.guestlogin(e, "twi")}
                // href="javascript:void(0);"
              >
                <span class="fas fa-laptop-code">&nbsp;</span> TWI
              </button>
            </li>
            <div class="dropdown-divider"></div>
            <li class="nav-item active">
              <a
                class="dropdown-item fontB fontregular text-success my-2 my-sm-0"
                onClick={(e) => value.guestlogin(e, "yoruba")}
                href="javascript:void(0);"
              >
                <span class="fas fa-laptop-code">&nbsp;</span> YORUBA
              </a>
            </li>
            <div class="dropdown-divider"></div>
            <li>
              <a
                id="darkyellow"
                class="dropdown-item fontB fontregular text-warning my-2 my-sm-0"
                onClick={(e) => value.guestlogin(e, "ga")}
                href="javascript:void(0);"
              >
                <i class="fas fa-laptop-code">&nbsp;</i> GA
              </a>
            </li>
            <div class="dropdown-divider"></div>
            <li>
              <a
                class="dropdown-item fontB fontregular text-secondary my-2 my-sm-0"
                onClick={(e) => value.guestlogin(e, "swahili")}
                href="javascript:void(0);"
              >
                <span class="fas fa-laptop-code">&nbsp;</span> SWAHILI
              </a>
            </li>
            <div class="dropdown-divider"></div>
            <li>
              <a
                class="dropdown-item fontB fontregular text-danger my-2 my-sm-0"
                onClick={(e) => value.guestlogin(e, "igbo")}
                href="javascript:void(0);"
              >
                <span class="fas fa-laptop-code">&nbsp;</span> IGBO
              </a>
            </li>
            {/* <div class="dropdown-divider"></div>
            <li>
              <a
                class="dropdown-item fontB fontregular text-info my-2 my-sm-0"
                onClick={(e) =>
                  value.guestlogin(e, "kinyarwanda")
                }
                href="javascript:void(0);"
              >
                <span class="fas fa-laptop-code">&nbsp;</span> KINYARWANDA
              </a>
            </li> */}
          </ul>
        </div>
      </div>
    );
  }
  displayDemosList2(value) {
    return (
      <div class="btn-group">
        <button
          // id="dropdownMenuLink"
          type="button"
          class="buttonmargin fontB fontregular btn btn-light my-2 my-sm-0 dropdown-toggle dropdown-toggle-split"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i
            // class="text-secondary fa-solid fa-earth-africa"
            class="text-secondary fas fa-search"
            class="text-secondary fas fa-globe"
          >
            &nbsp;
          </i>{" "}
          <span class="text-danger fontB">+</span>{" "}
          <span class="text-warning fontB">+</span>{" "}
          <span class="text-success fontB">+</span>{" "}
          {/* <span class="text-info fontB">+</span> &nbsp;*/}&nbsp;
          {"  "}
          <span class="sr-only"> Toggle Dropdown</span>
        </button>{" "}
        <div class="dropdown-menu">
          <ul>{this.displayMoreLanguages(value)}</ul>
        </div>
      </div>
    );
  }
  render() {
    return (
      <UserConsumer>
        {(value) => (
          <html>
            <head>
              <meta charset="utf-8" />
              <meta http-equiv="X-UA-Compatible" content="IE=edge" />
              <meta
                name="viewport"
                content="width=device-width,initial-scale=1,shrink-to-fit=no"
              />
              <meta
                name="description"
                content="Dialogue Africa provides an online platform and application to learn African languages and connect to culture. Learn Twi, Fante, Ga, Swahili, Yoruba and more"
              />
              <title>
                {" "}
                Dialogue Africa: Learn African languages and connect to culture.
                Learn Twi, Yoruba, Swahili, Igbo, Ga, Fante and more{" "}
              </title>
              <link
                rel="stylesheet"
                href="https://use.fontawesome.com/releases/v5.6.3/css/all.css"
                integrity="sha384-UHRtZLI+pbxtHCWp1t77Bi1L4ZtiqrqD80Kn4Z8NTSRyMA2Fd33n5dQ8lWUE00s/"
                crossorigin="anonymous"
              />
              <link
                rel="stylesheet"
                href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css"
                integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm"
                crossorigin="anonymous"
              />
              <link
                rel="stylesheet"
                href="./styleshannon1.css"
                type="text/css"
              />
              <link
                href="https://fonts.googleapis.com/css?family=Baloo+Da+2&display=swap"
                rel="stylesheet"
              />
              <link
                rel="stylesheet"
                type="text/css"
                href="//fonts.googleapis.com/css?family=Varela+Round"
              />
            </head>

            <body class="graybackground">
              <nav class="fontB navbar fixed-top navbar-expand-lg navbar-light bg-light">
                <a class="navbar-brand" href="#">
                  <img
                    class="favicon dialoguelogobar"
                    alt="learn African language platform app"
                    src="./Images/da-logo-lineart.png"
                  />
                </a>
                <button
                  id="nav-toggle"
                  class="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarTogglerDemo02"
                  aria-controls="navbarTogglerDemo02"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span class="navbar-toggler-icon"></span>
                </button>

                <div
                  class="fontB collapse navbar-collapse"
                  id="navbarTogglerDemo02"
                >
                  <ul class="fontB navbar-nav ml-auto">
                    {this.displayDemosList(value)}
                    <li class="nav-item active">
                      {!this.displayName(value) ? (
                        <Link class="fontB fontregular nav-link" to="/login">
                          &nbsp;&nbsp;
                          <span class="fa fa-sign-in-alt" aria-hidden="true">
                            &nbsp;
                          </span>
                          Login &nbsp;
                        </Link>
                      ) : (
                        <span class="fontB fontregular nav-link" href="#">
                          &nbsp;&nbsp;
                          <span class="fa fa-user-circle">&nbsp;</span> Hi{" "}
                          {value.isGuest
                            ? "Guest"
                            : value.get_first_name_or_username()}
                          &nbsp;
                        </span>
                      )}
                    </li>
                    {/* <li class="nav-item active">
                      <a
                        class="fontB fontregular nav-link"
                        href="/blog-main.html"
                      >
                        <span class="far fa-newspaper"></span> Blog{" "}
                      </a>
                    </li> */}
                    {/* <li class="nav-item active">
                      <a class="fontB fontregular nav-link" href="/subscribe">
                        <span class="fas fa-satellite-dish"></span> Stay
                        Informed! <span class="sr-only"> (current) </span>{" "}
                      </a>
                    </li> */}
                    <li class="nav-item active">
                      <a
                        class="fontB fontregular btn btn-outline-info my-2 my-sm-0"
                        href="https://www.bit.ly/dlga-crowdsource"
                      >
                        <span class="fa fa-hands-helping">&nbsp;</span>{" "}
                        Contribute
                      </a>
                    </li>
                    <li class="nav-item active">
                      <a
                        class="fontB fontregular nav-link"
                        href="/blog-main.html"
                      >
                        &nbsp;<span class="fa fa-book"></span> Blog &nbsp;
                      </a>
                    </li>
                    {this.displayName(value) ? (
                      <li class="nav-item active">
                        <a
                          className="delete-button"
                          onClick={value.logout}
                          class="fontB fontregular nav-link"
                          href="javascript:void(0);"
                        >
                          <span class="fa fa-sign-out"></span> Logout
                          <span class="sr-only">(current)</span>
                        </a>
                      </li>
                    ) : (
                      <li class="nav-item active">
                        <Link
                          class="fontB fontregular btn btn-outline-success my-2 my-sm-0"
                          to="/signup"
                        >
                          <i class="fas fa-user-plus">&nbsp;</i> Register
                        </Link>
                      </li>
                    )}
                  </ul>
                </div>
              </nav>

              <div class="container-fluid">
                <div class="row">
                  <div class="col-md-6 carouselContainer">
                    <div
                      id="carouselExampleSlidesOnly"
                      class="carousel slide"
                      data-ride="carousel"
                    >
                      <div class="carousel-inner">
                        <div class="carousel-item active">
                          <img
                            class="d-block w-100"
                            src="Images/COMMUNICATION-AFRICA-DIF-COLORS.png"
                            alt="learn african languages app"
                          />
                        </div>
                        <div class="carousel-item">
                          <img
                            class="d-block w-100"
                            src="Images/suckyourmuddac.png"
                            alt="learn twi, speak akan twi language app"
                          />
                        </div>
                        <div class="carousel-item">
                          <img
                            class="d-block w-100"
                            src="Images/oldman-hausa-woman-pi.png"
                            alt="african languages app"
                          />
                        </div>
                        <div class="carousel-item">
                          <img
                            class="d-block w-100"
                            src="Images/ghanaman-ipadwoman-rs.png"
                            alt="african culture app"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div id="rpadding2" class="col-md-6 my-auto rpadding">
                    <div class="text-center">
                      <p class="h1large fontB"> Dialogue Africa</p>
                    </div>
                    <p class="fonto fontB text-center bolder tagline">
                      {" "}
                      Learn. Connect. Access!
                    </p>
                    <p
                      id="startinfo"
                      class="fontB text-justify twentypixf mx-5"
                    >
                      Develop the foundation you need to thrive in African
                      spaces. Join the community to access free, fun lessons and
                      learn Twi, Yoruba, Swahili, Ga, Igbo, and more. We prepare
                      you for real world interactions in Africa & African
                      communities near you!
                    </p>
                    {!value.isLoggedIn ? (
                      <Link to="/signup" style={{ textDecoration: "none" }}>
                        <button
                          href="javascript:void(0);"
                          id="thinbuttonextrastyle"
                          class="btn btn-success d-block mx-auto rpadding"
                        >
                          <p class="fontB"> Start Free Lessons </p>
                        </button>
                      </Link>
                    ) : (
                      <button
                        onClick={(e) => {
                          value.recent_select_course_redirect();
                        }}
                        id="thinbuttonextrastyle"
                        class="btn btn-success d-block mx-auto rpadding"
                      >
                        <p class="fontB"> Continue Course </p>
                      </button>
                    )}
                  </div>
                </div>
              </div>

              <div class="container-fluid">
                <div class="row">
                  <div class="col-md-12 col-sm-12 platformblue">
                    <p class="text-center fontwhite freeplatform fontB ">
                      {" "}
                      Dialogue Africa is a black-owned tech platform to learn
                      African languages & culture and build community.
                    </p>
                  </div>
                </div>

                <br />
              </div>
              <div class="row whitespace">
                <div class="col-md-4 my-5 px-5">
                  <div>
                    <img
                      class="iconsizer iconcenter zoom"
                      alt="Learn Twi, Yoruba, Swahili, Igbo, Ga and more African languages with free lessons on our app online. Prepare for success in Ghana, Nigeria, Kenya and more."
                      src="Images/icon-onedestination.png"
                    />
                    <h5 class="fontB fontregular  bold text-center textorange">
                      {" "}
                      One Destination, Multiple African Languages{" "}
                    </h5>
                    <p class="fontbs fontsmaller text-justify">
                      Learn African languages and cultures like Akan Twi,
                      Yoruba, Igbo, Hausa, Bambara, Shona and many other all
                      through one application. Courses for several cultures are
                      now available!
                    </p>
                  </div>
                  <div class="paddingtop15">
                    {/* <Link class="text-danger" to="/crowdsource"> */}
                    <a
                      class="text-danger"
                      href="https://www.bit.ly/dlga-crowdsource"
                    >
                      <>
                        <img
                          class="iconsizer iconcenter zoom"
                          alt="crowdsource africa language app african community language application"
                          src="Images/icon-crowd-sourced.png"
                        />
                        <h5 class="fontB fontregular bold text-justify text-center">
                          {" "}
                          Crowd-Sourced. User Focused
                        </h5>
                      </>
                    </a>
                    {/* </Link> */}
                    <p class="fontbs fontsmaller text-justify">
                      {" "}
                      Users can contribute by building lessons, adding
                      vocabulary and sharing stories! Join the community effort!
                      <a
                        id="homepage_feedback"
                        class="bold text-danger"
                        alt="africa platform app"
                        href="https://www.bit.ly/dlga-crowdsource"
                      >
                        {" "}
                        Contact us{" "}
                      </a>
                      to sign up as a contributor and help build African
                      language courses.
                    </p>
                  </div>
                </div>

                <div class="col-md-4 my-auto px-5">
                  <img
                    class="laptop"
                    src="Images/computer-first-screen.png"
                    alt="Dialogue Africa. Digital learning africa"
                  />
                </div>

                <div class="col-md-4 my-5 px-5">
                  <div>
                    <img
                      class="iconsizer iconcenter zoom"
                      alt="celebrate african culture pride app"
                      src="Images/icon-telescope.png"
                    />
                    <h5 class="fontB fontregular bold text-center text-info">
                      {" "}
                      African Point-of-View:{" "}
                    </h5>
                    <p class="fontbs fontsmaller text-justify">
                      {" "}
                      We celebrate the uniqueness of African language and
                      culture. Our courses are designed with the cultural and
                      linguistic specificities of Africa in mind.
                    </p>
                  </div>
                  <div class="paddingtop15">
                    <img
                      class="iconsizer iconcenter zoom"
                      alt="african community, africa social learning app"
                      src="Images/icon-dna.png"
                    />
                    <h5 class="fontB fontregular text-justify bolder text-center text-success">
                      {" "}
                      Community-Based. Socially Driven.
                    </h5>
                    <p class="fontbs fontsmaller text-justify">
                      {" "}
                      Engage with other users, share stories, ask questions and
                      contribute to quizzes. We believe that is learning is
                      social! So let's socialize!
                    </p>
                  </div>
                </div>

                <div class="container-fluid white">
                  <div class="row">
                    {/*    <div class="col-md-12 col-sm-12 greybanner"></div> */}
                    <div class="col-md-12 easystepsorange">
                      <p class=" fontB fontwhite freeplatform text-center">
                        {" "}
                        Start in 3 Simple Steps
                      </p>
                    </div>
                    {/* <!--    <div class="col-md-12 col-sm-12 greybanner"></div>--> */}
                  </div>

                  <div class="row">
                    <div class="col-md-7 threestepsdivs ">
                      <a href="#choose_language_jump_link"></a>
                      <ScrollableAnchor id={"choose_language_jump_link"}>
                        <Link
                          to=""
                          onclick="return false;"
                          style={{
                            textDecoration: "none",
                            color: "black",
                            cursor: "default",
                          }}
                        >
                          <h2
                            id="fontB"
                            class="zoomer fontB movedown2 paddingfordivs text-center mx-5"
                          >
                            <img
                              class="lineiconimg"
                              src={one}
                              alt="african language dialogue"
                            />{" "}
                            <span id="language-select"></span>
                            Select African Language & Culture
                          </h2>
                          <p class="fontbs nobreak text-justify fontsmall mx-5">
                            Dialogue Africa provides users with multiple options
                            for immersion and is expanding to include the major
                            languages and cultures across the African continent.
                            Learn African languages and culture - including Akan Twi, Yoruba, Swahili, Ga, Igbo & more - with both free & premium tiers. Others in the pipeline include Amharic, Hausa, Xhosa and Kinyarwanda.
                          </p>
                          <div class="demostable">
                            <ul id="trydemoswrapper">
                              <li>
                                <button
                                  id="textorangeid"
                                  class="buttonmargin fontB fontregular btn btn-outline-warning my-2 my-sm-0"
                                  onClick={(e) => value.guestlogin(e, "twi")}
                                  href="javascript:void(0);"
                                >
                                  <img
                                    id="selectlangimage"
                                    src={ghanaflaglineicon}
                                  ></img>{" "}
                                  {/* 🇬🇭{" "}
                                  <span id="try-twi-demo-1" class="fa fa-user">
                                    {" "}
                                  </span>{" "}
                                  */}
                                  {21 ? "Learn Twi" : "AKAN TWI"}
                                </button>
                              </li>
                              <li>
                                <a
                                  class="buttonmargin fontB fontregular btn btn-outline-success my-2 my-sm-0"
                                  onClick={(e) => value.guestlogin(e, "yoruba")}
                                  href="javascript:void(0);"
                                >
                                  <img
                                    id="selectlangimage"
                                    src={naijaflaglineicon}
                                  ></img>{" "}
                                  {/* {" "}
                                  🇳🇬{" "}
                                  <span
                                    id="try-yoruba-demo-1"
                                    class="fa fa-user"
                                  ></span> */}{" "}
                                  {21 ? "Learn Yoruba" : "YORUBA"}{" "}
                                </a>
                              </li>
                              <li>
                                <a
                                  class="buttonmargin fontB fontregular btn btn-outline-secondary my-2 my-sm-0"
                                  onClick={(e) =>
                                    value.guestlogin(e, "swahili")
                                  }
                                  href="javascript:void(0);"
                                >
                                  <img
                                    id="selectlangimage"
                                    src={tanzaniaflaglineicon}
                                  ></img>
                                  <img
                                    id="selectlangimagepair2"
                                    src={kenyaflaglineicon}
                                  ></img>{" "}
                                  {21 ? "Learn Swahili" : "SWAHILI"}
                                </a>
                              </li>
                              <li>
                                <a
                                  id="darkyellow"
                                  class="buttonmargin fontB fontregular btn btn-outline-warning my-2 my-sm-0"
                                  onClick={(e) => value.guestlogin(e, "ga")}
                                  href="javascript:void(0);"
                                >
                                  <img
                                    id="selectlangimage"
                                    src={ghanaflaglineicon}
                                  ></img>
                                  {/* {" "}
                                  🇬🇭{" "}
                                  <span id="try-ga-demo-1" class="fa fa-user">
                                    {" "}
                                  </span> */}{" "}
                                  {21 ? "Learn Ga" : "GA"}
                                </a>
                              </li>

                              <li>
                                <a
                                  // id="textsoftredid"
                                  class="buttonmargin fontB fontregular btn btn-outline-danger my-2 my-sm-0"
                                  onClick={(e) => value.guestlogin(e, "igbo")}
                                  href="javascript:void(0);"
                                >
                                  <img
                                    id="selectlangimage"
                                    src={naijaflaglineicon}
                                  ></img>{" "}
                                  {21 ? "Learn Igbo" : "IGBO"}{" "}
                                </a>
                              </li>

                              {this.languageListItem(
                                value,
                                "Mòoré",
                                burkinafasoflaglineicon,
                                "btn btn-outline-danger",
                                "",
                                "",
                                true
                              )}

                              <li>
                                <a
                                  class="buttonmargin fontB fontregular btn btn-outline-success my-2 my-sm-0"
                                  onClick={(e) => value.guestlogin(e, "ewe")}
                                  href="javascript:void(0);"
                                >
                                  <img
                                    id="selectlangimage"
                                    src={togoflaglineicon}
                                  ></img>
                                  <img
                                    id="selectlangimagepair2"
                                    src={ghanaflaglineicon}
                                  ></img>{" "}
                                  {21 ? "Learn Ewe" : "EWE"}
                                </a>
                              </li>
                              {/* {this.languageListItem(
                                value,
                                "krio",
                                zambiaflaglineicon,
                                "btn btn-outline-success",
                                "",
                                "",
                                true
                              )} */}
                              {this.languageListItem(
                                value,
                                "Somali",
                                somaliaflaglineicon,
                                "btn btn-outline-info",
                                "",
                                "",
                                true
                              )}
                              {this.languageListItem(
                                value,
                                "Shona",
                                zimbabweflaglineicon,
                                "btn btn-outline-warning",
                                "darkyellow",
                                "",
                                true
                              )}
                              {/* {this.languageListItem(
                                value,
                                "Bemba",
                                zambiaflaglineicon,
                                "btn btn-outline-success",
                                "",
                                "",
                                true
                              )}
                              {this.languageListItem(
                                value,
                                "Luganda",
                                ugandaflaglineicon,
                                "btn btn-outline-secondary",
                                "",
                                "",
                                true
                              )} */}

                              <li>{this.displayDemosList2(value)}</li>

                              {/* <li>
                                <DropdownExampleInline />
                              </li> */}
                            </ul>
                            <br />
                            {/* <br /> */}
                          </div>
                        </Link>
                      </ScrollableAnchor>
                    </div>

                    <div class="col-md-5">
                      <img
                        class="img-fluid chooselanguage center-block my-5 px-3"
                        alt="Twi language, Yoruba, Hausa, Fante, Igbo, Swahili, Amharic, Shona"
                        src={chooselangimg}
                      />
                    </div>
                  </div>
                </div>

                <div class="container-fluid threestepsdivs grey">
                  <div class="row">
                    <div class="col-md-7">
                      <img
                        class="img-fluid my-5 pl-3"
                        alt="africa business fashion travel tourism family tour invest africa study art app"
                        src="Images/study-focus-graphics-kente.png"
                      />
                    </div>

                    <div class="col-md-5 ">
                      <h2 class="zoomer bold fontB movedownfocus text-center mx-5">
                        <img
                          class="lineiconimg"
                          src={two}
                          alt="africa language for business travel"
                        />{" "}
                        Choose Lesson Topic
                      </h2>
                      <p class=" text-justify fontbs studyfocuschoice fontsmall mx-5">
                        {" "}
                        Learn African languages and culture in a manner that is
                        immediately useful. We want to help you learn phrases
                        that will quickly help you build better relationships,
                        convey your own unique thoughts and navigate realistic
                        and culturally accurate situations.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="container-fluid white threestepsdivs westafricanpov ">
                  <div class="row">
                    <div class="col-md-6">
                      <h2 class="zoomer fontB text-center bold paddingfordivs movedown2 mx-3">
                        <img
                          class="lineiconimg"
                          src={three}
                          alt="learn africa"
                        />{" "}
                        Start Learning!
                      </h2>
                      <p class=" text-justify fontbs fontsmall pchooseyourlanguage mx-5">
                        {" "}
                        Start to learn African languages and culture with our
                        courses in Akan Twi, Yoruba, Ga, Swahili or Igbo. We
                        continue to develop our platform and lessons to best
                        serve our users. Remember to give us some
                        <a
                          id="homepage_feedback"
                          class="bold text-info"
                          alt="africa platform app"
                          href={
                            "https://brown.co1.qualtrics.com/jfe/form/SV_cOMTf4CUsSf5iyp"
                          }
                        >
                          {" "}
                          feedback{" "}
                        </a>
                        so we can innovate and build with you in mind!{" "}
                      </p>
                    </div>

                    <div class="col-md-6">
                      <img
                        class=" d-block mx-auto img-fluid my-5"
                        src="Images/man-on-keyboard-small.png"
                        alt="learn twi: travel and speak akan with Twi language app "
                      />
                      {!value.isLoggedIn ? (
                        <Link
                          to={{
                            pathname: "/signup",
                            state: {
                              from: "/",
                            },
                          }}
                          to="/signup"
                          id="thinbuttonextrastyle"
                          class="fontB fontregular d-block mx-auto btn btn-danger center-block westafrican "
                        >
                          Start Free Lessons
                        </Link>
                      ) : (
                        <a
                          onClick={(e) => {
                            value.recent_select_course_redirect();
                          }}
                          href="javascript:void(0);"
                          id="thinbuttonextrastyle"
                          class="fontB fontregular d-block mx-auto btn btn-danger center-block westafrican "
                        >
                          Continue Course
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/*<!--Footer-->*/}
              <footer class="fontB footer page-footer font-small pt-4 mt-4">
                {/*<!--Footer Links-->*/}
                <div class="container-fluid text-center">
                  <div class="row">
                    {/*<!--First column-->*/}
                    <div class="col-md-6">
                      <h5 class="text-uppercase fontbs">Connect with us</h5>
                      <a href="https://facebook.com/DialogueAfricaPlatform">
                        <img
                          id="fbmediaicon"
                          class="img-fluid"
                          alt="africa language culture facebook"
                          src={facebookicon}
                        />
                      </a>
                      <a href="/blog-main.html">
                        <img
                          id="blogicon"
                          class="img-fluid"
                          alt="africa language culture blog"
                          src={blogicon}
                        />
                      </a>

                      <a href="https://instagram.com/dialogue_africa">
                        <img
                          id="socialmediaicon"
                          class="img-fluid"
                          alt="africa language culture instagram"
                          src="Images/instagramicon.PNG"
                        />
                      </a>

                      <a href="https://medium.com/@dialogueafrica">
                        <img
                          id="mediumicon"
                          class="img-fluid"
                          alt="africa language culture patreon donate"
                          src="Images/mediumicon.png"
                        />
                      </a>
                      <br/> <br/>
                      <div>
                        <a href='https://apps.apple.com/us/app/dialogue-africa/id1639179695'><img id='badgeiconapple' alt='Download on the App Store' src={appStoreBadge}/></a>
                        <a href='https://play.google.com/store/apps/details?id=com.dialogue_africa.dialogue_africa&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img id='badgeicon' alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>

                      </div>
                      <br/>
                    </div>


                    {/*<!--Second column-->*/}
                    <div class="col-md-6">
                      <h5 class="text-uppercase fontbs">Available Courses</h5>
                      <ul class="list-unstyled fontbs">
                        <li>
                          <Link
                            class="fontB fontregular"
                            id="footertext"
                            onClick={(e) => value.guestlogin(e, "twi")}
                            to="javascript:void(0);"
                          >
                            {" "}
                            Learn Twi{" "}
                          </Link>
                        </li>
                        <li>
                          <Link
                            class="fontB fontregular"
                            id="footertext"
                            onClick={(e) => value.guestlogin(e, "yoruba")}
                            to="javascript:void(0);"
                          >
                            {" "}
                            Learn Yoruba{" "}
                          </Link>
                        </li>
                        <li>
                          <Link
                            class="fontB fontregular"
                            id="footertext"
                            onClick={(e) => value.guestlogin(e, "ga")}
                            to="javascript:void(0);"
                          >
                            {" "}
                            Learn Ga{" "}
                          </Link>
                        </li>
                        <li>
                          <Link
                            class="fontB fontregular"
                            id="footertext"
                            onClick={(e) => value.guestlogin(e, "swahili")}
                            to="javascript:void(0);"
                          >
                            {" "}
                            Learn Swahili{" "}
                          </Link>
                        </li>
                        <li>
                          <Link
                            class="fontB fontregular"
                            id="footertext"
                            onClick={(e) => value.guestlogin(e, "igbo")}
                            to="javascript:void(0);"
                          >
                            {" "}
                            Learn Igbo{" "}
                          </Link>
                        </li>
                      </ul>
                    </div>
                    {/*<!--/.Second column-->*/}
                  </div>
                </div>
                {/*<!--/.Footer Links-->*/}

                {/*<!--Copyright-->*/}
                <div class="footer-copyright py-3 text-center">
                  <p class="fontB fontregular" id="footertext">
                    Made with{" "}
                    <span class="heart" style={{ color: "#ff0d4e" }}>
                      ♥
                    </span>{" "}
                    by Dialogue Africa.{" "}
                    {/* <span class="heart" style={{ color: "#794121" }}>
                      ♥
                    </span>
                    <span class="heart" style={{ color: "#130c0e" }}>
                      ♥
                    </span>{" "} */}
                  </p>

                  <a href="mailto:info@dialogue-africa.com">
                    <p class="fontB fontregular" id="footertext">
                      <span class="fa fa-envelope" aria-hidden="true"></span>{" "}
                      info@dialogue-africa.com{" "}
                    </p>
                  </a>
                  <p id="footertext" class="fontB fontregular">
                    <span class="fa fa-copyright"></span> 2018 - 2024 Dialogue Africa. All rights reserved.{" "}
                    <a
                      id="footertext"
                      class="fontB fontregular"
                      href="privacy-policy.html"
                    >
                      Privacy Policy
                    </a>
                    {". "}
                    <a
                      id="footertext"
                      class="fontB fontregular"
                      href="terms-and-conditions.html"
                    >
                      Terms & Conditions
                    </a>{" "}
                  </p>
                </div>
                {/*<!--/.Copyright-->*/}
              </footer>
              {/*<!--/.Footer-->*/}

              <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.4/jquery.min.js"></script>
              <script
                src="https://code.jquery.com/jquery-3.2.1.slim.min.js"
                integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN"
                crossorigin="anonymous"
              ></script>
              <script
                src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js"
                integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q"
                crossorigin="anonymous"
              ></script>
              <script
                src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js"
                integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl"
                crossorigin="anonymous"
              ></script>
            </body>
          </html>
        )}
      </UserConsumer>
    );
  }
}

export default Selectlanguage;
