import React, { useContext, useState } from "react";
import Multiplechoicethree from "./components/Multiplechoicethree";
import MultiplechoiceGeneral from "./components/MultiplechoiceGeneral";
import Selectlanguage from "./Selectlanguage";
import TwiSelectlanguage from "./TwiSelectlanguage";
import SwahiliSelectlanguage from "./SwahiliSelectlanguage";
import GaSelectlanguage from "./GaSelectlanguage";
import YorubaSelectlanguage from "./YorubaSelectlanguage";
import IgboSelectlanguage from "./IgboSelectlanguage";
import Selectcourse from "./Selectcourse";
import Welcomeculture from "./Welcomeculture";
import WelcomeBasicGrammar from "./WelcomeBasicGrammar";
import TranslateQuiz from "./components/TranslateQuiz/TranslateQuiz.js";
import SignupForm from "./components/SignupForm/SignUpForm.js";
import SubscribeForm from "./components/SignupForm/SubscribeForm.js";
import LoginForm from "./components/SignupForm/LoginForm.js";
import ConvertForm from "./components/SignupForm/ConvertForm.js";
import CrowdsourceForm from "./components/SignupForm/CrowdsourceForm.js";
import RetrievePasswordForm from "./components/SignupForm/RetrievePasswordForm.js";
import PasswordResetForm from "./components/SignupForm/PasswordResetForm.js";
import { Switch, Redirect, Route } from "react-router-dom";
import { UserConsumer } from "./components/User/User.js";
import CheckoutRedirect from "./CheckoutFormThree.js";
import PaymentOptions from "./PaymentOptions.js";
import DeleteUser from "./components/SignupForm/DeleteUser.js";
import { hotjar } from "react-hotjar";

//Add hotjar tracking
hotjar.initialize(1125790, 6);

// Custom wrapper for <Route> using premium access level and profile status
// to determine access versus redirection
const PR = (props) => {
  const {
    component,
    num_questions,
    lesson_number,
    lesson_type,
    path,
    language,
    select_course_link,
    using_african_word,
    limited: limited_a,
  } = props;
  // Get quiz access component or redirect accordingly
  const getComponent = (
    urlPath,
    limited_path,
    contextvalue,
    routeRenderProps
  ) => {
    // console.log("getcomp ctx 1: ", contextvalue);
    // console.log("getcomp limited: ", limited_a);
    const user = contextvalue.user;
    const isLoggedIn = contextvalue.isLoggedIn;
    const isGuest = contextvalue.isGuest;
    const premiumMember = localStorage.getItem("accessStatus") == "true"; //contextvalue.hasPremiumAccess; //
    const registeredMember = isLoggedIn && !isGuest;

    // Even if quiz is not limited user has to be classified as guest or
    // registered to access quiz. Disallows unexpected paths to quiz access
    const freeTierAccessible =
      (isGuest || registeredMember) && limited_path !== "true";
    // console.log("getcomp limited_path: ", limited_path);
    // console.log("getcomp freeTierAccessible: ", freeTierAccessible);
    let proceedToQuiz = premiumMember || freeTierAccessible;
    const Mycomponenta = component;

    let redirectPath = "/signup";
    // console.log("getcomp ctx 2: ", contextvalue);
    // console.log(
    //   `getcomp premiumMember:${premiumMember}, path:${urlPath}, ctx:${contextvalue}`
    // );
    // console.log(
    //   `getcomp pre-condition check registeredMember:${registeredMember}, premiumMember:${premiumMember}, registeredMember && !premiumMember: ${
    //     registeredMember && !premiumMember
    //   }`
    // );
    if (!premiumMember) {
      // redirectPath = "/subscription-options";
      redirectPath = "/checkout-redirect";
    }
    // if (registeredMember && !premiumMember) {
    //   // reserve payment features for Twi only
    //   // currently language prop is unset for twi
    //   // PR components.
    //   // if (language != null) {
    //   //   console.log("getcomp language!=null path");
    //   //   proceedToQuiz = true;
    //   // } else {
    //   // console.log("getcomp redirect set to checkout-redirect path");
    //   redirectPath = "/subscription-options";
    //   // }
    // } else if (isGuest && !registeredMember) {
    //   // console.log("getcomp elseif: redirect to /create_profile");
    //   // missing profile
    //   redirectPath = "/create_profile";
    // } else {
    //   console.log("getcomp overlooked else path");
    // }
    // console.log(
    //   `getcomp proceedToQuiz:${proceedToQuiz}, redirectPath:${redirectPath}`
    // );
    // console.log(`getcomp registeredMember:${registeredMember}`);
    // console.log(
    //   `getcomp language: ${language}. (language != null) => ${language != null}`
    // );
    if (proceedToQuiz) {
      // console.log("getcomp proceeding to Quiz");
      return (
        <Mycomponenta
          myuser={user}
          num_questions={num_questions}
          lesson_number={lesson_number}
          lesson_type={lesson_type}
          contextapivalue={contextvalue}
          my_path={path}
          language={language}
          select_course_link={select_course_link}
          using_african_word={using_african_word}
          {...routeRenderProps}
        />
      );
    } else {
      console.log("getcomp redirecting to: ", redirectPath);
      const link_before_redirect = routeRenderProps.location.pathname;
      return (
        <Redirect
          to={{
            pathname: redirectPath,
            state: {
              from: link_before_redirect,
              language: language,
            },
          }}
        />
      );
    }
  };
  let urlPath = path;
  let ctx = useContext(UserConsumer);
  return (
    <div>
      <Route
        path={urlPath}
        render={(render_props) => {
          return getComponent(urlPath, limited_a, ctx, render_props);
        }}
      />
    </div>
  );
};

// Custom wrapper for <Route> using premium status to determine
// access versus redirection
const PremiumUserRoute = ({ component: MyComponent, language, path: path }) => {
  let redirectPath = "/checkout-redirect";

  // if user from context is registered & has premium access proceed
  // else send to redirect path.
  const myfunc = (glb_ctx, props) => {
    const isPremiumMember = glb_ctx.hasPremiumAccess;

    let proceed = glb_ctx.isLoggedIn && !glb_ctx.isGuest && isPremiumMember;
    if (proceed) {
      return (
        <MyComponent
          contextapivalue={glb_ctx}
          myuser={glb_ctx.user}
          language={language}
          {...props}
        />
      );
    } else {
      // console.log("--> syc redirect pur: ", redirectPath);
      return (
        <Redirect
          to={{
            pathname: redirectPath,
            state: { from: props.location.pathname },
          }}
        />
      );
    }
  };
  return (
    <UserConsumer>
      {(glb_ctx) => (
        <Route
          path={path}
          render={(props) => {
            // console.log("syc stack pur: ", props.location.pathname);
            return myfunc(glb_ctx, props);
          }}
        />
      )}
    </UserConsumer>
  );
};

// Custom wrapper for <Route> using login status to determine
// access versus redirection
const RouteWithCtx = ({ component: MyComponent, path: path, ...rest }) => {
  return (
    <UserConsumer>
      {(glb_ctx) => (
        <Route
          path={path}
          render={(props) => {
            return (
              <MyComponent
                contextapivalue={glb_ctx}
                myuser={glb_ctx.user}
                {...props}
                {...rest}
              />
            );
          }}
        />
      )}
    </UserConsumer>
  );
};
const UserOrGuestLoginRoute = ({
  component: MyComponent,
  language,
  path: path,
  ...rest
}) => {
  let redirectPath = "/signup";
  return (
    <UserConsumer>
      {(glb_ctx) => (
        <Route
          path={path}
          render={(props) => {
            // console.log("syc stack ugl: ", props.location.pathname);
            if (glb_ctx.isLoggedIn) {
              return (
                <MyComponent
                  contextapivalue={glb_ctx}
                  myuser={glb_ctx.user}
                  language={language}
                  {...props}
                  {...rest}
                />
              );
            } else {
              // console.log("--> syc redirect ugl: ", redirectPath);
              return (
                <Redirect
                  to={{
                    pathname: redirectPath,
                    state: { from: props.location.pathname },
                  }}
                />
              );
            }
          }}
        />
      )}
    </UserConsumer>
  );
};

// Custom wrapper for <Route> using registration status to determine
// access versus redirection
const RegisteredMemberRoute = ({
  path: urlPath,
  language,
  component: MyComponent,
}) => {
  let redirectPath = "/signup";
  return (
    <UserConsumer>
      {(glb_ctx) => {
        const registeredMember = glb_ctx.isLoggedIn && !glb_ctx.isGuest;
        // mightHaveProgress not needed for Early Adopter Premium Pass
        const SAVE_PROGRESS_PATH = "/create_profile";
        const mightHaveProgress = glb_ctx.isGuest && !registeredMember;
        if (mightHaveProgress) {
          // console.log("syc: RMR mightHaveProgress: ctx", glb_ctx);
          redirectPath = SAVE_PROGRESS_PATH;
        }
        return (
          <Route
            path={urlPath}
            render={(props) => {
              // console.log("syc stack rmr: ", props.location.pathname);
              if (registeredMember) {
                return (
                  <MyComponent
                    contextapivalue={glb_ctx}
                    myuser={glb_ctx.user}
                    {...props}
                    language={language}
                  />
                );
              } else {
                // console.log("--> syc redirect rmr: ", redirectPath);
                return (
                  <Redirect
                    to={{
                      pathname: redirectPath,
                      state: {
                        from: props.location.pathname,
                      },
                    }}
                  />
                );
              }
            }}
          />
        );
      }}
    </UserConsumer>
  );
};

// Class component defining & matching relative url paths with their
// counterpart JSX Components using basic & custom <Route> components
const Root = () => {
  return (
    <div>
      <Switch>
        <div>
          {/* Welcome / Starting Pages Routes */}
          <Route exact path="/" component={Selectlanguage} />
          {/* <Route exact path="learn/twi/en/Learn-Twi" component={TwiSelectlanguage} /> */}
          <Route exact path="/course/Learn-Twi" component={TwiSelectlanguage} />
          <Route
            exact
            path="/course/Learn-Yoruba"
            component={YorubaSelectlanguage}
          />
          <Route exact path="/course/Learn-Ga" component={GaSelectlanguage} />
          <Route
            exact
            path="/course/Learn-Swahili"
            component={SwahiliSelectlanguage}
          />
          <Route
            exact
            path="/course/Learn-Igbo"
            component={IgboSelectlanguage}
          />
          <UserOrGuestLoginRoute
            path="/select_course"
            component={Selectcourse}
            language="twi"
          />
          <UserOrGuestLoginRoute
            path="/select-yoruba-course"
            component={Selectcourse}
            language="yoruba"
          />
          {/* <UserOrGuestLoginRoute
            path="/select-moore-course"
            component={Selectcourse}
            language="moore"
          /> */}
          <UserOrGuestLoginRoute
            path="/select-ga-course"
            component={Selectcourse}
            language="ga"
          />
          <UserOrGuestLoginRoute
            path="/select-igbo-course"
            component={Selectcourse}
            language="igbo"
          />
          <UserOrGuestLoginRoute
            path="/select-swahili-course"
            component={Selectcourse}
            language="swahili"
          />
          {/* Pass in lanugage to allow UserLessonProgressDetails to be setup in backend */}
          {/* <UserOrGuestLoginRoute
            path="/select_moore_course"
            component={Selectcourse}
            language="moore"
          />
          <UserOrGuestLoginRoute
            path="/select_ewe_course"
            component={Selectcourse}
            language="ewe"
          />
          <UserOrGuestLoginRoute
            path="/select_somali_course"
            component={Selectcourse}
            language="somali"
          /> */}
          {/*For clashing paths: Order matters here to give previous select course links precedence*/}
          {/* User example below to pass props to route & then handle passed
          information */}
          {/* history.push({
           pathname: '/secondpage',
           search: '?query=abc',
           state: { detail: 'some_value' }
          }); */}
          {/* Will work when getLanguage is added to Selectcourse &
          all push calls to Selectcourse add a language prop to state */}
          <UserOrGuestLoginRoute
            path="/select_:culture(somali|moore|ewe|shona|hausa|fante|lingala|wolof|luganda|kinyarwanda|chichewa|bemba|setswana)_course"
            component={Selectcourse}
            language="general"
          />
          {/* Swahili Lesson Quiz Routes*/}
          <PR
            path="/swahili-course-homenouns_quiz"
            limited="false"
            component={MultiplechoiceGeneral}
            num_questions={12}
            lesson_number={"10"}
            language="swahili"
            select_course_link="/select-swahili-course"
            using_african_word={true}
          />

          <PR
            path="/swahili-wellbeing-quiz"
            limited="false"
            component={MultiplechoiceGeneral}
            num_questions={12}
            lesson_number={"72"}
            language="swahili"
            select_course_link="/select-swahili-course"
            using_african_word={true}
          />
          <PR
            path="/swahili-bodyparts-quiz"
            limited="false"
            component={MultiplechoiceGeneral}
            num_questions={12}
            lesson_number={"8"}
            language="swahili"
            select_course_link="/select-swahili-course"
            using_african_word={true}
          />
          <PR
            path="/swahili-chores_quiz"
            limited="false"
            component={MultiplechoiceGeneral}
            num_questions={12}
            lesson_number={"16"}
            language="swahili"
            select_course_link="/select-swahili-course"
            using_african_word={true}
          />
          <PR
            path="/swahili-animals_quiz"
            limited="false"
            component={MultiplechoiceGeneral}
            num_questions={12}
            lesson_number={"13"}
            language="swahili"
            select_course_link="/select-swahili-course"
            using_african_word={true}
          />
          <PR
            path="/swahili-romance_quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={12}
            lesson_number={"20"}
            language="swahili"
            select_course_link="/select-swahili-course"
            using_african_word={true}
          />
          <PR
            path="/swahili-colors_quiz"
            limited="false"
            component={MultiplechoiceGeneral}
            num_questions={12}
            lesson_number={"14"}
            language="swahili"
            select_course_link="/select-swahili-course"
            using_african_word={true}
          />
          <PR
            path="/swahili-marketfood-quiz"
            limited="false"
            component={MultiplechoiceGeneral}
            num_questions={12}
            lesson_number={"6"}
            language="swahili"
            select_course_link="/select-swahili-course"
            using_african_word={true}
          />
          <PR
            path="/swahili-marketfood-2-quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={12}
            lesson_number={"18"}
            language="swahili"
            select_course_link="/select-swahili-course"
            using_african_word={true}
          />
          <PR
            path="/swahili-professions-quiz"
            limited="false"
            component={MultiplechoiceGeneral}
            num_questions={12}
            lesson_number={"5"}
            language="swahili"
            select_course_link="/select-swahili-course"
            using_african_word={true}
          />
          <PR
            path="/swahili-homeverbs-quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={12}
            lesson_number={"11"}
            language="swahili"
            select_course_link="/select-swahili-course"
            using_african_word={true}
          />
          <PR
            path="/swahili-medicalnouns-quiz"
            limited="false"
            component={MultiplechoiceGeneral}
            num_questions={12}
            lesson_number={"17"}
            language="swahili"
            select_course_link="/select-swahili-course"
            using_african_word={true}
          />
          <PR
            path="/swahili-course-greetings-quiz"
            limited="false"
            component={MultiplechoiceGeneral}
            num_questions={14}
            lesson_number={"22"}
            language="swahili"
            select_course_link="/select-swahili-course"
            using_african_word={true}
          />
          <PR
            path="/swahili-family-quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={14}
            lesson_number={"15"}
            language="swahili"
            select_course_link="/select-swahili-course"
            using_african_word={true}
          />
          <PR
            path="/swahili-atthebar-quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={14}
            lesson_number={"7"}
            language="swahili"
            select_course_link="/select-swahili-course"
            using_african_word={true}
          />
          {/* Igbo Lesson Quiz Routes*/}
          <PR
            path="/igbo_numbers_2_vocab_quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={14}
            lesson_number={"416"}
            language="igbo"
            select_course_link="/select-igbo-course"
            using_african_word={true}
          />
          <PR
            path="/igbo_numbers_quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={14}
            lesson_number={"415"}
            language="igbo"
            select_course_link="/select-igbo-course"
            using_african_word={true}
          />
          <PR
            path="/igbo-course-greetings-quiz"
            limited="false"
            component={MultiplechoiceGeneral}
            num_questions={14}
            lesson_number={"22"}
            language="igbo"
            select_course_link="/select-igbo-course"
            using_african_word={true}
          />
          <PR
            path="/igbo-professions-quiz"
            limited="false"
            component={MultiplechoiceGeneral}
            num_questions={23}
            lesson_number={"5"}
            language="igbo"
            select_course_link="/select-igbo-course"
            using_african_word={true}
          />
          <PR
            path="/igbo-professions-2-quiz"
            limited="false"
            component={MultiplechoiceGeneral}
            num_questions={14}
            lesson_number={"42"}
            language="igbo"
            select_course_link="/select-igbo-course"
            using_african_word={true}
          />
          <PR
            path="/igbo-medicalnouns-quiz"
            limited="false"
            component={MultiplechoiceGeneral}
            num_questions={14}
            lesson_number={"17"}
            language="igbo"
            select_course_link="/select-igbo-course"
            using_african_word={true}
          />
          <PR
            path="/igbo-romance-quiz"
            limited="false"
            component={MultiplechoiceGeneral}
            num_questions={12}
            lesson_number={"20"}
            language="igbo"
            select_course_link="/select-igbo-course"
            using_african_word={true}
          />
          <PR
            path="/igbo-animals-quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={12}
            lesson_number={"13"}
            language="igbo"
            select_course_link="/select-igbo-course"
            using_african_word={true}
          />
          <PR
            path="/igbo-course-homenouns_quiz"
            limited="false"
            component={MultiplechoiceGeneral}
            num_questions={14}
            lesson_number={"10"}
            language="igbo"
            select_course_link="/select-igbo-course"
            using_african_word={true}
          />

          <PR
            path="/igbo-colors_quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={14}
            lesson_number={"14"}
            language="igbo"
            select_course_link="/select-igbo-course"
            using_african_word={true}
          />
          <PR
            path="/igbo-marketfood-quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={14}
            lesson_number={"6"}
            language="igbo"
            select_course_link="/select-igbo-course"
            using_african_word={true}
          />
          <PR
            path="/igbo-course-family-quiz"
            limited="false"
            component={MultiplechoiceGeneral}
            num_questions={14}
            lesson_number={"15"}
            language="igbo"
            select_course_link="/select-igbo-course"
            using_african_word={true}
          />
          <PR
            path="/igbo-family-blended-quiz"
            limited="false"
            component={MultiplechoiceGeneral}
            num_questions={14}
            lesson_number={"54"}
            language="igbo"
            select_course_link="/select-igbo-course"
            using_african_word={true}
          />
          <PR
            path="/igbo-greetings-blended-quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={10}
            lesson_number={"52"}
            language="igbo"
            select_course_link="/select-igbo-course"
            using_african_word={true}
          />
          {/* Moore */}
          <PR
            path="/course-family-quiz"
            limited="false"
            component={MultiplechoiceGeneral}
            num_questions={14}
            lesson_number={"15"}
            language="general"
            using_african_word={true}
          />
          <PR
            path="/course-greetings-vocab-quiz"
            limited="false"
            component={MultiplechoiceGeneral}
            num_questions={14}
            lesson_number={"22"}
            language="general"
            using_african_word={true}
          />
          <PR
            path="/course-homenouns-quiz"
            limited="false"
            component={MultiplechoiceGeneral}
            num_questions={12}
            lesson_number={"10"}
            language="general"
            using_african_word={true}
          />
          <PR
            path="/course-homeverbs-quiz"
            limited="false"
            component={MultiplechoiceGeneral}
            num_questions={12}
            lesson_number={"11"}
            language="general"
            using_african_word={true}
          />
          <PR
            path="/course-marketfood-quiz"
            limited="false"
            component={MultiplechoiceGeneral}
            num_questions={12}
            lesson_number={"6"}
            language="general"
            using_african_word={true}
          />
          {/* Ga Lesson Quiz Routes*/}
          <PR
            path="/ga-romance-blended-quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={10}
            lesson_number={"383"}
            language="ga"
            select_course_link="/select-ga-course"
            using_african_word={true}
          />
          <PR
            path="/ga-romance-quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={10}
            lesson_number={"20"}
            language="ga"
            select_course_link="/select-ga-course"
            using_african_word={true}
          />
          <PR
            path="/accra-boxers-quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={10}
            lesson_number={"280"}
            language="ga"
            select_course_link="/select-ga-course"
            using_african_word={true}
          />
          <PR
            path="/ga-chores-quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={10}
            lesson_number={"16"}
            language="ga"
            select_course_link="/select-ga-course"
            using_african_word={true}
          />
          <PR
            path="/ga-greetings_phrases_quiz"
            limited="false"
            component={MultiplechoiceGeneral}
            num_questions={23}
            lesson_number={"76"}
            language="ga"
            select_course_link="/select-ga-course"
            using_african_word={true}
          />
          <PR
            path="/ga-course-professions_quiz"
            limited="false"
            component={MultiplechoiceGeneral}
            num_questions={23}
            lesson_number={"5"}
            language="ga"
            select_course_link="/select-ga-course"
            using_african_word={true}
          />

          <PR
            path="/ga-atthebar-vocab-quiz"
            limited="false"
            component={MultiplechoiceGeneral}
            num_questions={14}
            lesson_number={"7"}
            language="ga"
            select_course_link="/select-ga-course"
            using_african_word={true}
          />
          <PR
            path="/ga-homeverbs-quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={14}
            lesson_number={"11"}
            language="ga"
            select_course_link="/select-ga-course"
            using_african_word={true}
          />
          <PR
            path="/ga-family-quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={14}
            lesson_number={"15"}
            language="ga"
            select_course_link="/select-ga-course"
            using_african_word={true}
          />
          <PR
            path="/ga-course-greetings-quiz"
            limited="false"
            component={MultiplechoiceGeneral}
            num_questions={14}
            lesson_number={"22"}
            language="ga"
            select_course_link="/select-ga-course"
            using_african_word={true}
          />
          <PR
            path="/ga-course-homenouns_quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={12}
            lesson_number={"10"}
            language="ga"
            select_course_link="/select-ga-course"
            using_african_word={true}
          />
          <PR
            path="/ga-medicalnouns-quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={12}
            lesson_number={"17"}
            language="ga"
            select_course_link="/select-ga-course"
            using_african_word={true}
          />
          <PR
            path="/ga-marketfood-quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={12}
            lesson_number={"6"}
            language="ga"
            select_course_link="/select-ga-course"
            using_african_word={true}
          />
          <PR
            path="/ga-marketfood-2-quiz"
            limited="false"
            component={MultiplechoiceGeneral}
            num_questions={12}
            lesson_number={"18"}
            language="ga"
            select_course_link="/select-ga-course"
            using_african_word={true}
          />
          {/* Yoruba Lesson Quiz Routes
                Find way to get web-crawlers to these links even without user account
                and assess effectiveness of such strategies

              */}
          {/* <PR
            path="/yoruba-course-greetings-vocab-quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={12}
            lesson_number={"22"}
            language="yoruba"
            select_course_link="/select-yoruba-course"
            using_african_word={true}
          /> */}
          <PR
            path="/igbo-wellbeing-quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={12}
            lesson_number={"347"}
            language="igbo"
            select_course_link="/select-igbo-course"
            using_african_word={true}
          />
          <PR
            path="/igbo-meals-2-quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={12}
            lesson_number={"214"}
            language="igbo"
            select_course_link="/select-igbo-course"
            using_african_word={true}
          />
          <PR
            path="/igbo-meals-1-quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={12}
            lesson_number={"115"}
            language="igbo"
            select_course_link="/select-igbo-course"
            using_african_word={true}
          />
          <PR
            path="/igbo-artists-puzzle-quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={12}
            lesson_number={"80"}
            language="igbo"
            select_course_link="/select-igbo-course"
            using_african_word={true}
          />
          <PR
            path="/igbo-cities-puzzle-quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={12}
            lesson_number={"81"}
            language="igbo"
            select_course_link="/select-igbo-course"
            using_african_word={true}
          />
          <PR
            path="/igbo-marketfood-2"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={12}
            lesson_number={"18"}
            language="igbo"
            select_course_link="/select-igbo-course"
            using_african_word={true}
          />
           <PR
            path="/yoruba_numbers_quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={14}
            lesson_number={"415"}
            language="yoruba"
            select_course_link="/select-yoruba-course"
            using_african_word={true}
          />
          <PR
            path="/yoruba_intro_2_quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={14}
            lesson_number={"349"}
            lesson_type="blended"
            language="yoruba"
            select_course_link="/select-yoruba-course"
            using_african_word={true}
          />
          <PR
            path="/yoruba-culture-quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={14}
            lesson_number={"3"}
            lesson_type="blended"
            language="yoruba"
            select_course_link="/select-yoruba-course"
            using_african_word={true}
          />
          <PR
            path="/yoruba-greetings-2-quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={14}
            lesson_number={"247"}
            lesson_type="blended"
            language="yoruba"
            select_course_link="/select-yoruba-course"
            using_african_word={true}
          />
          <PR
            path="/yoruba-wellbeing-quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={12}
            lesson_number={"148"}
            language="yoruba"
            select_course_link="/select-yoruba-course"
            using_african_word={true}
          />
          <PR
            path="/naija-artists-puzzle-quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={12}
            lesson_number={"79"}
            language="yoruba"
            select_course_link="/select-yoruba-course"
            using_african_word={true}
          />
          <PR
            path="/yoruba-colors_quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={14}
            lesson_number={"14"}
            language="yoruba"
            select_course_link="/select-yoruba-course"
            using_african_word={true}
          />
          <PR
            path="/yoruba-animals-quiz"
            limited="false"
            component={MultiplechoiceGeneral}
            num_questions={12}
            lesson_number={"13"}
            language="yoruba"
            select_course_link="/select-yoruba-course"
            using_african_word={true}
          />
          <PR
            path="/yoruba-romance-quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={12}
            lesson_number={"20"}
            language="yoruba"
            select_course_link="/select-yoruba-course"
            using_african_word={true}
          />
          <PR
            path="/yoruba-atthebar-quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={14}
            lesson_number={"7"}
            language="yoruba"
            select_course_link="/select-yoruba-course"
            using_african_word={true}
          />
          <PR
            path="/yoruba-course-family-quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={12}
            lesson_number={"15"}
            language="yoruba"
            select_course_link="/select-yoruba-course"
            using_african_word={true}
          />
          <PR
            path="/yoruba-course-homenouns_quiz"
            limited="false"
            component={MultiplechoiceGeneral}
            num_questions={12}
            lesson_number={"10"}
            language="yoruba"
            select_course_link="/select-yoruba-course"
            using_african_word={true}
          />
          <PR
            path="/yoruba-course-professions_quiz"
            limited="false"
            component={MultiplechoiceGeneral}
            num_questions={23}
            lesson_number={"5"}
            language="yoruba"
            select_course_link="/select-yoruba-course"
            using_african_word={true}
          />
          <PR
            path="/yoruba-chores-quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={10}
            lesson_number={"16"}
            language="yoruba"
            select_course_link="/select-yoruba-course"
            using_african_word={true}
          />
          <PR
            path="/yoruba-bodyparts-quiz"
            limited="false"
            component={MultiplechoiceGeneral}
            num_questions={10}
            lesson_number={"8"}
            language="yoruba"
            select_course_link="/select-yoruba-course"
            using_african_word={true}
          />
          <PR
            path="/igbo-bodyparts-quiz"
            limited="false"
            component={MultiplechoiceGeneral}
            num_questions={10}
            lesson_number={"8"}
            language="igbo"
            select_course_link="/select-igbo-course"
            using_african_word={true}
          />
          <PR
            path="/yoruba-medicalnouns-quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={12}
            lesson_number={"17"}
            language="yoruba"
            select_course_link="/select-yoruba-course"
            using_african_word={true}
          />
          <PR
            path="/yoruba-marketfood-quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={12}
            lesson_number={"6"}
            language="yoruba"
            select_course_link="/select-yoruba-course"
            using_african_word={true}
          />
          <PR
            path="/yoruba-marketfood-2-quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={12}
            lesson_number={"18"}
            language="yoruba"
            select_course_link="/select-yoruba-course"
            using_african_word={true}
          />
          <PR
            path="/yoruba-course-homeverbs-quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={12}
            lesson_number={"11"}
            language="yoruba"
            select_course_link="/select-yoruba-course"
            using_african_word={true}
          />
          <PR
            path="/yoruba-greetings-quiz"
            limited="false"
            component={MultiplechoiceGeneral}
            num_questions={14}
            lesson_number={"49"}
            lesson_type="blended"
            language="yoruba"
            select_course_link="/select-yoruba-course"
            using_african_word={true}
          />
          <PR
            path="/yoruba_numbers_2_vocab_quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={14}
            lesson_number={"416"}
            language="yoruba"
            select_course_link="/select-yoruba-course"
            using_african_word={true}
          />
          {/* Twi Lesson Quiz Routes.... */}
          <PR
            path="/numbers_2_vocab_quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={5}
            lesson_number={"416"} //38, 53
          />
          <PR
            path="/twi_i_want_quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={5}
            lesson_number={"381"} //38, 53
          />
          <PR
            path="/dialogue-pack-1-manhyia"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={5}
            lesson_number={"38"} //38, 53
          />
          <PR
            path="/dialogue-pack-2-cities"
            limited="false"
            component={MultiplechoiceGeneral}
            num_questions={5}
            lesson_number={"53"}
          />
          <PR
            path="/greetings_vocab_quiz"
            limited="false"
            component={MultiplechoiceGeneral}
            num_questions={14}
            lesson_number={"22"}
          />
          <PR
            path="/greetings_quiz"
            limited="false"
            component={MultiplechoiceGeneral}
            num_questions={14}
            lesson_number={"34"}
            lesson_type="blended"
          />
          <PR
            path="/atthebar_vocab_quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={25}
            lesson_number={"7"}
          />
          <PR
            path="/atthebar_quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={25}
            lesson_number={"37"}
            lesson_type="blended"
          />
          <PR
            path="/marketfood_vocab_quiz"
            limited="false"
            component={MultiplechoiceGeneral}
            num_questions={22}
            lesson_number={"6"}
          />
          <PR
            path="/basics-1-vocab-quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={10}
            lesson_number={"50"}
          />
          <PR
            path="/basics-1-blended-quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={10}
            lesson_number={"55"}
          />
          <PR
            path="/subject_pronouns_1_vocab_quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={10}
            lesson_number={"56"}
          />

          <PR
            path="/subject-pronouns-2-vocab"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={10}
            lesson_number={"313"}
          />
          <PR
            path="/greetings_fixed_vocab"
            limited="false"
            component={MultiplechoiceGeneral}
            num_questions={10}
            lesson_number={"63"}
          />
          <PR
            path="/object_pronouns_1_vocab_quiz"
            limited="false"
            component={MultiplechoiceGeneral}
            num_questions={10}
            lesson_number={"58"}
          />

          <PR
            path="/bodyparts_4_blended_quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={10}
            lesson_number={"61"}
          />

          <PR
            path="/bodyparts_5_heated_emotion_quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={10}
            lesson_number={"64"}
          />
          <PR
            path="/romance_3_cute_idioms_quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={10}
            lesson_number={"60"}
          />
          <PR
            path="/basics-1-blended-2-quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={10}
            lesson_number={"65"}
          />
          <PR
            path="/twi_haggling_3_quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={10}
            lesson_number={"82"}
          />
          <PR
            path="/twi_meals_1_quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={10}
            lesson_number={"181"}
          />
          <PR
            path="/numbers_quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={10}
            lesson_number={"415"}
          />
          <PR
            path="/twi_meals_2_quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={10}
            lesson_number={"315"}
          />
          <PR
            path="/twi_haggling_blended_quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={10}
            lesson_number={"68"}
          />
          <PR
            path="/women_culture_quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={10}
            lesson_number={"69"}
          />
          <PR
            path="/culture_food_ghana_quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={10}
            lesson_number={"77"}
          />
          <PR
            path="/ghana_games_major_cities_quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={10}
            lesson_number={"78"}
          />
          <PR
            path="/twi_tenses_quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={10}
            lesson_number={"70"}
          />
          <PR
            path="/twi_tenses_2_quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={10}
            lesson_number={"71"}
          />
          <PR
            path="/twi_tenses_3_quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={10}
            lesson_number={"73"}
          />
          <PR
            path="/twi_tenses_4_quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={10}
            lesson_number={"74"}
          />
          <PR
            path="/basics-1-blended-3-quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={10}
            lesson_number={"66"}
          />
          <PR
            path="/twi_haggling_quiz"
            limited="false"
            component={MultiplechoiceGeneral}
            num_questions={10}
            lesson_number={"67"}
          />
          <PR
            path="/object_pronouns_1_blended_quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={10}
            lesson_number={"59"}
          />
          <PR
            path="/subject_pronouns_1_blended_quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={10}
            lesson_number={"57"}
          />
          <PR
            path="/singular_pronouns_blended_quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={10}
            lesson_number={"62"}
          />
          <PR
            path="/marketfood_3_vocab_quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={22}
            lesson_number={"43"}
          />
          <PR
            path="/marketfood_quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={22}
            lesson_number={"36"}
            lesson_type="blended"
          />
          <PR
            path="/medicalnouns_quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={16}
            lesson_number={"35"}
            lesson_type="blended"
          />
          <PR
            path="/medicalnouns_vocab_quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={16}
            lesson_number={"17"}
          />
          <PR
            path="/homeverbs_quiz"
            limited="flase"
            component={MultiplechoiceGeneral}
            num_questions={15}
            lesson_number={"11"}
          />
          <PR
            path="/marketfood2_quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={23}
            lesson_number={"18"}
          />
          <PR
            path="/professions_quiz"
            limited="false"
            component={MultiplechoiceGeneral}
            num_questions={23}
            lesson_number={"5"}
          />
          <PR
            path="/professions_2_quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={23}
            lesson_number={"42"}
          />
          <PR
            path="/test_your_twi_quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={11}
            lesson_number={"25"}
          />
          <PR
            path="/digraph_quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={1}
            lesson_number={"26"}
          />
          <PR
            path="/family_quiz"
            limited="false"
            component={MultiplechoiceGeneral}
            num_questions={19}
            lesson_number={"15"}
          />
          <PR
            path="/culture_quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={12}
            lesson_number={"3"}
          />
          <PR
            path="/colors_quiz"
            limited="false"
            component={MultiplechoiceGeneral}
            num_questions={23}
            lesson_number={"14"}
          />
          <PR
            path="/colors_2_quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={23}
            lesson_number={"27"}
          />
          <PR
            path="/at_school_2_quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={23}
            lesson_number={"29"}
          />
          <PR
            path="/at_school_3_quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={23}
            lesson_number={"40"}
          />
          <PR
            path="/at_school_4_quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={23}
            lesson_number={"41"}
          />
          <PR
            path="/at_school_1_quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={23}
            lesson_number={"33"}
          />
          <PR
            path="/animals_quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={16}
            lesson_number={"13"}
          />
          <PR
            path="/chores_quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={18}
            lesson_number={"16"}
          />
          <PR
            path="/homenouns_quiz"
            limited="false"
            component={MultiplechoiceGeneral}
            num_questions={12}
            lesson_number={"10"}
          />
          <PR
            path="/at_home_2_quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={12}
            lesson_number={"4"}
          />
          <PR
            path="/romance_quiz"
            limited="false"
            component={MultiplechoiceGeneral}
            num_questions={18}
            lesson_number={"20"}
          />
          <PR
            path="/romance_2_quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={18}
            lesson_number={"26"}
          />
          <PR
            path="/atschool_quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            num_questions={20}
            lesson_number={"21"}
          />
          <PR
            path="/bodyparts_quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            lesson_number={"8"}
            num_questions={"10"}
          />
          <PR
            path="/bodyparts_2_quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            lesson_number={"19"}
            num_questions={"10"}
          />
          <PR
            path="/bodyparts_3_tension_quiz"
            limited="true"
            component={MultiplechoiceGeneral}
            lesson_number={"44"}
            num_questions={"10"}
          />
          <PR
            path="/grammar_quiz"
            limited="true"
            component={Multiplechoicethree}
          />
          {/* Twi Lesson Notes Routes */}
          <PR
            path="/welcome_greetings"
            limited="false"
            component={Welcomeculture}
          />
          <PR
            path="/welcome_basic_grammar"
            limited="true"
            component={WelcomeBasicGrammar}
          />
          <PR path="/translate_quiz" limited="true" component={TranslateQuiz} />
          <RegisteredMemberRoute
            path="/checkout-redirect"
            component={CheckoutRedirect}
          />
          <RegisteredMemberRoute
            path="/checkout-subscribe"
            component={CheckoutRedirect}
            payment_option={"yearly"}
          />
          <RegisteredMemberRoute
            path="/subscription-options"
            component={PaymentOptions}
          />
          {/* <Route
            path="/subscription-options"
            render={() => <PaymentOptions />}
          /> */}
          <Route path="/signup" render={() => <SignupForm />} />
          <Route
            path="/promo"
            render={() => <SignupForm usingReferralCode={true} />}
          />
          <Route
            path="/invite"
            render={() => <SignupForm usingReferralCode={true} />}
          />
          <RouteWithCtx path="/subscribe" component={SubscribeForm} />
          <Route path="/login" component={LoginForm} />
          <Route path="/crowdsource" component={CrowdsourceForm} />
          <Route path="/retrieve_password" component={RetrievePasswordForm} />
          <Route path="/account_deactivation" component={DeleteUser} />
          <Route path="/account_user_delete" component={DeleteUser} />
          <Route path="/api/password_reset/" component={PasswordResetForm} />
          <UserOrGuestLoginRoute
            path="/create_profile"
            component={ConvertForm}
          />
          <UserOrGuestLoginRoute
            path="/update_profile"
            component={ConvertForm}
            profile_already_exists={true}
          />
        </div>
      </Switch>
    </div>
  );
};

export default Root;
