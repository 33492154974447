import React, { Component } from "react";
import "./Choices.css";

class Choicesfour extends Component {
  constructor(props) {
    super(props);
    this.playChoiceAudio = this.playChoiceAudio.bind(this);

    this.putSelectedChoice = this.putSelectedChoice.bind(this);
    this.getidstyle = this.getidstyle.bind(this);
    this.playAudio = this.playAudio.bind(this);
    this.audio = new Audio();
    // this.audio = new Audio();
    this.state = {
      cans: true,
      selectedChoiceObject: "",
      selectedChoiceId: "",
      tempChoiceId: "", //the choice id used for audio pronunciation - to allow pronunciation both before and after submission
      mute: false,
      choices: !this.props.auto_generated_choices
        ? this.props.questionObject.choices
        : this.props.questionObject.auto_generated_choices.choices,
      correct_answer: !this.props.auto_generated_choices
        ? this.props.questionObject.correct_answer
        : this.props.questionObject.auto_generated_choices
            .correct_answer_option,
    };
  } //constructor close
  componentWillUnmount() {
    this.audio.pause();
  }
  putSelectedChoice(choice) {
    this.setState({ selectedChoiceObject: choice });
  }

  handleChange(e) {
    //const {setCurrent, setScore, questionObject} = this.props;
    e.preventDefault();
    // console.log(e.target);
    const selected = e.target.value;
    const choiceId = selected;
    // const choiceAudio = selected.audio;

    this.props.setSelectedChoice(choiceId);
    this.props.setCurrentQuestionObject(this.props.questionObject);

    if (this.props.answerSubmitted == false) {
      this.setState({ selectedChoiceId: choiceId });
      this.setState({ tempChoiceId: choiceId });
      this.playChoiceAudio();
      // this.playStateAudio();
    } else {
      this.setState({ tempChoiceId: choiceId });
      this.playChoiceAudio(choiceId);
      // this.playStateAudio();
    }
  }
  playAudio(audio1) {
    var promise = audio1.play();
    if (promise !== undefined) {
      promise
        .catch((error) => {
          // Auto-play was prevented
          // Show a UI element to let the user manually start playback
        })
        .then(() => {
          // Auto-play started
        });
    }
  }
  playChoiceAudio() {
    if (this.props.mute === false) {
      // this.audio.pause();
      // console.log(this.state.selectedChoiceObject);
      this.state.choices.map((choice) => {
        if (this.state.tempChoiceId === choice.multiple_choice_option) {
          this.audio.src = choice.twi_audio_url;
        }
      });
      // console.log(this.state.selectedChoiceObject);
      this.playAudio(this.audio);
    }
  }
  getidstyle(choiceId) {
    var correcta = this.state.correct_answer === choiceId;
    var submitted = this.props.answerSubmitted === true;
    var selecteda = this.state.selectedChoiceId === choiceId;
    var anscorrect = submitted && correcta;
    var answrong = submitted && !correcta && selecteda;
    if (answrong) {
      return "wrongans";
    } else if (anscorrect) {
      return "correctans";
    } else {
      if (choiceId == this.state.selectedChoiceId) {
        return "selectedchoice1";
      } else {
        return "choice1";
      }
    }
  }
  getbuttonstyle(choiceId) {
    var correcta = this.state.correct_answer === choiceId;
    var submitted = this.props.answerSubmitted === true;
    var selecteda = this.state.selectedChoiceId === choiceId;
    var anscorrect = submitted && correcta;
    var answrong = submitted && !correcta && selecteda;
    if (answrong) {
      return "wrongansbutton";
    } else if (anscorrect) {
      return "correctansbutton";
    } else {
      if (choiceId == this.state.selectedChoiceId) {
        return "selectedchoicebuttonstyle";
      } else {
        return "choicebuttonstyle";
      }
    }
  }
  getChoiceImage = (choice) => {
    var choiceword = choice.word;
    var image_url = choiceword.image_url;
    if (this.props.using_african_word) {
      choiceword = choice.african_word;

      // use general image unless there is a
      // language specific image
      if (choiceword.has_native_image) {
        image_url = choiceword.native_image_url;
      }
    }
    return image_url;
  };

  render() {
    return (
      <div>
        <div>
          <div onClick={this.props.mute ? {} : this.playChoiceAudio()}> </div>
        </div>
        <div className="choiceitemcollection">
          <label className="choiceitemfour">
            {this.state.choices.map((choice) => {
              if ("a" === choice.multiple_choice_option) {
                // console.log("start");
                // console.log(this.state.correct_answer);
                // console.log(this.props.answerSubmitted);
                // console.log(this.state.selectedChoiceId);
                // console.log("end");

                return (
                  <div id={this.getidstyle("a")}>
                    <img id="choiceimage" src={this.getChoiceImage(choice)} />
                    <input
                      onChange={this.handleChange.bind(this)}
                      type="radio"
                      name={this.props.questionObject.question_number}
                      value={choice.multiple_choice_option}
                      className="radiostyle"
                    />
                  </div>
                );
              }
            })}
          </label>
          <label className="choiceitemfour">
            {this.state.choices.map((choice) => {
              if ("b" === choice.multiple_choice_option) {
                this.state.selectedChoice = choice;
                // console.log("----------------------: ", choice);
                return (
                  <div id={this.getidstyle("b")}>
                    <img id="choiceimage" src={this.getChoiceImage(choice)} />
                    <input
                      onChange={this.handleChange.bind(this)}
                      type="radio"
                      name={this.props.questionObject.question_number}
                      value={choice.multiple_choice_option}
                      class="radiostyle"
                    />{" "}
                    {choice.english_text}
                  </div>
                );
              }
            })}
          </label>
          <label className="choiceitemfour">
            {this.state.choices.map((choice) => {
              if ("c" === choice.multiple_choice_option) {
                this.state.selectedChoice = choice;
                return (
                  <div id={this.getidstyle("c")}>
                    <img id="choiceimage" src={this.getChoiceImage(choice)} />
                    <input
                      className="radiostyle"
                      onChange={this.handleChange.bind(this)}
                      type="radio"
                      name={this.props.questionObject.question_number}
                      value={choice.multiple_choice_option}
                    />{" "}
                    {choice.english_text}
                  </div>
                );
              }
            })}
          </label>
        </div>
      </div>
    );
  }
}

export default Choicesfour;
