import React, { useState, useEffect } from "react";
import correct_swish from "./app_content/navigation_pages_content/correct-swish.wav";

import "./WordSearch.css";
const WordSearch = (props) => {
  let {
    setClick,
    answerSubmitted,
    answerCorrect,
    wordsConcat,
    gridLettersConcat,
    extraInfo,
  } = props;
  let main_set = new Set();
  const tokenizeWords = (input) => {
    const parts = input
      .split(/("[^"]*"|\s+)/)
      .filter((part) => part.trim() !== "")
      .map((part) => part.replace(/"/g, "").replace(/\s/g, ""));
    console.log("======= parts: ", parts);
    return parts;
  };
  // Set up variables for word bank and grid letters.
  let rand = 0;
  let wordsQuizDict = {};

  // Turn extraInfo data into json
  const jsonString = extraInfo;
  let wds = wordsConcat;
  let dsc = gridLettersConcat;

  // if json parse & info retrieval errors,
  // it defaults back to input words and grid letters
  try {
    const jsonObject = JSON.parse(jsonString);

    // extend range account words and gridletters not in extraInfo
    const jlen = Object.keys(jsonObject).length;
    const randRange = jlen + 1;

    // Alternative to generate more even distribution
    // Generate an array of numbers from 0 to randRange - 1
    const numbers = Array.from({ length: randRange }, (_, i) => i);

    // Shuffle the array using the Fisher-Yates algorithm
    for (let i = numbers.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [numbers[i], numbers[j]] = [numbers[j], numbers[i]];
    }

    const rand = numbers[0] - 1;

    // // rand values for extraInfo start at 0, -1 reserved for input words and grid letters
    // rand = Math.floor(Math.random() * randRange) - 1;
    if (rand >= 0) {
      wordsQuizDict = jsonObject[rand];
      wds = wordsQuizDict.words;
      dsc = wordsQuizDict.description;
    }
  } catch (e) {
    alert(e);
    jsonifyFailed = true;
  }

  // Randomly select dict of scrambled words and word back

  // Retrieve scrambled words
  var tbl_letters = dsc;

  // Retrieve word bank
  const wc2 = wds;

  const [tbLetters, setTbLetters] = useState(tbl_letters);
  const [wc1, setWC1] = useState(wc2);

  // tokenize words to create word dict.
  let tokens = tokenizeWords(wc1);
  tokens.map((token) => main_set.add(token));

  const audio = new Audio(correct_swish);
  const [dict, setDict] = useState(new Set());
  // const { funcDeleteDictWord, funcDictHas } = props;
  // initializing before use in useEffect
  const [wordsFound, setWordsFound] = useState(0);
  // mount effect
  useEffect(
    () => {
      // fetch & process dict words
      audio.src = correct_swish;
      setDict(main_set);
      setClick(checkAnswer);
      return () => {
        // cleanup
      };
    },
    /*passing in word found so setClick function is updated as the wordsFound increase*/ [
      wordsFound,
    ]
  );

  const [clickedCellCol, setClickedCellCol] = useState(-1);
  const [clickedCellRow, setClickedCellRow] = useState(-1);
  const [foundDict1, setFoundDict1] = useState(new Set());
  const [foundDict2, setFoundDict2] = useState(new Set());
  const [foundDict3, setFoundDict3] = useState(new Set());
  const [foundDict4, setFoundDict4] = useState(new Set());
  const [foundDict5, setFoundDict5] = useState(new Set());
  const correctAudio = () => {
    audio.play();
  };

  const checkAnswer = () => {
    // console.log("cca: wordsFound: ", wordsFound);
    // return true;
    return wordsFound > 4; //mainset.length()?
  };
  const table_style_id = () => {
    if (answerSubmitted) {
      if (!answerCorrect) {
        return "ws_table_wrong";
      }
      return "ws_table_correct";
    }
    return "ws_table";
  };
  const table_body_style_class = () => {
    return "";
  };

  const tbl = (rows, cols) => {
    var tarr = [];

    for (var i = 0; i < rows; i++) {
      tarr.push(row(i, cols, tbLetters.substring(i * cols, (i + 1) * cols)));
    }
    return (
      <table class={table_body_style_class()} id={table_style_id()}>
        <tbody>{tarr}</tbody>
      </table>
    );
  };
  const one_based_idx = (r, c, numRows) => {
    return r * numRows + c;
  };
  const cellstyle = (in_style, crow, ccol) => {
    // Cell style returned in priority of ordered conditional statments.
    if (
      ccol == clickedCellCol.toString() &&
      crow == clickedCellRow.toString()
    ) {
      return "clickedCell1";
    }
    let cr = parseInt(crow);
    let cc = parseInt(ccol);
    let idx = one_based_idx(cr, cc, 10);
    if (foundDict5.has(idx)) {
      return "clickedCellFound5";
    }
    if (foundDict4.has(idx)) {
      return "clickedCellFound4";
    }
    if (foundDict3.has(idx)) {
      return "clickedCellFound3";
    }
    if (foundDict2.has(idx)) {
      return "clickedCellFound2";
    }
    if (foundDict1.has(idx)) {
      return "clickedCellFound1";
    }
    return in_style;
  };
  const validWord = (r1, c1, r2, c2) => {
    // out of bounds OR only one clicked cell to compare
    if (r1 == -1 || r2 == -1 || c1 == -1 || c2 == -1) {
      return -1;
    }
    let rdiff = Math.abs(r1 - r2);
    let cdiff = Math.abs(c2 - c1);
    // One or both are zero
    // horizontal word across same row
    if (rdiff == 0 && cdiff != 0) {
      return 1;
    }
    // vertical word accross same column
    if (cdiff == 0 && rdiff != 0) {
      return 1;
    }
    // returns positive for same button reclick
    if (cdiff == 0 && rdiff == 0) {
      return 0;
    }
    // Both are non-zero
    // same magnitude: valid diagonal word in any direction
    if (rdiff == cdiff) {
      return 1;
    }
    // given previous statements will all return
    // if it reaches here all conditionals above failed.
    // different magnitudes: invalid diagonal
    if (rdiff != cdiff) {
      return -1;
    }
  };

  const getCellChar = (idx) => {
    return tbLetters.charAt(idx);
  };

  const searchWord = (r1, c1, r2, c2) => {
    // find word
    const dirInc = getDirectionIncrement(r1, c1, r2, c2);
    const len = wordLength(r1, c1, r2, c2);

    // loop to build selected word
    let acc = "";
    let tempDict = new Set();
    // start from zero to include
    // index from current cell
    for (var i = 0; i <= len; i++) {
      let rinc = i * dirInc[0];
      let cinc = i * dirInc[1];
      let rCellIdx = r1 + rinc;
      let cCellIdx = c1 + cinc;

      // store list of indices to be highligted
      let one_idx = one_based_idx(rCellIdx, cCellIdx, 10);
      acc = acc + getCellChar(one_idx);
      tempDict.add(one_idx);
    }
    let word = acc;

    // process
    let has = dict.has(word);
    if (has) {
      correctAudio();
      if (wordsFound == 0) {
        var u = new Set([...foundDict1, ...tempDict]);
        setFoundDict1(u);
        dict.delete(word);
      } else if (wordsFound == 1) {
        var u = new Set([...foundDict2, ...tempDict]);
        setFoundDict2(u);
        dict.delete(word);
      } else if (wordsFound == 2) {
        var u = new Set([...foundDict3, ...tempDict]);
        setFoundDict3(u);
        dict.delete(word);
      } else if (wordsFound == 3) {
        var u = new Set([...foundDict4, ...tempDict]);
        setFoundDict4(u);
        dict.delete(word);
      } else if (wordsFound == 4) {
        var u = new Set([...foundDict5, ...tempDict]);
        setFoundDict5(u);
        dict.delete(word);
      }

      setWordsFound(wordsFound + 1);
      return true;
    }
    return false;
  };
  const wordLength = (r1, c1, r2, c2) => {
    let rdiff = Math.abs(r2 - r1);
    let cdiff = Math.abs(c2 - c1);
    return Math.max(rdiff, cdiff);
  };
  const getDirectionIncrement = (r1, c1, r2, c2) => {
    let rdiff_signed = r2 - r1;
    let cdiff_signed = c2 - c1;
    let rowDir = 0;
    let colDir = 0;

    if (rdiff_signed > 0) {
      rowDir = 1;
    } else if (rdiff_signed < 0) {
      rowDir = -1;
    }
    if (cdiff_signed > 0) {
      colDir = 1;
    } else if (cdiff_signed < 0) {
      colDir = -1;
    }

    return [rowDir, colDir];
  };
  const cell = (char, colIdx, rowIdx) => {
    let str_col_id = colIdx.toString();
    var str_row_id = rowIdx.toString();

    return (
      <td
        id={str_col_id}
        class={cellstyle("cws_cell", str_row_id, str_col_id)}
        value={char}
        onClick={() => {
          let valid = validWord(clickedCellRow, clickedCellCol, rowIdx, colIdx);
          if (valid < 0) {
            // invalid: reset 1st cell
            setClickedCellCol(colIdx);
            setClickedCellRow(rowIdx);
          } else if (valid > 0) {
            // valid: additionally set 2nd cell

            let found = searchWord(
              clickedCellRow,
              clickedCellCol,
              rowIdx,
              colIdx
            );
            if (!found) {
              // reset to most recent click
              setClickedCellCol(colIdx);
              setClickedCellRow(rowIdx);
            } else {
              //none clicked when found
              setClickedCellCol(-1);
              setClickedCellRow(-1);
            }
          } else {
            // valid equals 0: unset all (same cell click)
            setClickedCellCol(-1);
            setClickedCellRow(-1);
          }
        }}
        // class="selectable positive collapsing"
      >
        <a id="agya" class="aws">
          {char}
        </a>
      </td>
    );
  };
  const row = (rowNum, cols, letters) => {
    var arr = [];
    for (var i = 0; i < cols; i++) {
      arr.push(cell(letters.charAt(i), i, rowNum));
    }
    return <tr>{arr}</tr>;
  };

  const display_word_list_id = () => {
    if (answerSubmitted) {
      if (!answerCorrect) {
        return "wordlist_wrong";
      }
      return "wordlist_correct";
    }
    return "";
    // return "wordlist";
  };
  const displayWordSection = (words) => {
    return (
      <ul class="wordlist">
        {words.map((word) => {
          return <li id={display_word_list_id()}>{word} </li>;
        })}
      </ul>
    );
  };
  const inst_correct = () => {
    if (answerSubmitted) {
      if (!answerCorrect) {
        return "inst_wrong";
      }
      return "inst_correct";
    }
    return "instruction";
  };
  const word_search_instruction = () => {
    return (
      <p id={inst_correct()}>Select word by clicking first and last letter</p>
    );
  };
  let set_arr = Array.from(main_set);

  return (
    <>
      {word_search_instruction()}
      {tbl(10, 10)}
      {displayWordSection(set_arr)}
    </>
  );
};

export default WordSearch;
