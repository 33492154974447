import React, { Component } from "react";
import bodyparts from "./components/app_content/navigation_pages_content/images/bodyparts.jpg";
import profession from "./components/app_content/navigation_pages_content/images/pharmacist.jpg";
import homenouns_image from "./components/app_content/navigation_pages_content/images/homenouns.jpg";
import homeverbs_image from "./components/app_content/navigation_pages_content/images/homeverbs.jpg";
import animals_image from "./components/app_content/navigation_pages_content/images/animals.jpg";
import colors_image from "./components/app_content/navigation_pages_content/images/colors.jpg";
import family_image from "./components/app_content/navigation_pages_content/images/family.jpg";
import chores_image from "./components/app_content/navigation_pages_content/images/chores.jpg";
import medicalnouns_image from "./components/app_content/navigation_pages_content/images/medicine_welcome1.png";
import romance_image from "./components/app_content/navigation_pages_content/images/romance.jpg";
import market from "./components/app_content/navigation_pages_content/images/market.png";
import greetings_image from "./components/app_content/navigation_pages_content/images/greetings.jpg";
import farmer_image from "./components/app_content/navigation_pages_content/images/okuani.png";
import teaching_image from "./components/app_content/navigation_pages_content/images/mathteacher.png";
import at_school_2_image from "./components/app_content/navigation_pages_content/images/teacher.png";
import romance_2_image from "./components/app_content/navigation_pages_content/images/romance.jpg";
import bar from "./components/app_content/navigation_pages_content/images/bar.png";
import rubiks_image from "./components/app_content/navigation_pages_content/images/rubiks.png";

import ReactPixel from "react-facebook-pixel";
if (process.env.REACT_APP_DEV_PIPELINE_STAGE == "production") {
  const options = {
    autoConfig: true, // set pixel's autoConfig
    debug: false, // enable logs
  };
  ReactPixel.init(process.env.REACT_APP_FB_PIXEL_CODE, options);
  ReactPixel.pageView();
}

const GroupItem = ({ info, ctx }) => {
  const quiz_info = ctx.state.ga_quiz_association_info[info];
  const url_route = quiz_info[0];
  const image = quiz_info[1];
  const name = quiz_info[2];
  const display = ctx.quiz_info(info);
  return ctx.props.returnQuiz(url_route, image, name, display);
};
const DiamondDisplay = (group_items, ctx) => {
  var top = "";
  var left = "";
  var right = "";
  var down = "";
  if (group_items.length > 0) {
    top = <GroupItem info={group_items[0]} ctx={ctx} />;
  }
  if (group_items.length > 1) {
    left = <GroupItem info={group_items[1]} ctx={ctx} />;
  }
  if (group_items.length > 2) {
    right = <GroupItem info={group_items[2]} ctx={ctx} />;
  }
  if (group_items.length > 3) {
    down = <GroupItem info={group_items[3]} ctx={ctx} />;
  }
  return (
    <label id="courserow">
      <label>{top}</label>
      <label>
        {left}
        {right}
      </label>
      <label>{down}</label>
    </label>
  );
};
const ListOfGroupItems = ({ group_items, ctx }) => {
  return <ul>{DiamondDisplay(group_items, ctx)}</ul>;
};
const ListOfGroups = ({ groups, ctx }) => {
  return (
    <ul>
      {groups.map((single_group) => (
        <ListOfGroupItems group_items={single_group} ctx={ctx} />
      ))}
    </ul>
  );
};
class GaSkillTree extends Component {
  constructor(props) {
    super(props);
    this.returnGaSkillTree = this.returnSkillTree.bind(this);
    this.getCompletionStats = this.getCompletionStats.bind(this);
    this.state = {
      ga_lesson_display_groups: [
        ["greetings_vocab"],
        ["ga_greetings_phrases", "home_nouns_1"],
        ["family"],
        ["medical_nouns_1_vocab"],
        ["market_food_2", "professions"],
        ["home_verbs_1"],
        ["at_the_bar_vocab","doing_chores"],
        ["accra_boxers"],
        // coming
        ["romance"],
        ["animals"],
        ["body_parts", "colors"],
        ["at_school_1"],
        ["professions_2", "romance_2", "at_school_3"],
        ["doing_chores_2"],
        ["colors_2", "animals_2"],
      ],
      ga_quiz_association_info: {
        accra_boxers: [
          "accra-boxers-quiz", rubiks_image, "Puzzle: Accra Boxers"
        ],
        ga_greetings_phrases: [
          "/ga-greetings_phrases_quiz",
          greetings_image,
          "Greetings 2",
        ],

        // key must match quiz name in
        // ga_lesson_display_groups.
        medical_nouns_1_vocab: [
          "ga-medicalnouns-quiz",
          medicalnouns_image,
          "Medical Nouns",
        ],

        professions: ["/ga-course-professions_quiz", profession, "Professions"],

        market_food_1_vocab: ["ga-marketfood-quiz", market, "At The Market"],

        market_food_2: ["ga-marketfood-2-quiz", market, "Market Food"],

        home_nouns_1: [
          "ga-course-homenouns_quiz",
          homenouns_image,
          "Home Nouns",
        ],

        home_verbs_1: [
          "ga-homeverbs-quiz",
          homeverbs_image,
          "Home Verbs",
        ],

        greetings_vocab: [
          "ga-course-greetings-quiz",
          greetings_image,
          "Greetings",
        ],

        romance: ["romance_quiz", romance_image, "Romance"],

        family: ["ga-family-quiz", family_image, "Family"],

        animals: ["animals_quiz", animals_image, "Animals"],

        body_parts: ["ga-bodyparts-quiz", bodyparts, "Body Parts"],

        doing_chores: ["ga-chores-quiz", chores_image, "Doing Chores"],

        colors: ["ga-colors-quiz", colors_image, "Colors"],

        romance_2: ["ga-romance-2-quiz", romance_2_image, "Romance 2"],
        at_school_1: [
          "ga-at-school-1-quiz",
          at_school_2_image,
          "Education 1",
        ],
        colors_2: ["ga-colors-2-quiz", colors_image, "Colors 2"],
        at_school_3: ["ga-at-school-3-quiz", teaching_image, "Education 2"],
        animals_2: ["ga-animals-2-quiz", animals_image, "Animals 2"],
        doing_chores_2: ["ga-chores-2quiz", chores_image, "Doing Chores 2"],
        professions_2: [
          "/ga-professions-2-quiz",
          farmer_image,
          "Professions 2",
        ],
        at_the_bar_vocab: ["ga-atthebar-vocab-quiz", bar, "At The Bar"],
      },
      ga_non_limited_quizzes: new Set([
        "greetings_vocab",
        "ga_greetings_phrases",
        "market_food_2",
        // "home_nouns_1",
      ]),
      // language_name : 'ga' // this.props.language_name
      // skill tree state info
      ga_playable_quizzes_set: new Set([
        "home_nouns_1",
        "ga_greetings_phrases",
        "medical_nouns_1_vocab",
        "market_food_2",
        "professions",
        "greetings_vocab",
        "family",
        "home_verbs_1",
        "at_the_bar_vocab",
        "doing_chores",
        "accra_boxers"
        // "market_food_1_vocab",
        // "romance",
        // "body_parts",
        // "colors",
        // "romance",
        // "animals",

      ]),
      lesson_stats: {},
    };
  }

  componentDidMount() {
    this.getCompletionStats(
      this.state.lesson_stats,
      this.state.ga_playable_quizzes_set
    );
  }

  getCompletionStats(lesson_stats, quiz_names) {
    this.props
      .getCompletionStats(quiz_names)
      .then((newItems) => this.setState({ lesson_stats: newItems }));
  }

  quiz_info(quiz_name) {
    const in_tree = this.state.ga_playable_quizzes_set.has(quiz_name);
    const stats = this.state.lesson_stats[quiz_name];
    const limited = !this.state.ga_non_limited_quizzes.has(quiz_name);
    var quiz_info_arr = [quiz_name, in_tree, stats, limited];
    return quiz_info_arr;
  }

  returnSkillTree() {
    return (
      <div className="courselineditems">
        <ListOfGroups groups={this.state.ga_lesson_display_groups} ctx={this} />
      </div>
    );
  }

  render() {
    return <div>{this.returnSkillTree()}</div>;
  }
}
export default GaSkillTree;
