import React from 'react';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share';

const SocialShare = () => {
  const appUrl = 'https://dialogue-africa.app.link'; // Replace with your app's URL
  const message = "Check out this app for learning language and culture! Dialogue Africa: ";

  return (
    <div className="social-share">
      <p>📣 Spread the word.</p>
      <div className="social-share-buttons">
      <FacebookShareButton url={appUrl} quote={message + appUrl}>
        <FacebookIcon size={32} round />
      </FacebookShareButton>

      <TwitterShareButton url={appUrl} title={message}>
        <TwitterIcon size={32} round />
      </TwitterShareButton>

      <WhatsappShareButton url={appUrl} title={message}>
        <WhatsappIcon size={32} round />
      </WhatsappShareButton>
      </div>
    </div>
  );
};

export default SocialShare;
