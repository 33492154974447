import React from "react";
import "./styles/Signupform.css";
import "./css/retrieve_password.css";
import { UserConsumer } from "../User/User.js";
import { Link } from "react-router-dom";
import background from "./images/bg-01.jpg"; //'./images/africanpattern1.jpg'
import { Navbar, Nav, NavItem, MenuItem, NavDropdown } from "react-bootstrap";
import $ from "jquery";
import loader1 from "./images/831.svg";

export default class RetrievePasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      waiting: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
  }
  handleEmail(event) {
    this.setState({ email: event.target.value });
  }
  handleSubmit(e, glb_ctx) {
    e.preventDefault();
    this.setState({ waiting: true }, () => {
      glb_ctx.retrievepassword(this.state).then(async (res) => {
        if (res.ok) {
          // Redirect to main page
          alert("Password reset link has been sent.");
          this.props.history.push("/login");
        } else {
          let json = await res.json();
          let json_keys = Object.keys(json);
          if (json_keys.length > 0) {
            let first_key_str = json_keys[0];
            let capitalized =
              first_key_str.charAt(0).toUpperCase() + first_key_str.slice(1);
            alert(`${capitalized} error. ${json[first_key_str]}`);
          } else {
            alert("Password retrieval failed. Try again");
          }
          this.setState({ waiting: false });
        }
      });
    });
  }

  render() {
    return (
      <UserConsumer>
        {(glb_ctx) => (
          <div>
            <div>
              <div>
                <head>
                  <title>
                    Retrieve Password. Learn African languages and connect to
                    culture. Learn Twi, Yoruba, Swahili, Igbo, Ga, Fante and
                    more.
                  </title>
                  <meta
                    name="description"
                    content="Dialogue Africa provides an online platform and application to learn African languages and connect to culture. Learn Twi, Fante, Ga, Swahili, Yoruba and more"
                  />
                  <meta charset="UTF-8" />
                  <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1"
                  />
                  <link
                    rel="icon"
                    type="image/png"
                    href="./images/icons/favicon.ico"
                  />
                  <link
                    rel="stylesheet"
                    type="text/css"
                    href="vendor/bootstrap/css/bootstrap.min.css"
                  />
                  <link
                    rel="stylesheet"
                    type="text/css"
                    href="fonts/font-awesome-4.7.0/css/font-awesome.min.css"
                  />
                  <link
                    rel="stylesheet"
                    type="text/css"
                    href="fonts/iconic/css/material-design-iconic-font.min.css"
                  />
                  <link
                    rel="stylesheet"
                    type="text/css"
                    href="vendor/animate/animate.css"
                  />
                  <link
                    rel="stylesheet"
                    type="text/css"
                    href="vendor/css-hamburgers/hamburgers.min.css"
                  />
                  <link
                    rel="stylesheet"
                    type="text/css"
                    href="vendor/animsition/css/animsition.min.css"
                  />
                  <link
                    rel="stylesheet"
                    type="text/css"
                    href="vendor/select2/select2.min.css"
                  />
                  <link
                    rel="stylesheet"
                    type="text/css"
                    href="vendor/daterangepicker/daterangepicker.css"
                  />
                  <link rel="stylesheet" type="text/css" href="css/util.css" />
                  <link rel="stylesheet" type="text/css" href="css/main.css" />

                  <link
                    href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
                    rel="stylesheet"
                  />
                  <link
                    rel="stylesheet"
                    href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css"
                    integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm"
                    crossorigin="anonymous"
                  />
                  <link
                    rel="stylesheet"
                    href="./styleshannon1.css"
                    type="text/css"
                  />
                  <link
                    href="https://fonts.googleapis.com/css?family=Open+Sans|Raleway"
                    rel="stylesheet"
                  />
                  <link
                    href="https://fonts.googleapis.com/css?family=Montserrat"
                    rel="stylesheet"
                  />
                  <link
                    rel="stylesheet"
                    type="text/css"
                    href="//fonts.googleapis.com/css?family=Varela+Round"
                  />
                </head>
                <nav class="navbar fixed-top navbar-expand-lg navbar-light bg-light">
                  <Link class="navbar-brand" to="/">
                    <img
                      class="favicon dialoguelogobar"
                      alt="Brand"
                      src="./Images/da-logo-lineart.png"
                    />
                  </Link>
                  <button
                    class="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarTogglerDemo02"
                    aria-controls="navbarTogglerDemo02"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span class="navbar-toggler-icon"></span>
                  </button>

                  <div
                    class="collapse navbar-collapse"
                    id="navbarTogglerDemo02"
                  >
                    <ul class="navbar-nav ml-auto">
                      <li class="nav-item active">
                        <Link class="nav-link" to="/">
                          <span class="fa fa-home"></span> Home
                          <span class="sr-only">(current)</span>
                        </Link>
                      </li>
                      <li class="nav-item active">
                        <a class="nav-link" href="/blog-main.html">
                          <span class="fa fa-book"></span> Blog
                        </a>
                      </li>
                      <li class="nav-item active">
                        <Link class="nav-link" to="/login">
                          <span class="fa fa-sign-in" aria-hidden="true"></span>{" "}
                          Login
                        </Link>
                      </li>
                      <li class="nav-item active">
                        <Link class="nav-link" to="/select_course">
                          <span class="fa fa-user"></span> Select Course
                        </Link>
                      </li>
                    </ul>
                  </div>
                </nav>
                <div class="limiter">
                  <div
                    class="container-login100"
                    style={{ backgroundImage: "url(" + background + ")" }}
                  >
                    <div class="wrap-login100 p-l-55 p-r-55 p-t-65 p-b-54">
                      <form
                        class="login100-form validate-form"
                        onSubmit={(e) => this.handleSubmit(e, glb_ctx)}
                      >
                        <span class="login100-form-title p-b-49">
                          Retrieve Password
                        </span>
                        <div>
                          {!this.state.waiting ? (
                            <div
                              class="wrap-input100 validate-input m-b-23"
                              data-validate="Email is required"
                            >
                              <i class="far fa-envelope grey"> </i>{" "}
                              <span class="label-input100">E-mail</span>
                              <input
                                class="input100"
                                type="text"
                                name="email"
                                placeholder="Enter e-mail"
                                value={this.state.email}
                                onChange={this.handleEmail}
                              />
                            </div>
                          ) : (
                            <div>
                              <img id="spinner" src={loader1} />
                            </div>
                          )}
                        </div>
                        <div class="container-login100-form-btn">
                          <div class="wrap-login100-form-btn">
                            <div class="login100-form-bgbtn"></div>
                            <button class="login100-form-btn">
                              <div>
                                {this.state.waiting
                                  ? "Processing ... ... ... "
                                  : "Submit"}
                              </div>
                            </button>
                          </div>
                        </div>
                      </form>
                      <br />
                      <div class="flex-col-c">
                        <span class="txt1 p-b-17">
                          Want a new account? <Link to="/signup"> Signup </Link>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="dropDownSelect1"></div>
                <script src="vendor/jquery/jquery-3.2.1.min.js"></script>
                <script src="vendor/animsition/js/animsition.min.js"></script>
                <script src="vendor/bootstrap/js/popper.js"></script>
                <script src="vendor/bootstrap/js/bootstrap.min.js"></script>
                <script src="vendor/select2/select2.min.js"></script>
                <script src="vendor/daterangepicker/moment.min.js"></script>
                <script src="vendor/daterangepicker/daterangepicker.js"></script>
                <script src="vendor/countdowntime/countdowntime.js"></script>
                <script src="js/main.js"></script>
              </div>
            </div>
          </div>
        )}
      </UserConsumer>
    );
  }
}
