import React, { Component } from "react";
import "./QuestionCard.css";
import piaw from "../Images/piaw.png";
import cities from "../Images/citieswordsearch.png";
import nkrumah from "../Images/nkrumahquote1-borninme.png";
import nkrumah2 from "../Images/nkrumah-borninme.png";
import ColorCodedTranslation from "./ColorCodedTranslation";
import CheckmarksThree from "./CheckmarksThree";
import WordBank from "./WordBank";
import language_icon from "../Images/languages.png";
import title_image from "../Images/language-title-2-practice.png";
import title_image2 from "../Images/language-title-1.png";
import map_title from "../Images/map-title2.png";
import mask_title from "../Images/mask-title.png";
import african_mask from "../Images/african-mask.png";
import puzzles_image from "../Images/games-puzzles.png";
import talk_image from "../Images/talk2.png";
import translate_image from "../Images/translate.png";
import map_image from "../Images/map.png";
import people_image from "../Images/people.png";
import quotes_image from "../Images/quotes.png";
import { Icon, Label } from "semantic-ui-react";
import WordSearch from "./WordSearch.js";
/**
 * Switches on hardcoded question types and uses
 * various helper functions to either peform
 * javascript or jsx building for display.
 * ---
 * Sets several state values based on questionObject prop and uses the states to generate card based on question type
 * **/
class QuestionCard extends Component {
  constructor(props) {
    super(props);
    this.myaudio = new Audio();
    this.state = {
      childCheckAnswer: this.props.childCheckAnswer,
      title: "",
      support_header: "",
      support_header_subtext: "",
      encoded_description: "",
      extra_info: "",
      title_audio_url: "",
      description_audio_1_url: "",
      main_image_url: "",
      category_title: "",
      category_sub_info_1: "",
      category_sub_info_2: "",
      theme_color_style: "",
      phrase: "",
      language_question_type: "",
    };
  }
  componentDidMount() {
    if (this.props.questionObject.card != null) {
      let card = this.props.questionObject.card;
      this.setState({
        title: card.title,
        support_header: card.support_header_title,
        support_header_subtext: card.support_header_subtext,
        encoded_description: card.description,
        extra_info: card.extra_info,
        title_audio_url: card.title_audio_url,
        description_audio_1_url: card.audio_1_url,
        main_image_url: card.main_image_url,
        category_title: card.category_title,
        category_title_name: card.category_title_name,
        category_sub_info_1: card.category_sub_info_1,
        category_sub_info_2: card.category_sub_info_2,
        theme_color_style: card.theme_color_style
          ? card.theme_color_style
          : "#42b83d",
        phrase: card.phrase,
        language_question_type: card.language_question_type,
      });
      this.props.setCurrentThemeColor(card.theme_color_style);

      this.playInitialCardSound(this.props.questionObject.card.title_audio_url);
    }
  }
  componentWillUnmount() {
    this.stopAudio(this.myaudio);
  }

  stopAudio = (my_audio) => {
    var promise = my_audio.pause();
    if (promise !== undefined) {
      promise
        .catch((error) => {
          // Auto-play was prevented
          // Show a UI element to let the user manually start playback
          console.log("ae: audio except 2");
        })
        .then(() => {
          // Auto-play started
        });
    }
    // my_audio.currentTime = 0;
  };
  // Function that takes in a lesson number
  // and returns the a serialized list of ordered cards
  // card_info = () {
  //   path = ""
  // }
  playInitialCardSound = (inSound) => {
    setTimeout(() => {
      this.playSound(inSound);
    }, 800);
  };
  playSound = (inSound) => {
    this.myaudio.src = inSound;
    var promise = this.myaudio.play();
    if (promise !== undefined) {
      promise
        .catch((error) => {
          // Auto-play was prevented
          // Show a UI element to let the user manually start playback
          console.log("ae: audio except 1");
        })
        .then(() => {
          // Auto-play started
        });
    }
  };
  languageCard = (lang_qt) => {
    let header_image = translate_image;
    if (lang_qt == "color-coded-phrases") {
      return (
        <>
          <div class="piawbox">
            <div class="culture_card_description">
              <div class="dpack-card piaw">
                {this.category_header(header_image)}
              </div>
            </div>
            <div>
              <ColorCodedTranslation
                questionObject={this.props.questionObject}
              />
            </div>
          </div>
        </>
      );
    } else if (lang_qt == "word-bank-english-to-twi") {
      return (
        <>
          <div class="piawbox">
            <div
              class="culture_card_description"
              style={{ marginBottom: "20px" }}
            >
              <div class="dpack-card piaw">
                {this.category_header(header_image)}
              </div>
            </div>
            <div>
              <WordBank
                questionObject={this.props.questionObject}
                answerSubmitted={this.props.answerSubmitted}
                setClick={this.props.setClick}
                answerCorrect={this.props.answerCorrect}
                questionPhrase={"understood"}
                hintWords={"underlying"}
                answerPhrase={"underlying"}
                answerExtras={"twi_extras"}
                displayPhrase={true}
              />
              <CheckmarksThree
                answerSubmitted={this.props.answerSubmitted}
                answerCorrect={this.props.answerCorrect}
              />
            </div>
          </div>
        </>
      );
    } else {
      return <div></div>;
    }
  };
  returnColoredText = (my_text, class_style) => {
    return <span style={{ color: class_style }}>{my_text}</span>;
  };
  tokenizeText = (text, token) => {
    let tokens = text.split(token);
    // colored text false into every other token
    // start token isn't colored.
    return tokens;
  };
  colorAndAppend = (value, index, array, class_style) => {
    acc = [];
    if (index % 2 == 0) {
      acc.push(array[index]);
    } else {
      acc.push(this.returnColoredText(tokens[i]), class_style);
    }
    return acc;
  };
  likeableHeart = () => {
    return (
      <div class="dpack-icon" onClick={this.props.toggleQuestionLike}>
        {this.props.questionLike ? (
          <Icon name="heart" size="large" color="red" />
        ) : (
          <Icon name="heart" size="large" />
        )}
      </div>
    );
  };
  parseEncodedString = (textToDecode, class_style) => {
    var key_bracket_set = new Set();
    key_bracket_set.add("#");
    key_bracket_set.add("<");

    var audio_dict = {
      $1$: [],
      $2$: [],
      $3$: [],
    };
    var bracket_dict = {
      "<1>": [],
      "<2>": [],
      "<3>": [],
      "#": [],
    };
    var acc = [];
    var so_far = "";
    // Iterate through characters
    var i = 0;
    while (i < textToDecode.length) {
      let c = textToDecode[i];

      // starting at each character check
      // if any of the substrings match audio dict keys
      if (c == "$") {
        // capture and add so_far and audio dict value to accumm array
        acc.push(so_far);
        so_far = "";
        let opener = textToDecode.substring(i, i + 3);
        acc.push(this.processBracket("", opener, class_style));

        // skip so_far_head and curr pointer to character after end of substring
        i = i + 3;
      }
      // if any of the substrings match bracket dict keys

      // skip so_far_head and curr pointer to character after end bracket substring
      else if (c == "<") {
        acc.push(so_far);
        so_far = "";
        let j = i + 3;
        let opener = textToDecode.substring(i, j);
        let el = audio_dict[opener];
        let d = textToDecode[j];
        // capture enclosed content
        let enclosed = "";
        while (d != "<" && j < textToDecode.length) {
          enclosed += d;
          j++;
          d = textToDecode[j];
        }
        acc.push(this.processBracket(enclosed, opener, class_style));
        i = j + 3;
      } else if (c == "#") {
        acc.push(so_far);
        so_far = "";
        let j = i + 1;
        let d = textToDecode[j];
        // capture enclosed content
        let enclosed = "";
        while (d != "#" && j < textToDecode.length) {
          enclosed += d;
          j++;
          d = textToDecode[j];
        }
        acc.push(this.processBracket(enclosed, "#", class_style));
        i = j + 1;
      } else {
        so_far += c;
        i++;
      }
    }

    // Add remaining so_far to accum
    acc.push(so_far);
    return acc;
  };
  processBracket = (enclosed, opener, class_style) => {
    if (opener == "#") {
      return this.returnColoredText(enclosed, class_style);
    } else if (opener == "<1>") {
      return (
        <>
          <i
            onClick={() => {
              this.playSound(this.state.description_audio_1_url);
            }}
            id="myletteraudio"
            class="fas fa-volume-up"
          ></i>
          <span
            onClick={() => {
              this.playSound(this.state.description_audio_1_url);
            }}
            class="myhover"
            style={{ color: this.state.theme_color_style }}
          >
            {" "}
            {enclosed}
          </span>
        </>
      );
    } else if (opener == "<2>") {
      return (
        <>
          <i
            onClick={() => {
              this.playSound(this.state.description_audio_1_url);
            }}
            id="myletteraudio"
            class="fas fa-volume-up"
          ></i>
          <span
            onClick={() => {
              this.playSound(this.state.description_audio_1_url);
            }}
            class="myhover"
            style={{ color: this.state.theme_color_style }}
          >
            {" "}
            {enclosed}
          </span>
        </>
      );
    } else if (opener == "<3>") {
      return (
        <span
          onClick={() => {
            this.playSound(this.state.description_audio_1_url);
          }}
          class="myhover"
          style={{ color: this.state.theme_color_style }}
        >
          {" "}
          {enclosed}
        </span>
      );
    } else if (opener == "$1$") {
      return (
        <i
          onClick={() => {
            this.playSound(this.state.description_audio_1_url);
          }}
          id="myletteraudio"
          class="fas fa-volume-up"
        ></i>
      );
    } else if (opener == "$2$") {
    } else if (opener == "$3$") {
    } else {
      return enclosed;
    }
  };
  // returnColoredText = (my_text, class_style) => {
  //   return <span class={class_style}>{my_text}</span>;
  // };

  displayAcc = (acc) => {
    return <p>{acc}</p>;
  };
  tokensToJSX = (tokens, class_style) => {
    var acc = [];
    for (var i = 0; i < tokens.length; i++) {
      if (i % 2 == 0) {
        acc.push(tokens[i]);
      } else {
        acc.push(this.returnColoredText(tokens[i], class_style));
      }
    }
    return <p>{acc}</p>;
  };
  category_header = (header_image) => {
    return (
      <div class="dpack-title">
        {this.category_info(header_image)}
        {this.likeableHeart()}
      </div>
    );
  };
  category_info = (header_image) => {
    return (
      <div>
        <img
          style={{
            display: "inline-block",
            // border: "1px solid blue",
            verticalAlign: "top",
            marginTop: "2px",
          }}
          id="headerImage"
          src={header_image}
        />
        <div
          class="headerTextGroup"
          style={{
            // border: "1px solid green",
            display: "inline-block",
          }}
        >
          <div>
            <p id="categoryTitle">{this.state.category_title_name}</p>
          </div>
          <div
            style={{
              // border: "1px solid purple",
              marginTop: -2,
            }}
          >
            <p id="categorySubInfo">
              {this.state.category_sub_info_1} &nbsp;•&nbsp;{" "}
              {this.state.category_sub_info_2}
            </p>
          </div>
        </div>
      </div>
    );
  };
  questionType = (type) => {
    let my_image = "";
    if (type == "card-quotes-sayings") {
      let header_image = quotes_image;
      my_image = this.state.main_image_url;
      return (
        <>
          <div class="piawbox">
            <div class="dpack-card piaw">
              {this.category_header(header_image)}
            </div>
            <img class="piaw" src={my_image} />
          </div>
        </>
      );
    }
    if (type == "card-games-activities") {
      let header_image = puzzles_image;
      return (
        <>
          <div class="piawbox">
            <div
              class="dpack-card piaw"
              style={{
                paddingRight: "0",
                paddingLeft: "0",
                marginRight: "5px",
              }}
            >
              {this.category_header(header_image)}
            </div>
            {this.state.support_header_subtext &&
              this.state.encoded_description && (
                <WordSearch
                  setClick={this.props.setClick}
                  answerSubmitted={this.props.answerSubmitted}
                  answerCorrect={this.props.answerCorrect}
                  wordsConcat={this.state.support_header_subtext}
                  gridLettersConcat={this.state.encoded_description}
                  extraInfo={this.state.extra_info}
                />
              )}
          </div>
        </>
      );
    } else if (
      type == "card-culture-knowledge" ||
      type == "card-lang-culture" ||
      type == "card-people-advancements"
    ) {
      let jsx = this.encodedStringToJSX(
        this.state.encoded_description,
        this.state.theme_color_style
      );
      let header_subtext_jsx = this.encodedStringToJSX(
        this.state.support_header_subtext,
        this.state.theme_color_style
      );
      let header_image = african_mask;
      if (type == "card-lang-culture") {
        header_image = talk_image;
      }
      if (type == "card-people-advancements") {
        header_image = people_image;
      }
      return (
        <div class="piawbox">
          <div class="dpack-card piaw">
            {this.category_header(header_image)}

            <div class="dpack-text aboveimage">
              <h3>{this.state.support_header}</h3>
              <p>
                <span>{header_subtext_jsx}</span>
              </p>
            </div>
            <div class="dpack-image">
              <img src={this.state.main_image_url} />
            </div>
            <div class="dpack-text">
              <div
                class="headerwithaudio myhover"
                // onload={this.playSound(this.state.title_audio_url)}
                onClick={() => {
                  this.playSound(this.state.title_audio_url);
                }}
              >
                <h5>
                  <i class="fas fa-volume-up" id="headerspeaker">
                    {" "}
                  </i>
                </h5>
                <h3>{this.state.title}</h3>
              </div>
              {jsx}
            </div>
          </div>
        </div>
      );
    } else if (type == "card-places-experiences") {
      let textToDecode = this.state.encoded_description;
      let jsx = this.encodedStringToJSX(textToDecode, "#ff8037");
      let header_image = map_image;
      return (
        <div class="piawbox">
          <div class="dpack-card piaw">
            {this.category_header(header_image)}
            <div class="dpack-image">
              <img src={this.state.main_image_url} />
            </div>
            <div class="dpack-text">
              <div
                class="headerwithaudio myhover"
                // onload={this.playSound(this.state.title_audio_url)}
                onClick={() => {
                  this.playSound(this.state.title_audio_url);
                }}
              >
                <h5>
                  <i class="fas fa-volume-up" id="headerspeaker">
                    {" "}
                  </i>
                </h5>
                <h3>{this.state.title}</h3>
              </div>
              {jsx}
            </div>
          </div>
        </div>
      );
    } else if (type == "card-language") {
      return this.languageCard(this.state.language_question_type);
    } else {
      return this.languageCard(type);
    }
  };
  encodedStringToJSX = (textToDecode, theme_color_style) => {
    let acc = this.parseEncodedString(textToDecode, theme_color_style);
    let jsx = this.displayAcc(acc);
    return jsx;
  };
  render() {
    return this.questionType(this.props.question_type);
  }
}

export default QuestionCard;
