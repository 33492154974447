import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Card } from "semantic-ui-react";
import "./PaymentOptions.css";
import { UserConsumer } from "./components/User/User.js";
import love from "./Images/love.png";
import user_play from "./Images/user-play.png";
import background from "./components/SignupForm/images/africanpattern1.jpg";

// Semantic Ui React example card
const CardMonth = () => (
  <a class="ui card">
    <div class="image" id="my_image">
      <img src={user_play} id="month_image" />
    </div>
    <div class="content centerText">
      <div id="title">Monthly Pass</div>
      <div class="header" id="price">
        $8.99
      </div>
      <div class="meta" id="mymeta">
        <span id="mydate" class="date">
          per month
        </span>
      </div>
      <div class="description" id="descrip">
        <Link
          to={{
            pathname: "/checkout-subscribe",
            state: { payment_option: "monthly" },
          }}
          style={{ textDecoration: "none" }}
        >
          <button id="payButton" class="ui green button" on>
            Select
          </button>
        </Link>
      </div>
    </div>
    <div class="extra content" id="vnorec">
      <i class="fas fa-star" id="icongreen"></i> Recommended
    </div>
  </a>
);

const CardYear = () => (
  <a class="ui card">
    <div class="image" id="my_image">
      <img src={love} id="year_image" />
    </div>
    <div class="content centerText">
      <div id="title">Full Year Pass</div>
      <div class="header" id="price">
        $98
      </div>
      <div class="meta" id="mymeta">
        <span id="mydate" class="date">
          per year - 1 month free
        </span>
      </div>
      <div class="description" id="descrip">
        <Link
          to={{
            pathname: "/checkout-subscribe",
            state: { payment_option: "yearly" },
          }}
          style={{ textDecoration: "none" }}
        >
          <button id="payButton" class="ui green button">
            Select
          </button>
        </Link>
      </div>
    </div>
    <div class="extra content" id="centerRec">
      <i class="fas fa-star" id="icongreen"></i>{" "}
      <span id="spanrec">Recommended</span>
    </div>
  </a>
);

const PaymentOptions = (router_props) => {
  const ctx = useContext(UserConsumer);

  const card1 = () => {
    return (
      <div class="paycard paycard1">
        {/* card1 */}
        <CardMonth />
      </div>
    );
  };
  const card2 = () => {
    return (
      <div class="paycard paycard2">
        {/* card2 */}
        <CardYear />
      </div>
    );
  };
  const cardSet = (ctx) => {
    return (
      <div class="paymentBox">
        <div class="payHeader">
          {/* <Link to = ""class="navbar-brand" href="#"> */}
          <img
            onClick={(e) => {
              ctx.recent_select_course_redirect();
            }}
            style={{ cursor: "pointer" }}
            class="mfavicon"
            alt="learn African language platform app"
            src="./Images/da-logo-lineart.png"
          />
          {/*   </Link> */}
          <p id="payTitle">Invest in yourself today. Subscribe now!</p>
        </div>
        <div class="paymentCardSet">
          {card1()}
          {card2()}
        </div>
      </div>
    );
  };

  // Render function to display functional component
  // elements.
  const render = (ctx) => {
    return (
      <>
        {}
        {cardSet(ctx)}
      </>
    );
  };

  return <div>{render(ctx)}</div>;
};

export default PaymentOptions;
