import React, { Component } from "react";
import sbimage from "./app_content/navigation_pages_content/images/speakerwhitebg.png";
import lightbulb from "./app_content/navigation_pages_content/images/animat-lightbulb-color.gif";
import "./WordBank.css";
import TranslateQuestion from "./TranslateQuiz/TranslateQuestion.js";
import "./TranslateQuiz/TranslateQuiz.css";
import Progress from "./Progress.js";
import { Link } from "react-router-dom";
import { Popup, Card, Rating, Image } from "semantic-ui-react";

const PopupWord = ({
  component: triggerComponent,
  word: givenWord,
  header: myheader,
}) => (
  <Popup style={{ position: "top left" }} trigger={triggerComponent}>
    <Popup.Header>
      {myheader == "" ? (
        <p>
          {" "}
          <img src={lightbulb} id="bulb_animated" />
          {givenWord} &nbsp;
        </p>
      ) : (
        <p>
          {" "}
          <img src={lightbulb} id="bulb_animated" /> &nbsp;{givenWord} &nbsp;
          <i id="nicegreen" class="fas fa-arrows-alt-h"></i> &nbsp;{myheader}
        </p>
      )}
    </Popup.Header>
  </Popup>
);

class WordBank extends Component {
  constructor(props) {
    super(props);
    this.myaudio = new Audio();
    this.state = {
      stateaudio: "",
      questionPhrase: this.props.questionPhrase,
      hintWords: this.props.hintWords,
      answerPhrase: this.props.answerPhrase,
      answerExtras: this.props.answerExtras,
      displayPhrase: this.props.displayPhrase,
      phrase: this.props.questionObject.phrase
        ? this.props.questionObject.phrase
        : this.props.questionObject.card.phrase,
    }; //state close
    // Bind Functions
    this.playQuestionAudio = this.playQuestionAudio.bind(this);
    this.getColor = this.getColor.bind(this);
    this.renderQuestion = this.renderQuestion.bind(this);
    this.group_info = this.group_info.bind(this);
    this.concatenate_string_array = this.concatenate_string_array.bind(this);
    this.orderedPhraseWordsArray = this.orderedPhraseWordsArray.bind(this);
    this.correctAnswerWordsArray = this.correctAnswerWordsArray.bind(this);
    this.arrayShuffle = this.arrayShuffle.bind(this);
    this.buildSelectableChoices = this.buildSelectableChoices.bind(this);
    this.phrase_word_group_type = this.phrase_word_group_type.bind(this);
    this.renderMainQuestionLine = this.renderMainQuestionLine.bind(this);
    this.renderQuizDirectionsLine = this.renderQuizDirectionsLine.bind(this);
    this.returnDisplayPhrase = this.returnDisplayPhrase.bind(this);
    this.returnDisplayPhraseColored = this.returnDisplayPhraseColored.bind(
      this
    );
    this.returnSoundButton = this.returnSoundButton.bind(this);
  }

  //Use a Fisher-Yates shuffle for more uniform probability of each outcome
  arrayShuffle(in_array) {
    var arr = in_array;
    for (let i = arr.length - 1; i > 0; i--) {
      let k = Math.random() * (i + 1);
      let j = Math.floor(k);
      let l = arr[i];
      arr[i] = arr[j];
      arr[j] = l;
    }
    return arr;
  }
  phrase_word_group_type(lang) {
    if (lang === "literal") {
      return "0";
    } else if (lang === "underlying") {
      return "1";
    } else if (lang === "understood") {
      if (this.state.phrase.same_literal_and_understood) {
        return "0";
      }
      return "2";
    } else if (lang === "english_extras") {
      return "3";
    } else if (lang === "twi_extras") {
      return "4";
    }
  }
  renderQuestion() {
    return (
      <TranslateQuestion
        sentence={this.props.questionObject.underlying_native_phrase}
        correctAnswerWordsArray={this.correctAnswerWordsArray}
        selectableChoicesFunc={this.buildSelectableChoices}
        setClick2={this.props.setClick}
        answerSubmitted={this.props.answerSubmitted}
        answerCorrect={this.props.answerCorrect}
      />
    );
  }
  correctAnswerWordsArray() {
    var answer_arr = this.orderedPhraseWordsArray(
      this.phrase_word_group_type(this.state.answerPhrase)
    );
    var choices = [];
    for (var i = 0; i < answer_arr.length; i++) {
      choices.push({ text: answer_arr[i], position: i, audio_url: "" });
    }
    return choices;
  }
  buildSelectableChoices() {
    var info_arr = this.orderedPhraseWordsArray(
      this.phrase_word_group_type(this.state.answerPhrase)
    );
    var extra_arr1 = this.orderedPhraseWordsArray(
      this.phrase_word_group_type(this.state.answerExtras)
    );
    //Concatenate array
    info_arr = info_arr.concat(extra_arr1);
    var info_arr_shuffled = this.arrayShuffle(info_arr);

    var choices = [];
    for (var i = 0; i < info_arr_shuffled.length; i++) {
      choices.push({ text: info_arr_shuffled[i], position: i, audio_url: "" });
    }
    return choices;
  }
  orderedPhraseWordsArray(language_id) {
    let info_arr = [];
    this.state.phrase.phrase_word_grouped.map((phrase_word) => {
      if (phrase_word.language_id == language_id) {
        info_arr.push(phrase_word.text);
      }
    });
    //Add question mark
    if (
      this.state.phrase.is_question &&
      language_id != this.phrase_word_group_type(this.state.answerExtras)
    ) {
      info_arr.push("?");
    }
    return info_arr;
  }

  componentDidMount() {
    return setTimeout(
      function () {
        this.playQuestionAudio();
      }.bind(this),
      850
    );
  }

  getColor(option) {
    if (option === "1") {
      return "red_word";
    } else if (option === "2") {
      return "yellow_word";
    } else if (option === "3") {
      return "blue_word";
    } else if (option === "4") {
      return "green_word";
    } else if (option === "5") {
      return "orange_word";
    } else if (option === "none") {
      return "light_grey";
    } else if (option === "answer_color") {
      return "answer_green";
    }
  }
  playQuestionAudio() {
    if (this.props.questionPhrase == "underlying") {
      this.state.stateaudio = this.state.phrase.underlying_native_phrase_audio_url;
    }
    this.myaudio.src = this.state.stateaudio;

    var promise = this.myaudio.play();
    if (promise !== undefined) {
      promise
        .catch((error) => {
          // Auto-play was prevented
          // Show a UI element to let the user manually start playback
        })
        .then(() => {
          // Auto-play started
        });
    }
  }
  group_info(group_number, language_id) {
    //optimize to remove unnecessary computation on each call
    var groupInfo1 = new Object();
    this.state.phrase.phrase_word_grouped.map((phrase_word) => {
      if (phrase_word.language_id == language_id) {
        let info_arr = [];
        if (groupInfo1.hasOwnProperty(phrase_word.group_number)) {
          info_arr = groupInfo1[phrase_word.group_number];
        }
        info_arr.push(phrase_word.text);
        groupInfo1[phrase_word.group_number] = info_arr;
      }
    });
    return groupInfo1[group_number];
  }
  concatenate_string_array(str_arr) {
    if (str_arr == null) {
      return "";
    }
    var res = "";
    for (var i = 0; i < str_arr.length; i++) {
      res = res + " " + str_arr[i];
    }
    return res;
  }
  returnSoundButton(sound_button_style) {
    if (this.props.questionPhrase != "understood") {
      return (
        <img
          id={sound_button_style}
          onClick={this.playQuestionAudio}
          src={sbimage}
        />
      );
    } else {
      return <div></div>;
    }
  }
  returnDisplayPhraseColored() {
    //green word == "4"
    var color_style_str = "answer_color";
    var hide_str = "";
    return this.returnDisplayPhrase(color_style_str, hide_str);
  }
  returnDisplayPhrase(style_str, hide_str) {
    var mainClassStyle = "horizontal";
    var sound_button_style = "sbitranslate_wordbank";
    if (hide_str == "nodisplay") {
      sound_button_style = "sbit2";
    }
    return (
      <div id="question4info2" class={mainClassStyle}>
        {hide_str == "nodisplay" ? (
          this.returnSoundButton(sound_button_style)
        ) : (
          <div></div>
        )}
        <div id={hide_str}>
          <p class="horizontal">{this.returnSoundButton(sound_button_style)}</p>
          {this.state.phrase.phrase_word_grouped.map((phrase_word) => {
            if (
              phrase_word.language_id ==
              this.phrase_word_group_type(this.state.questionPhrase)
            ) {
              let header_arr = this.group_info(
                phrase_word.group_number,
                this.phrase_word_group_type(this.state.questionPhrase)
              );
              let word_arr = this.group_info(
                phrase_word.group_number,
                this.phrase_word_group_type(this.state.hintWords)
              );
              let myheader = this.concatenate_string_array(header_arr);
              let myword = this.concatenate_string_array(word_arr);

              if (myword == "") {
                return (
                  <p class="horizontal" id={this.getColor(style_str)}>
                    {phrase_word.text}&nbsp;
                  </p>
                );
              } else {
                return (
                  <PopupWord
                    component={
                      <div>
                        <p
                          class="horizontal word_emphasis"
                          id={this.getColor(style_str)}
                        >
                          {phrase_word.text}
                        </p>
                        <span>&nbsp;</span>
                      </div>
                    }
                    word={myword}
                    header={header_arr.length > 1 ? myheader : ""}
                  />
                );
              }
            }
          })}
          {this.state.phrase.is_question ? (
            <p class="horizontal" id={this.getColor("none")}>
              ?
            </p>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    );
  }
  renderMainQuestionLine() {
    if (this.state.displayPhrase) {
      return this.returnDisplayPhrase("none", "");
    } else if (!this.state.displayPhrase && this.props.answerSubmitted) {
      if (this.props.questionPhrase == this.props.answerPhrase) {
        return this.returnDisplayPhrase("none", "nodisplay");
      } else {
        return this.returnDisplayPhraseColored();
      }
    } else if (!this.state.displayPhrase && !this.props.answerSubmitted) {
      var hide_str = "nodisplay";
      return this.returnDisplayPhrase("none", hide_str);
    }
  }

  renderQuizDirectionsLine() {
    if (this.state.displayPhrase) {
      return <p class="questiontext_wordbank">Translate using the word bank</p>;
    } else if (
      !this.state.displayPhrase &&
      this.props.questionPhrase == this.props.answerPhrase
    ) {
      return (
        <p class="questiontext_wordbank">
          Transcribe the audio using the word bank
        </p>
      );
    } else {
      return (
        <div class="question4info">
          <div>
            <p class="questiontext_wordbank">
              Translate the audio using the word bank
            </p>
          </div>
        </div>
      );
    }
  }

  render() {
    return (
      <div id="wordbankframe">
        <head></head>
        <div>{this.renderQuizDirectionsLine()}</div>
        <br />
        <div class="corebox">
          <div>{this.renderMainQuestionLine()}</div>
          <div id="quiz-question">{this.renderQuestion()}</div>
        </div>
      </div>
    );
  }
} //end of Word-Bank className

export default WordBank;
