import React, { Component } from "react";
import "./Choicesthree.css";

class ChoicesMispell extends Component {
  constructor(props) {
    super(props);
    this.playChoiceAudio = this.playChoiceAudio.bind(this);
    this.putSelectedChoice = this.putSelectedChoice.bind(this);
    this.getbuttonstyle = this.getbuttonstyle.bind(this);
    this.audio = new Audio();
    this.state = {
      using_african_word: this.props.using_african_word,
      currentChoice: "",
      cans: true,
      selectedChoiceObject: "",
      selectedChoiceId: "",
      tempChoiceId: "", //the choice id used for audio pronunciation - to allow pronunciation both before and after submission
      choices: !this.props.auto_generated_choices
        ? this.props.questionObject.choices
        : this.props.questionObject.auto_generated_choices.choices,
      correct_answer: !this.props.auto_generated_choices
        ? this.props.questionObject.correct_answer
        : this.props.questionObject.auto_generated_choices
            .correct_answer_option,
    };
  }
  // componentDidMount() {
  //   return setTimeout(
  //     function () {
  //       this.playChoiceAudio(this.state.correct_answer);
  //     }.bind(this),
  //     850
  //   );
  // }
  // playQuestionAudio() {
  //   if (this.props.questionPhrase == "underlying") {
  //     this.state.stateaudio = this.state.phrase.underlying_native_phrase_audio_url;
  //   }
  //   this.myaudio.src = this.state.stateaudio;

  //   var promise = this.myaudio.play();
  //   if (promise !== undefined) {
  //     promise
  //       .catch((error) => {
  //         // Auto-play was prevented
  //         // Show a UI element to let the user manually start playback
  //       })
  //       .then(() => {
  //         // Auto-play started
  //       });
  //   }
  // }
  putSelectedChoice(choice) {
    this.setState({ selectedChoiceObject: choice });
  }
  handleChange(e) {
    // console.log("mispell debug:: handle change");
    // console.log(e.target);
    const selected = e.target.value;
    this.props.setSelectedChoice(selected);
    this.props.setCurrentQuestionObject(this.props.questionObject);
    if (this.props.answerSubmitted == false) {
      // console.log("mispell debug:: handle change :: unsubmitted");
      this.setState({ selectedChoiceId: selected });
      this.setState({ tempChoiceId: selected });
      // for mispells only play choice audio after
      // user answered incorrectly
      if (selected != this.state.correct_answer) {
        this.playChoiceAudio(selected);
      }
    } else {
      // console.log("mispell debug:: handle change :: submitted");
      this.setState({ tempChoiceId: selected });
      this.playChoiceAudio(selected);
    }
    // console.log("mispell debug:: handle change complete");
  }
  playChoiceAudio(selected) {
    this.audio.pause();
    // console.log(this.state.selectedChoiceObject);
    this.state.choices.map((choice) => {
      if (selected === choice.multiple_choice_option) {
        this.audio.src = this.getChoiceAudio(choice);
        this.audio.play();
      }
    });
    // console.log(this.state.selectedChoiceObject);
  }
  getbuttonstyle(choiceId) {
    var correcta = this.state.correct_answer === choiceId;
    var submitted = this.props.answerSubmitted === true;
    var selecteda = this.state.selectedChoiceId === choiceId;
    var anscorrect = submitted && correcta;
    var answrong = submitted && !correcta && selecteda;
    if (answrong) {
      return "sentence_wrongansbutton";
    } else if (anscorrect) {
      return "sentence_correctansbutton";
    } else {
      if (choiceId == this.state.selectedChoiceId) {
        return "selectedchoicebuttonstyle";
      } else {
        return "sentence_choicebuttonstyle";
      }
    }
  }
  getChoiceMispell(choice, num) {
    var choiceword = choice.word;
    var mispell = "";
    if (this.state.using_african_word) {
      choiceword = choice.african_word;
      switch (num) {
        case 1:
          mispell = choiceword.native_mispell_one;
          break;
        case 2:
          mispell = choiceword.native_mispell_two;
          break;
        case 3:
          mispell = choiceword.native_mispell_three;
          break;
        default:
      }
    } else {
      switch (num) {
        case 1:
          mispell = choiceword.mispell_one;
          break;
        case 2:
          mispell = choiceword.mispell_two;
          break;
        case 3:
          mispell = choiceword.mispell_three;
          break;
        default:
      }
    }
    return mispell;
  }
  getChoiceText(choice) {
    var text = "";
    var choiceword = "";
    if (this.state.using_african_word) {
      choiceword = choice.african_word;
      text = choiceword.native_text;
    } else {
      choiceword = choice.word;
      text = choiceword.twi_text;
    }
    return text;
  }
  getChoiceAudio(choice) {
    var choiceword = "";
    var audio_url = "";
    if (this.state.using_african_word) {
      choiceword = choice.african_word;
      audio_url = choiceword.native_audio_url;
    } else {
      choiceword = choice.word;
      audio_url = choiceword.twi_audio_url;
    }
    return audio_url;
  }
  render() {
    return (
      <div>
        <div className="choicethreeitemcollection">
          <label className="sentence_choicethreeitem">
            {this.state.choices.map((choice) => {
              this.state.currentChoice = choice;
              if ("a" === choice.multiple_choice_option) {
                this.state.selectedChoice = choice;
                var display_word = this.getChoiceMispell(
                  this.state.currentChoice,
                  1
                );
                if (this.state.correct_answer == "a") {
                  display_word = this.getChoiceText(this.state.currentChoice);
                }
                return (
                  <div class="choicebutton_three" id={this.getbuttonstyle("a")}>
                    <input
                      className="radiostylethree"
                      onChange={this.handleChange.bind(this)}
                      type="radio"
                      name={this.props.questionObject.question_number}
                      value={"a"}
                    />{" "}
                    {display_word}
                  </div>
                );
              }
            })}
          </label>
          <label className="sentence_choicethreeitem">
            {this.state.choices.map((choice) => {
              if ("b" === choice.multiple_choice_option) {
                this.state.selectedChoice = choice;
                var display_word = this.getChoiceMispell(
                  this.state.currentChoice,
                  2
                );
                if (this.state.correct_answer == "b") {
                  display_word = this.getChoiceText(this.state.currentChoice);
                }
                return (
                  <div
                    className="choicebutton_three"
                    id={this.getbuttonstyle("b")}
                  >
                    <input
                      className="radiostylethree"
                      onChange={this.handleChange.bind(this)}
                      type="radio"
                      name={this.props.questionObject.question_number}
                      value={"b"}
                    />{" "}
                    {display_word}
                  </div>
                );
              }
            })}
          </label>
          <label className="sentence_choicethreeitem">
            {this.state.choices.map((choice) => {
              if ("c" === choice.multiple_choice_option) {
                this.state.selectedChoice = choice;
                var display_word = this.getChoiceMispell(
                  this.state.currentChoice,
                  3
                );
                if (this.state.correct_answer == "c") {
                  display_word = this.getChoiceText(this.state.currentChoice);
                }
                return (
                  <div
                    className="choicebutton_three"
                    id={this.getbuttonstyle("c")}
                  >
                    <input
                      className="radiostylethree"
                      onChange={this.handleChange.bind(this)}
                      type="radio"
                      name={this.props.questionObject.question_number}
                      value={"c"}
                    />{" "}
                    {display_word}
                  </div>
                );
              }
            })}
          </label>
        </div>
      </div>
    );
  }
}
export default ChoicesMispell;
