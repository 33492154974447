export function PostData(type, userData) {

let BaseURL = 'https://dialogue-rest-api.herokuapp.com/';
let temptype = 'login/'
let localURL = 'http://127.0.0.1:8000/'
return new Promise((resolve, reject) =>{
fetch(localURL+type, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(userData)
})
.then((response) => response.json())
.then((res) => {
    resolve(res);
})
.catch((error) => {
   reject(error);
});

});
}
