import React, { Component } from "react";
import "./Multiplechoice.css";
// import correctmark from "./app_content/navigation_pages_content/images/check2.png"
import correctmark from "./app_content/navigation_pages_content/images/animat-checkmark-color.gif";

class CheckmarksThree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      check: correctmark,
      loaded: correctmark,
    };
  }
  // componentDidUpdate(prevProps) {
  //   this.setState({loaded: ''})
  //   setTimeout(() => {
  //     this.setState({loaded: this.state.check})
  //   }, 0)
  // }
  render() {
    var checkmarkCorrectImagePath = correctmark;
    // console.log("answerCorrect cm3: ", this.props.answerCorrect);
    // console.log("answerSubmitted cm3: ", this.props.answerSubmitted);
    return (
      <div>
        <div className="checkmarkitemsthree">
          {this.props.answerSubmitted === true && this.props.answerCorrect ? (
            <img src={checkmarkCorrectImagePath} alt="loading..." />
          ) : (
            <div></div>
          )}
        </div>
      </div>
    );
  }
}

export default CheckmarksThree;
