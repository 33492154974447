import React from "react";
import "./styles/Signupform.css";
import { UserConsumer } from "../User/User.js";
import { Link } from "react-router-dom";
import background from "./images/bg-01.jpg";
import { Navbar, Nav, NavItem, MenuItem, NavDropdown } from "react-bootstrap";

import $ from "jquery";

export default class PasswordResetForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      confirmPassword: "",
      username: "",
      submitted: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handleUsername = this.handleUsername.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.handleConfirmPassword = this.handleConfirmPassword.bind(this);
    this.redirectUser = this.redirectUser.bind(this);
  }

  redirectUser(path) {
    this.props.history.push(path);
  }
  submitInfo(ctx, data) {
    // var path = "api/password_reset/reset_password/confirm/";
    var path = "api/pwd_reset_confirm/";
    const headers = {
      "Content-Type": "application/json",
      Authorization: `JWT ${data.token}`,
    };
    return ctx.post(path, data, headers);
  }
  handleSubmit(e, ctx) {
    e.preventDefault();
    const passwordsMatch = this.state.password === this.state.confirmPassword;
    //If the passwords match, make a post request.
    if (passwordsMatch) {
      const query = new URLSearchParams(window.location.search);
      const token_info = query.get("token");
      const data = {
        password: this.state.password,
        token: token_info,
      };
      this.submitInfo(ctx, data)
        .then(async (res) => {
          let json = await res.json();
          if (res.ok) {
            localStorage.setItem("token", json.token);
            localStorage.setItem("username", data.username);
            localStorage.setItem("isLoggedIn", true);
            localStorage.setItem("gueststatus", false);
            alert(
              "Password reset complete. Proceed to login with new credentials!"
            );
            this.redirectUser("/login");
          } else {
            let json_keys = Object.keys(json);
            if (json_keys.length > 0) {
              let first_key_str = json_keys[0];
              let capitalized =
                first_key_str.charAt(0).toUpperCase() + first_key_str.slice(1);
              alert(`${capitalized} error. ${json[first_key_str]}`);
            } else {
              alert(
                "Password reset failed. Please confirm e-mail link or token is correct. Contact info@dialogue-africa.com if issue persists."
              );
            }
          }
        })
        .catch(function (error) {
          //Alert username or password is invalid
          console.error(error);
          alert(
            "Password reset failed. Please confirm e-mail link/token is correct. Contact info@dialogue-africa.com if issue persists."
          );
        });
    } else {
      alert("Passwords do not match. Retry");
    }
  }
  handleEmail(event) {
    this.setState({ email: event.target.value });
  }

  handleUsername(event) {
    this.setState({ username: event.target.value });
  }

  handlePassword(event) {
    this.setState({ password: event.target.value });
  }

  handleConfirmPassword(event) {
    this.setState({ confirmPassword: event.target.value });
  }

  render() {
    return (
      <UserConsumer>
        {(value) => (
          <div>
            <div>
              <div>
                <head>
                  <title>
                    Password Reset. Learn African languages and connect to
                    culture. Learn Twi, Yoruba, Swahili, Igbo, Ga, Fante and
                    more.
                  </title>
                  <meta
                    name="description"
                    content="Dialogue Africa provides an online platform and application to learn African languages and connect to culture. Learn Twi, Fante, Ga, Swahili, Yoruba and more"
                  />
                  <meta charset="UTF-8" />
                  <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1"
                  />
                  <link
                    rel="icon"
                    type="image/png"
                    href="./images/icons/favicon.ico"
                  />
                  <link
                    rel="stylesheet"
                    type="text/css"
                    href="vendor/bootstrap/css/bootstrap.min.css"
                  />
                  <link
                    rel="stylesheet"
                    type="text/css"
                    href="fonts/font-awesome-4.7.0/css/font-awesome.min.css"
                  />
                  <link
                    rel="stylesheet"
                    type="text/css"
                    href="fonts/iconic/css/material-design-iconic-font.min.css"
                  />
                  <link
                    rel="stylesheet"
                    type="text/css"
                    href="vendor/animate/animate.css"
                  />
                  <link
                    rel="stylesheet"
                    type="text/css"
                    href="vendor/css-hamburgers/hamburgers.min.css"
                  />
                  <link
                    rel="stylesheet"
                    type="text/css"
                    href="vendor/animsition/css/animsition.min.css"
                  />
                  <link
                    rel="stylesheet"
                    type="text/css"
                    href="vendor/select2/select2.min.css"
                  />
                  <link
                    rel="stylesheet"
                    type="text/css"
                    href="vendor/daterangepicker/daterangepicker.css"
                  />
                  <link rel="stylesheet" type="text/css" href="css/util.css" />
                  <link rel="stylesheet" type="text/css" href="css/main.css" />

                  <link
                    href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
                    rel="stylesheet"
                  />
                  <link
                    rel="stylesheet"
                    href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css"
                    integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm"
                    crossorigin="anonymous"
                  />
                  <link
                    rel="stylesheet"
                    href="../../styleshannon1.css"
                    type="text/css"
                  />
                  <link
                    href="https://fonts.googleapis.com/css?family=Open+Sans|Raleway"
                    rel="stylesheet"
                  />
                  <link
                    href="https://fonts.googleapis.com/css?family=Montserrat"
                    rel="stylesheet"
                  />
                  <link
                    rel="stylesheet"
                    type="text/css"
                    href="//fonts.googleapis.com/css?family=Varela+Round"
                  />
                </head>
                {/*	<nav class="navbar navheight navbar-expand-lg fixed-top navbar-light">*/}
                <nav class="navbar fixed-top navbar-expand-lg navbar-light bg-light">
                  <Link class="navbar-brand" to="/">
                    <img
                      class="favicon dialoguelogobar"
                      alt="Brand"
                      src="../../Images/da-logo-lineart.png"
                    />
                  </Link>
                  <button
                    class="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarTogglerDemo02"
                    aria-controls="navbarTogglerDemo02"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span class="navbar-toggler-icon"></span>
                  </button>

                  <div
                    class="collapse navbar-collapse"
                    id="navbarTogglerDemo02"
                  >
                    <ul class="navbar-nav ml-auto">
                      <li class="nav-item active">
                        <Link class="nav-link" to="/">
                          <span class="fa fa-home"></span> Home
                          <span class="sr-only">(current)</span>
                        </Link>
                      </li>
                      <li class="nav-item active">
                        <a class="nav-link" href="/blog-main.html">
                          <span class="fa fa-book"></span> Blog
                        </a>
                      </li>
                      <li class="nav-item active">
                        <Link class="nav-link" to="/login">
                          <span class="fa fa-sign-in" aria-hidden="true"></span>{" "}
                          Login
                        </Link>
                      </li>
                      <li class="nav-item active">
                        <Link class="nav-link" to="/select_course">
                          <span class="fa fa-user"></span> Select Course
                        </Link>
                      </li>
                    </ul>
                  </div>
                </nav>

                <div class="limiter">
                  <div
                    class="container-login100"
                    style={{ backgroundImage: "url(" + background + ")" }}
                  >
                    <div class="wrap-login100 p-l-55 p-r-55 p-t-65 p-b-54">
                      <form
                        class="login100-form validate-form"
                        onSubmit={(e) => {
                          this.handleSubmit(e, value);
                        }}
                      >
                        <span class="login100-form-title p-b-49">
                          Password Reset
                        </span>
                        <div
                          class="wrap-input100 validate-input"
                          data-validate="Password is required"
                        >
                          <span class="label-input100">
                            New Password (6+ characters)
                          </span>
                          <input
                            class="input100"
                            type="password"
                            name="pass"
                            placeholder="Type your password"
                            pattern="^(.*).{6,}$" //"^(?=.*[0-9])(?=.*[a-zA-Z]).{8,}$"
                            value={this.state.password}
                            onChange={this.handlePassword}
                          />
                          <span class="focus-input100"></span>
                        </div>
                        <div
                          class="wrap-input100 validate-input"
                          data-validate="Password confirmation required"
                        >
                          <span class="label-input100">
                            Confirm New Password{" "}
                          </span>
                          <input
                            class="input100"
                            type="password"
                            name="pass"
                            placeholder="Type your password"
                            pattern="^(.*).{6,}$"
                            value={this.state.confirmPassword}
                            onChange={this.handleConfirmPassword}
                          />
                          <span class="focus-input100"></span>
                        </div>
                        <br />

                        <div class="container-login100-form-btn">
                          <div class="wrap-login100-form-btn">
                            <div class="login100-form-bgbtn"></div>
                            <button class="login100-form-btn">
                              Reset Password
                            </button>
                          </div>
                        </div>
                      </form>
                      <br />
                      {/*<div class="flex-col-c p-t-155"> //original styling of div tag below*
										<div class="flex-col-c">
												<span class="txt1 p-b-17">
													Already have an account? <Link to="/login"> Login </Link>
												</span>

										</div>*/}
                      {/*
											<div id="d-inline" class="flex-col-c">
											<Link to="/"> Home Page </Link>
											<Link to="/select_course"> Select Course </Link>
											</div>
                    */}
                    </div>
                  </div>
                </div>

                <div id="dropDownSelect1"></div>

                <script src="vendor/jquery/jquery-3.2.1.min.js"></script>
                <script src="vendor/animsition/js/animsition.min.js"></script>
                <script src="vendor/bootstrap/js/popper.js"></script>
                <script src="vendor/bootstrap/js/bootstrap.min.js"></script>
                <script src="vendor/select2/select2.min.js"></script>
                <script src="vendor/daterangepicker/moment.min.js"></script>
                <script src="vendor/daterangepicker/daterangepicker.js"></script>
                <script src="vendor/countdowntime/countdowntime.js"></script>
                <script src="js/main.js"></script>
              </div>
            </div>
          </div>
        )}
      </UserConsumer>
    );
  }
}
