import React, { Component } from "react";
import sbimage from "./app_content/navigation_pages_content/images/speakerwhitebg.png";
import "./ColorCodedTranslation.css";

class ColorCodedTranslation extends Component {
  constructor(props) {
    super(props);
    this.myaudio = new Audio();
    this.state = {
      mute: false,
      stateaudio: "",
      phrase: this.props.questionObject.phrase
        ? this.props.questionObject.phrase
        : this.props.questionObject.card.phrase,
      same_literal_and_understood: this.props.questionObject.phrase
        ? this.props.questionObject.phrase.same_literal_and_understood
        : this.props.questionObject.card.phrase.same_literal_and_understood,
    }; //state close
    this.playQuestionAudio = this.playQuestionAudio.bind(this);
    this.getColor = this.getColor.bind(this);
  }

  componentDidMount() {
    return setTimeout(
      function () {
        this.playQuestionAudio();
      }.bind(this),
      850
    );
  }

  getColor(option) {
    if (option === "1") {
      return "red_word";
    } else if (option === "2") {
      return "orange_word";
    } else if (option === "3") {
      return "blue_word";
    } else if (option === "4") {
      return "green_word";
    } else if (option === "5") {
      return "yellow_word";
    } else if (option === "none") {
      return "light_grey";
    }
  }
  playQuestionAudio() {
    this.state.stateaudio = this.state.phrase.underlying_native_phrase_audio_url;
    this.myaudio.src = this.state.stateaudio;

    var promise = this.myaudio.play();
    if (promise !== undefined) {
      promise
        .catch((error) => {
          // Auto-play was prevented
          // Show a UI element to let the user manually start playback
        })
        .then(() => {
          // Auto-play started
        });
    }
  }
  add_meaning = () => {
    if (this.state.same_literal_and_understood) {
      return;
    } else {
      return (
        <div>
          <div id="question4info2" class="horizontal_coded">
            <div>
              {this.state.phrase.phrase_word_grouped.map((phrase_word) => {
                if (phrase_word.language_id == "2") {
                  return (
                    <p class="horizontal_coded" id={this.getColor("none")}>
                      {phrase_word.text}&nbsp;
                    </p>
                  );
                }
              })}
              {this.state.phrase.is_question ? (
                <p class="horizontal_coded" id={this.getColor("none")}>
                  ?
                </p>
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </div>
      );
    }
  };
  same_literal_and_understood_adjust = (main_style, same) => {
    if (same) {
      return main_style + " same_literal_and_understood";
    } else {
      return main_style;
    }
  };
  render() {
    var space = " ";
    var twi_text = "me ma mo akwaaba";
    return (
      <div>
        <head>
          <link
            href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
            rel="stylesheet"
          />
          <link
            rel="stylesheet"
            href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css"
            integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm"
            crossorigin="anonymous"
          />
        </head>
        <p class="questiontext_three"> Study the color-coded translation</p>
        <br />
        <div className="translatecardtop"></div>
        <div
          onClick={this.playQuestionAudio}
          class={this.same_literal_and_understood_adjust(
            "translatecard corebox",
            this.state.same_literal_and_understood
          )}
        >
          <div>
            <div id="question4info2" class="horizontal_coded">
              <img
                id="sbitranslate_coded"
                onClick={this.playQuestionAudio}
                src={sbimage}
              />
              <div>
                {this.state.phrase.phrase_word_grouped.map((phrase_word) => {
                  if (phrase_word.language_id == "1") {
                    return (
                      <p
                        class="horizontal_coded"
                        id={this.getColor(phrase_word.group_number)}
                      >
                        {phrase_word.text}&nbsp;
                      </p>
                    );
                  }
                })}
                {this.state.phrase.is_question ? (
                  <p class="horizontal_coded" id={this.getColor("none")}>
                    ?
                  </p>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          </div>
          <div>
            <div id="question4info2" class="horizontal_coded">
              <div>
                {this.state.phrase.phrase_word_grouped.map((phrase_word) => {
                  if (phrase_word.language_id == "0") {
                    return (
                      <p
                        class="horizontal_coded"
                        id={this.getColor(phrase_word.group_number)}
                      >
                        {phrase_word.text}&nbsp;
                      </p>
                    );
                  }
                })}
                {this.state.phrase.is_question ? (
                  <p class="horizontal_coded" id={this.getColor("none")}>
                    ?
                  </p>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          </div>
          {this.add_meaning()}
        </div>
      </div>
    );
  }
} //end of Word-Bank className

export default ColorCodedTranslation;
