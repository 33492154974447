import React, { Component } from "react";
import "./Choices.css";
import sbimage from "./app_content/grammar_quiz_content/images/soundbuttonimage.jpg";

class Choices extends Component {
  constructor(props) {
    super(props);
    this.playChoiceAudio = this.playChoiceAudio.bind(this);
    this.playChoiceAudioParams = this.playChoiceAudioParams.bind(this);
    this.putSelectedChoice = this.putSelectedChoice.bind(this);
    this.getidstyle = this.getidstyle.bind(this);
    this.getChoiceAudio = this.getChoiceAudio.bind(this);
    this.getChoiceImage = this.getChoiceImage.bind(this);
    this.getChoiceText = this.getChoiceText.bind(this);
    this.myaudio = new Audio();
    this.audio = new Audio();
    this.state = {
      using_african_word: this.props.using_african_word,
      cans: true,
      selectedChoiceObject: "",
      selectedChoiceId: "",
      tempChoiceId: "", //the choice id used for audio pronunciation - to allow pronunciation both before and after submission
      mute: false,
      stateaudio: "",
      choices: !this.props.auto_generated_choices
        ? this.props.questionObject.choices
        : this.props.questionObject.auto_generated_choices.choices,
      correct_answer: !this.props.auto_generated_choices
        ? this.props.questionObject.correct_answer
        : this.props.questionObject.auto_generated_choices
            .correct_answer_option,
    };
  }
  putSelectedChoice(choice) {
    this.setState({ selectedChoiceObject: choice });
  }
  handleChange(e) {
    e.preventDefault();
    const selected = e.target.value;
    if (this.props.answerSubmitted == false) {
      this.setState({ selectedChoiceId: selected }, () => {
        this.props.setSelectedChoice(this.state.selectedChoiceId);
      });
      var correctAnsChoice = this.state.correct_answer;

      var timerlen = 600;
      return setTimeout(
        function () {
          this.playChoiceAudioParams(correctAnsChoice);
        }.bind(this),
        timerlen
      );
    } else {
      this.setState({ tempChoiceId: selected });
      this.playChoiceAudio(selected);
    }
  }
  playChoiceAudioParams(selected) {
    if (this.props.mute == false) {
      this.audio.pause();
      this.state.choices.map((choice) => {
        if (selected === choice.multiple_choice_option) {
          this.audio.src = this.getChoiceAudio(choice);
          this.audio.play();
        }
      });
    }
  }
  getChoiceWord(choice) {
    var choiceword = choice.word;
    if (this.state.using_african_word) {
      choiceword = choice.african_word;
    }
    return choiceword;
  }
  getChoiceImage(choice) {
    var choiceword = choice.word;
    var image_url = choiceword.image_url;
    if (this.state.using_african_word) {
      choiceword = choice.african_word;

      // use general image unless there is a
      // language specific image
      if (choiceword.has_native_image) {
        image_url = choiceword.native_image_url;
      }
    }
    return image_url;
  }
  getChoiceText(choice) {
    var text = "";
    var choiceword = "";
    if (this.state.using_african_word) {
      choiceword = choice.african_word;
      text = choiceword.native_text;
    } else {
      choiceword = choice.word;
      text = choiceword.twi_text;
    }
    return text;
  }
  getChoiceAudio(choice) {
    var choiceword = "";
    var audio_url = "";
    if (this.state.using_african_word) {
      choiceword = choice.african_word;
      audio_url = choiceword.native_audio_url;
    } else {
      choiceword = choice.word;
      audio_url = choiceword.twi_audio_url;
    }
    return audio_url;
  }

  playChoiceAudio() {
    if (this.props.mute === false) {
      this.audio.pause();
      this.state.choices.map((choice) => {
        if (this.state.tempChoiceId === choice.multiple_choice_option) {
          this.audio.src = this.getChoiceAudio(choice);
        }
      });
      console.log(this.state.selectedChoiceObject);
      var promise = this.myaudio.play();
      if (promise !== undefined) {
        promise
          .catch((error) => {
            // Auto-play was prevented
            // Show a UI element to let the user manually start playback
          })
          .then(() => {
            // Auto-play started
          });
      }
    }
  }
  getidstyle(choiceId) {
    var correcta = this.state.correct_answer === choiceId;
    var submitted = this.props.answerSubmitted === true;
    var selecteda = this.state.selectedChoiceId === choiceId;
    var anscorrect = submitted && correcta;
    var answrong = submitted && !correcta && selecteda;
    if (answrong) {
      return "wrongans";
    } else if (anscorrect) {
      return "correctans";
    } else {
      if (choiceId == this.state.selectedChoiceId) {
        return "selectedchoice1";
      } else {
        return "choice1";
      }
    }
  }
  getbuttonstyle(choiceId) {
    var correcta = this.state.correct_answer === choiceId;
    var submitted = this.props.answerSubmitted === true;
    var selecteda = this.state.selectedChoiceId === choiceId;
    var anscorrect = submitted && correcta;
    var answrong = submitted && !correcta && selecteda;
    if (answrong) {
      return "wrongansbutton";
    } else if (anscorrect) {
      return "correctansbutton";
    } else {
      if (choiceId == this.state.selectedChoiceId) {
        return "selectedchoicebuttonstyle";
      } else {
        return "choicebuttonstyle";
      }
    }
  }
  choiceItem = (letter) => {
    return (
      <label className="choiceitem">
        {this.state.choices.map((choice) => {
          if (letter === choice.multiple_choice_option) {
            return (
              <div id={this.getidstyle(letter)}>
                <img id="choiceimage" src={this.getChoiceImage(choice)} />
                <input
                  onChange={this.handleChange.bind(this)}
                  type="radio"
                  name={this.props.questionObject.question_number}
                  value={choice.multiple_choice_option}
                  className="radiostyle"
                />
                <p id="choiword">{this.getChoiceText(choice)}</p>
              </div>
            );
          }
        })}
      </label>
    );
  };

  render() {
    return (
      <div>
        <div className="choicesIC">
          {this.choiceItem("a")}
          {this.choiceItem("b")}
          {this.choiceItem("c")}
        </div>
      </div>
    );
  }
}

export default Choices;
