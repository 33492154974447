import React, { Component } from "react";
import exit_quiz from "./app_content/navigation_pages_content/images/exit_quiz4.png";
import { Link } from "react-router-dom";
import congrats_image from "./app_content/navigation_pages_content/images/congrats_money.png";
import tryagain_image from "./app_content/navigation_pages_content/images/scorecard.png";
import { Button, Progress as SemanticProgress } from "semantic-ui-react";
import styled from "styled-components";

export const StyledProgressBar = styled(SemanticProgress)`
  & > .bar {
    background-color: ${(props) => props.color || "green"} !important;
  }
`;
class Progress extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    // Moustrap.bind(['1 2 3 4', up down left right'], this.popUp);
  }
  componentWillUnmount() {
    // Moustrap.unbind(['1 2 3 4', up down left right']);
  }

  //https://www.google.com/search?q=progress+bar+with+custom+image&rlz=1C1CHBF_enUS861US862&oq=progress+bar+with+custom+image&aqs=chrome..69i57j0l3.9751j0j7&sourceid=chrome&ie=UTF-8

  render() {
    let reward = this.props.culture_card ? "culture card" : "proverb point";
    var category = this.props.category;
    var prog_style = "breakunderprogress";
    if (category == "card" || category == "test_card") {
      prog_style = "reducebreak";
    }
    var questionsSoFar = this.props.currentQuestion - 1;
    var questionsToIgnore = this.props.questionsToIgnore;
    var countTestedQuestions = questionsSoFar - questionsToIgnore;
    var percentage = this.props.lessonFetched
      ? 100 * (this.props.currentQuestion / this.props.totalQuestions)
      : 0;
    var stringPercent = percentage.toString();
    var progressStyleString = stringPercent + "%";
    // progress-bar-striped bg-success
    // console.log("progressStyleString: ", progressStyleString);
    var hasReviewText = this.props.isReview ? "Review" : "";
    var stars = this.props.stars;
    //          {/*  <button type="button" id = "entertips" data-toggle="modal" data-target="#exampleModalLong"> */}
    let color_str = this.props.currentThemeColor
      ? this.props.currentThemeColor
      : "#5cb85c"; //"#ff8037", lightgrey;
    // const myCustomColor = "#ff8037"; //"rgb(150, 100, 255)";
    //"rgb(200, 200, 255)";
    return (
      <div>
        {this.props.currentQuestion <= this.props.totalQuestions ? (
          <div>
            <br />
            <br />
            {/* <div> */}
            {/* <StyledProgressBar
                color={myCustomColor}
                percent={stringPercent}
                style={{
                  width: "65%",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginBottom: "0px",
                  transition: "width 1.6s ease !important",
                }}
                active
              /> */}
            {/* <SemanticProgress
                percent={stringPercent}
                color={"purple"}
                style={{
                  color: color_str,
                  width: "65%",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginBottom: "0px",
                }}
                active
              />
              <div id={prog_style}></div> */}
            {/* </div> */}
            <div id={prog_style} class="progress" style={{ width: "65%" }}>
              <div
                class="progress-bar progress-bar-striped"
                style={{
                  width: progressStyleString,
                  backgroundColor: color_str,
                  transition: "width 1.0s ease",
                }}
                role="progressbar"
                // style="background-color: #8d5ce0 !important"
                // style={{
                //   backgroundColor: "#8d5ce0 !important",
                //   width: progressStyleString,
                // }}
                aria-valuenow={stringPercent}
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>
        ) : (
          <div class="congrats_questiontext">
            <br />
            <br />
            {stars >= 3 ? (
              <div>
                <div>
                  <img id="congrats_image" src={congrats_image} />
                  <div>
                    Great Job! You've unlocked a {reward}{" "}
                    <i id="nicegreen" class="fas fa-seedling"></i>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <img id="congrats_image" src={tryagain_image} />
                <div>Good Effort! Try again.</div>
              </div>
            )}
            <div>
              {hasReviewText} Score: {this.props.score} out of{" "}
              {countTestedQuestions}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Progress;
