import React from "react";
import "./styles/Signupform.css";
// import "./stles/SignupformExtra.css";
import { UserConsumer } from "../User/User.js";
import { Link } from "react-router-dom";
import background from "./images/bg-01.jpg";
// import background from "./images/africanpattern4.jpg";
import ReactPixel from "react-facebook-pixel";
import { Button, Icon } from "semantic-ui-react";

if (process.env.REACT_APP_DEV_PIPELINE_STAGE == "production") {
  const options = {
    autoConfig: true, // set pixel's autoConfig
    debug: false, // enable logs
  };
  ReactPixel.init(process.env.REACT_APP_FB_PIXEL_CODE, options);
  ReactPixel.pageView();
}

export default class SignupForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      username: "",
      showModal: false,
      referralCode: "",
      usingReferralCode: this.props.usingReferralCode == true,
      submitted: false,
      allowReferral: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handleReferralCode = this.handleReferralCode.bind(this);
    this.handleUsername = this.handleUsername.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.cleanEmail = this.cleanEmail.bind(this);
    this.cleanReferralCode = this.cleanReferralCode.bind(this);
    this.redirectUser = this.redirectUser.bind(this);
    //this.renderRedirectModal = this.renderRedirectModal.bind(this);
    this.closeModalAndRedirect = this.closeModalAndRedirect.bind(this);
    this.setShowModal = this.setShowModal.bind(this);
    this.hideShowModal = this.hideShowModal.bind(this);
    this.generateUsernameFromEmail = this.generateUsernameFromEmail.bind(this);
    this.redirectUser = this.redirectUser.bind(this);
  }
  componentDidMount() {
    const query = new URLSearchParams(window.location.search);
    const invite_code = query.get("code");
    this.setState({ referralCode: invite_code });
  }
  setShowModal() {
    //this.setState({showModal: true})
  }
  hideShowModal() {
    this.setState({ showModal: false });
  }
  closeModalAndRedirect(path) {
    this.hideShowModal();
    //TODO: Test/check: may not even need to close modal
    //this.redirectUser(path);
  }

  handleSubmit(e, ctx) {
    if (this.state.submitted) {
      e.preventDefault();
      return;
    }
    e.preventDefault();
    // if redirected to signup from payments, then redirect
    // back to payments page after signup, if homepage, then
    // to select lang jump link. Anything else goes to select
    // lang jump link
    const SELECT_LANG_JUMP_LINK = "/#choose_language_jump_link";
    let from_data_available = this.props.location && this.props.location.state;
    const router_state = from_data_available ? this.props.location.state : null;
    let REDIRECT_TO = from_data_available
      ? router_state.from
      : SELECT_LANG_JUMP_LINK;
    let data = this.state;
    data.usingReferralCode = this.state.usingReferralCode && !!this.state.referralCode && (this.state.referralCode.length > 0);
    ctx.signup(e, this.state, REDIRECT_TO).then((ok) => {
      if (!ok) {
        this.setState({ submitted: false });
      }
    });
    this.setState({ submitted: true });
  }
  redirectUser(path) {
    this.props.history.push(path);
  }
  validateEmail(email) {
    // if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)){
    // 	return true
    // }
    // alert("You have entered an invalid email address!")
    // return false
  }
  cleanEmail(email) {
    return email.toLowerCase();
  }
  cleanReferralCode(code) {
    return code.toUpperCase();
  }
  handleReferralCode(event) {
    var my_code = event.target.value;
    my_code = this.cleanReferralCode(my_code);
    this.setState({ referralCode: my_code });
  }
  handleEmail(event) {
    //handle email only
    var my_email = event.target.value;
    //validateEmail
    var valid = this.validateEmail();
    if (!valid) {
      // this.setState({email: ""});
      // return;
    }
    my_email = this.cleanEmail(my_email);
    this.setState({ email: my_email });
    var my_username = this.generateUsernameFromEmail(my_email);
    this.setState({ username: my_username });
  }
  generateUsernameFromEmail(my_email) {
    var name = my_email.substring(0, my_email.lastIndexOf("@"));
    var domain = my_email.substring(my_email.lastIndexOf("@") + 1);
    var timestamp = Date.now(); // This would be the timestamp you want to format
    var timestring = timestamp.toString(); // new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(timestamp);
    var timestrlen = timestring.length;
    if (name.length < 1) {
      name = "user";
    }
    var my_username = name + timestring.substring(timestrlen - 4, timestrlen);
    return my_username;
  }

  handleUsername(event) {
    this.setState({ username: event.target.value });
  }

  handlePassword(event) {
    this.setState({ password: event.target.value });
  }

  render() {
    const successRedirect = "/select_course";
    return (
      <UserConsumer>
        {(glb_ctx) => (
          <div>
            <div>
              <div>
                <head>
                  <title>
                    Sign Up: Learn African Languages. Learn Twi, Yoruba,
                    Swahili, Igbo, Ga, Fante and more"
                  </title>
                  <meta charset="UTF-8" />
                  <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1"
                  />
                  <meta
                    name="description"
                    content="Dialogue Africa provides an online platform and application to learn African languages and connect to culture. Learn Twi, Fante, Ga, Swahili, Yoruba and more"
                  />
                  <link
                    rel="icon"
                    type="image/png"
                    href="./images/icons/favicon.ico"
                  />
                  <link
                    rel="stylesheet"
                    type="text/css"
                    href="vendor/bootstrap/css/bootstrap.min.css"
                  />
                  <link
                    rel="stylesheet"
                    type="text/css"
                    href="fonts/font-awesome-4.7.0/css/font-awesome.min.css"
                  />
                  <link
                    rel="stylesheet"
                    type="text/css"
                    href="fonts/iconic/css/material-design-iconic-font.min.css"
                  />
                  <link
                    rel="stylesheet"
                    type="text/css"
                    href="vendor/animate/animate.css"
                  />
                  <link
                    rel="stylesheet"
                    type="text/css"
                    href="vendor/css-hamburgers/hamburgers.min.css"
                  />
                  <link
                    rel="stylesheet"
                    type="text/css"
                    href="vendor/animsition/css/animsition.min.css"
                  />
                  <link
                    rel="stylesheet"
                    type="text/css"
                    href="vendor/select2/select2.min.css"
                  />
                  <link
                    rel="stylesheet"
                    type="text/css"
                    href="vendor/daterangepicker/daterangepicker.css"
                  />
                  <link rel="stylesheet" type="text/css" href="css/util.css" />
                  <link rel="stylesheet" type="text/css" href="css/main.css" />

                  <link
                    rel="stylesheet"
                    href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css"
                    integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm"
                    crossorigin="anonymous"
                  />
                  <link
                    rel="stylesheet"
                    href="../../styleshannon1.css"
                    type="text/css"
                  />
                  <link
                    href="https://fonts.googleapis.com/css?family=Open+Sans|Raleway"
                    rel="stylesheet"
                  />
                  <link
                    href="https://fonts.googleapis.com/css?family=Montserrat"
                    rel="stylesheet"
                  />
                  <link
                    rel="stylesheet"
                    type="text/css"
                    href="//fonts.googleapis.com/css?family=Varela+Round"
                  />
                </head>

                <nav class="navbar fixed-top navbar-expand-lg navbar-light bg-light">
                  <Link class="navbar-brand" to="/">
                    <img
                      class="favicon dialoguelogobar"
                      alt="Brand"
                      src="./Images/da-logo-lineart.png"
                    />
                  </Link>
                  <button
                    class="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarTogglerDemo02"
                    aria-controls="navbarTogglerDemo02"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span class="navbar-toggler-icon"></span>
                  </button>

                  <div
                    class="collapse navbar-collapse"
                    id="navbarTogglerDemo02"
                  >
                    <ul class="navbar-nav ml-auto">
                      <li class="nav-item active">
                        {!glb_ctx.isLoggedIn ? (
                          <Link class="fontB fontregular nav-link" to="/login">
                            &nbsp;&nbsp;
                            <span class="fa fa-sign-in-alt" aria-hidden="true">
                              &nbsp;
                            </span>
                            Login &nbsp;
                          </Link>
                        ) : (
                          <span class="fontB fontregular nav-link" href="#">
                            &nbsp;&nbsp;
                            <span class="fa fa-user-circle">
                              &nbsp;
                            </span> Hi {glb_ctx.get_first_name_or_username()}
                            &nbsp;
                          </span>
                        )}
                      </li>
                      <li class="nav-item active">
                        <a
                          class="fontB fontregular nav-link"
                          href="/blog-main.html"
                        >
                          <span class="fa fa-book"></span> Blog &nbsp;
                        </a>
                      </li>
                      <li class="nav-item active">
                        <a
                          class="fontB fontregular btn btn-outline-info my-2 my-sm-0"
                          href="https://www.bit.ly/dlga-crowdsource"
                        >
                          <span class="fa fa-hands-helping">&nbsp;</span>{" "}
                          Crowdsource
                        </a>
                      </li>
                      {glb_ctx.isLoggedIn ? (
                        <li class="nav-item active">
                          <Link
                            class="fontB fontregular nav-link"
                            to="/select_course"
                          >
                            <span class="fa fa-user"></span> Select Course
                          </Link>
                        </li>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </div>
                </nav>

                <div>
                  <div class="limiter">
                    <div
                      class="container-login100"
                      style={{ backgroundImage: "url(" + background + ")" }}
                      id="signupfont"
                    >
                      <div class="wrap-login100 p-l-55 p-r-55 p-t-65 p-b-54">
                        <form
                          class="login100-form validate-form"
                          onSubmit={(e) => {
                            if (!this.state.submitted) {
                              this.handleSubmit(e, glb_ctx);
                            }
                          }}
                        >
                          <span class="login100-form-title p-b-54">
                            {!this.state.usingReferralCode
                              ? "Join Us !"
                              : "Quick Sign Up"}
                          </span>
                          {/* <hr id="social-hr" />
                          <div class="social-login-buttons">
                            <Button circular color="facebook" icon="facebook" />
                            <Button
                              circular
                              color="google plus"
                              icon="google plus"
                            />
                          </div>
                          <hr id="or-hr" /> */}

                          <div
                            class="wrap-input100 validate-input m-b-23"
                            data-validate="Email is required"
                          >
                            <i class="far fa-envelope purple"> </i>{" "}
                            <span class="label-input100">E-mail</span>
                            <input
                              id="email-input"
                              class="input100"
                              type="text"
                              name="email"
                              placeholder="Enter e-mail"
                              value={this.state.email}
                              onChange={this.handleEmail}
                            />
                          </div>
                          <div
                            class="wrap-input100 validate-input m-b-23"
                            data-validate="Password is required"
                          >
                            <i class="fas fa-unlock-alt nicegreen"></i>
                            <span class="label-input100">
                              Create Password [6+ characters]
                            </span>
                            <input
                              id="password-input"
                              class="input100"
                              type="password"
                              name="pass"
                              placeholder="Enter password"
                              pattern="^(.*).{6,}$"
                              title="must be 6+ characters"
                              value={this.state.password}
                              onChange={this.handlePassword}
                            />
                          </div>
                          {this.state.usingReferralCode || this.state.allowReferral ? (
                            <div
                              class="wrap-input100 validate-input m-b-23"
                              data-validate="Invite code is required"
                            >
                              <i class="fas fa-plug"></i>{" "}
                              <span class="label-input100">Invite Code</span>
                              <input
                                id="email-input"
                                class="input100"
                                type="text"
                                name="email"
                                placeholder="Enter code (optional)"
                                value={this.state.referralCode}
                                onChange={this.handleReferralCode}
                              />
                            </div>
                          ) : (
                            <></>
                          )}
                          <div class="text-right p-t-0 p-b-31">
                            <Link to="/retrieve_password">
                              <span>Forgot password?</span>
                            </Link>
                          </div>
                          <div class="container-login100-form-btn">
                            <div class="wrap-login100-form-btn">
                              <div class="login100-form-bgbtn"></div>
                              <button
                                class="login100-form-btn"
                                disabled={this.state.submitted}
                              >
                                {/* <span>Get Free Access</span> */}
                                {/* <span>Free / 1 - click sign-up </span> */}
                                <span>
                                  {" "}
                                  {this.state.usingReferralCode &&
                                  this.state.submitted ? (
                                    <span>
                                      {" "}
                                      <i class="fas fa-fan fa-spin"></i>{" "}
                                      Processing ...
                                    </span>
                                  ) : (
                                    "Join !"
                                  )}{" "}
                                </span>
                              </button>
                            </div>
                          </div>
                        </form>
                        {/* <div class="txt1 text-center p-t-34 p-b-20">
                          <hr id="social-hr2" />
                        </div>

                        <div class="flex-c-m">
                          <a href="#" class="login100-social-item bg1">
                            <i class="fa fa-facebook"></i>
                          </a>

                          <a href="#" class="login100-social-item bg2">
                            <i class="fa fa-twitter"></i>
                          </a>

                          <a href="#" class="login100-social-item bg3">
                            <i class="fa fa-google"></i>
                          </a>
                        </div> */}
                        <br />
                        <div class="flex-col-c">
                          <span class="txt1 p-b-17">
                            Already have an account?{" "}
                            <Link to="/login">
                              <span>Login</span>
                            </Link>
                          </span>
                          {/*
												<div>
														<RenderRedirectModal show = {this.state.showModal}
																								 path = {successRedirect}
																								 hideFunc = {this.closeModalAndRedirect}
																								 showFunc = {this.setShowModal}
														/>
												</div>
												*/}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="dropDownSelect1"></div>

                <script src="vendor/jquery/jquery-3.2.1.min.js"></script>
                <script src="vendor/animsition/js/animsition.min.js"></script>
                <script src="vendor/bootstrap/js/popper.js"></script>
                <script src="vendor/bootstrap/js/bootstrap.min.js"></script>
                <script src="vendor/select2/select2.min.js"></script>
                <script src="vendor/daterangepicker/moment.min.js"></script>
                <script src="vendor/daterangepicker/daterangepicker.js"></script>
                <script src="vendor/countdowntime/countdowntime.js"></script>
                <script src="js/main.js"></script>
              </div>
            </div>
          </div>
        )}
      </UserConsumer>
    );
  }
}
