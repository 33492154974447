import React from "react";
import { UserConsumer } from "../User/User.js";
import { Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
class DeleteUser extends React.Component {
  delete_request = (ctx) => {
    this.delete_curr_user(ctx).then((ok) => {
      if (ok) {
        ctx.logout();
      } else {
        alert("User deletion failed!");
      }
    });
  };
  delete_curr_user = (ctx) => {
    const path = "account_user_delete/";
    const data = {};
    return ctx.postUsingToken(path, data);
  };
  navBar = () => {
    return  <nav class="navbar fixed-top navbar-expand-lg navbar-light bg-light">
    <Link class="navbar-brand" to="/">
      <img
        class="favicon dialoguelogobar"
        alt="Brand"
        src="./Images/da-logo-lineart.png"
      />
    </Link>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarTogglerDemo02"
      aria-controls="navbarTogglerDemo02"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div
      class="collapse navbar-collapse"
      id="navbarTogglerDemo02"
    >
      <ul class="navbar-nav ml-auto">
        <li class="nav-item active">
          <Link class="nav-link" to="/">
            <span class="fa fa-home"></span> Home
            <span class="sr-only">(current)</span>
          </Link>
        </li>
        <li class="nav-item active">
          <a class="nav-link" href="/blog-main.html">
            <span class="fa fa-book"></span> Blog
          </a>
        </li>
      </ul>
    </div>
  </nav>
  }
  render() {
    return (
      <UserConsumer>
        {(glb_ctx) => {
          return (

            <div class = "fontB" style={{marginTop:75, marginLeft:25, marginRight:25, fontSize:18}}>
              {this.navBar()}
              <br></br>
              <div > To delete account on the web, log in first, then use the button on this page to delete account.</div>
              <br></br>
              <div>This action cannot be undone. This will permanently deactivate your account and erase all your personal data stored by Dialogue Africa.</div>
              <br></br>
              <div>This action will not cancel existing subscriptions. Please do so on the App Store, Play Store or contact us for support.</div>
              <br></br>
              <div> Please e-mail info@dialogue-africa.com with any questions / concerns.</div>
              <br></br>
              <div>✌🏾 Thank you !</div>
            <br></br>
            <br></br>
            <Button
              id="user-delete"
              onClick={() => {
                this.delete_request(glb_ctx);
              }}
              basic
              color="red"
              size="large"
            >
              Delete account: {glb_ctx.user}
            </Button>
            </div>
          );
        }}
      </UserConsumer>
    );
  }
}
export default DeleteUser;
