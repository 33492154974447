import React, { Component } from "react";
import "./Welcomeculture.css";
import { Link } from "react-router-dom";
import { UserConsumer } from "./components/User/User.js";
import basic_grammar_image from "./components/app_content/navigation_pages_content/images/today.png";
import ohSound from "./components/app_content/greetings_welcome_content/audio/oh.wav";
import ehSound from "./components/app_content/greetings_welcome_content/audio/eh.wav";
import twiAlphabetSound from "./components/app_content/greetings_welcome_content/audio/twi_alphabet.wav";
import dialogue_twi_logo from "./Images/dialogue-africa-ghana-twi-logo.png";
class Welcomeculture extends Component {
  constructor(props) {
    super(props);
    this.myaudio = new Audio();
    this.playOhSound = this.playOhSound.bind(this);
    this.playEhSound = this.playEhSound.bind(this);
    this.playNativeAlphabet = this.playNativeAlphabet.bind(this);
    this.stopAudio = this.stopAudio.bind(this);
    this.getProverbPointCount = this.getProverbPointCount.bind(this);
    this.fetchUserProfileDetails = this.fetchUserProfileDetails.bind(this);
    this.fetchProverbPointCount = this.fetchProverbPointCount.bind(this);
    this.state = {
      proverb_point_count: 0,
    };
  }
  componentDidMount() {
    //https://stackoverflow.com/questions/28241912/bootstrap-modal-in-react-js
    //check out last but 1 answer
    // $(this.getDOMNode()).modal('show');
    this.fetchUserProfileDetails();
  }
  fetchUserProfileDetails() {
    var pp = this.fetchProverbPointCount();
    this.setState({
      proverb_point_count: pp,
    });
  }
  proverbCount() {
    var path = "proverb_point_count/";
    let value = this.props.contextapivalue;
    return value.getUsingToken(path);
  }
  fetchProverbPointCount() {
    this.proverbCount()
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(response.status);
        }
        response.json().then((proverb_points) => {
          this.setState({ proverb_point_count: proverb_points });
        });
      })
      .catch((error) => {
        console.error("error retrieving proverb points");
      });
  }
  getProverbPointCount() {
    return this.state.proverb_point_count;
  }
  playOhSound() {
    this.myaudio.src = ohSound;
    var promise = this.myaudio.play();
    if (promise !== undefined) {
      promise
        .catch((error) => {
          // Auto-play was prevented
          // Show a UI element to let the user manually start playback
        })
        .then(() => {
          // Auto-play started
        });
    }
  }
  playEhSound() {
    this.myaudio.src = ehSound;
    var promise = this.myaudio.play();
    if (promise !== undefined) {
      promise
        .catch((error) => {
          // Auto-play was prevented
          // Show a UI element to let the user manually start playback
        })
        .then(() => {
          // Auto-play started
        });
    }
  }
  stopAudio() {
    this.myaudio.src = ehSound;
    var promise = this.myaudio.pause();
    if (promise !== undefined) {
      promise
        .catch((error) => {
          // Auto-play was prevented
          // Show a UI element to let the user manually start playback
        })
        .then(() => {
          // Auto-play started
        });
    }
    this.myaudio.currentTime = 0;
  }
  playNativeAlphabet() {
    this.myaudio.src = twiAlphabetSound;
    var promise = this.myaudio.play();
    if (promise !== undefined) {
      promise
        .catch((error) => {
          // Auto-play was prevented
          // Show a UI element to let the user manually start playback
        })
        .then(() => {
          // Auto-play started
        });
    }
  }
  render() {
    return (
      <UserConsumer>
        {(value) => (
          <div>
            <head>
              <meta charset="utf-8" />
              <meta http-equiv="X-UA-Compatible" content="IE=edge" />
              <meta
                name="viewport"
                content="width=device-width,initial-scale=1,shrink-to-fit=no"
              />
              <meta
                name="description"
                content="Dialogue-Africa is an online platform for learning African languages including Twi, Fante, Ga, Hausa, Yoruba and More "
              />
              <title>
                {" "}
                Dialogue-Africa: Learn African Languages like Twi, Fante, Ga,
                Igbo, Hausa, Yoruba, Shona, Amharic and More{" "}
              </title>

              <link
                rel="stylesheet"
                href="https://stackpath.bootstrapcdn.com/bootstrap/4.2.1/css/bootstrap.min.css"
                integrity="sha384-GJzZqFGwb1QTTN6wy59ffF1BuGJpLSa9DkKMp0DgiMDm4iYMj70gZWKYbI706tWS"
                crossorigin="anonymous"
              />
              <script type="text/javascript"></script>
              <script
                src="https://stackpath.bootstrapcdn.com/bootstrap/4.2.1/js/bootstrap.min.js"
                integrity="sha384-B0UglyR+jN6CkvvICOB2joaf5I4l3gm9GU6Hc1og6Ls7i6U/mkkaduKaBhlAXv9k"
                crossorigin="anonymous"
              ></script>
              <link
                rel="stylesheet"
                href="./styleshannon1.css"
                type="text/css"
              />
              <link
                href="https://fonts.googleapis.com/css?family=Open+Sans|Raleway"
                rel="stylesheet"
              />
              <link
                href="https://fonts.googleapis.com/css?family=Montserrat"
                rel="stylesheet"
              />
              <link
                rel="stylesheet"
                type="text/css"
                href="//fonts.googleapis.com/css?family=Varela+Round"
              />
            </head>
            <nav class="navbar fixed-top navbar-expand-lg navbar-light bg-light">
              <Link class="navbar-brand" to="/select_course">
                <img
                  class="favicon dialoguelogobar"
                  alt="Brand"
                  src={dialogue_twi_logo}
                />
              </Link>
              <button
                class="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-backdrop="static"
                data-target="#navbarTogglerDemo02"
                aria-controls="navbarTogglerDemo02"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="navbar-toggler-icon"></span>
              </button>

              <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
                <ul class="navbar-nav ml-auto">
                  <li class="nav-item active">
                    <p id="provcount">
                      <i id="seedlinglarge" class="fas fa-seedling"></i>x{" "}
                      {this.getProverbPointCount()}{" "}
                    </p>{" "}
                    <span class="sr-only">(current)</span>
                  </li>
                  <li class="nav-item active">
                    {value.isGuest ? (
                      <Link
                        class="btn btn-success my-2 my-sm-0"
                        to="/create_profile"
                      >
                        <span class="fa fa-user-plus"></span> Create Profile{" "}
                        <span class="sr-only">(current)</span>
                      </Link>
                    ) : (
                      <a class="btn btn-outline-success my-2 my-sm-0" href="#">
                        <span class="fa fa-user-circle"></span> Hi{" "}
                        {value.isLoggedIn
                          ? value.get_first_name_or_username()
                          : "Guest"}{" "}
                        <span class="sr-only">(current)</span>
                      </a>
                    )}
                  </li>
                  <li class="nav-item active">
                    <a class="nav-link" href="blog-main.html">
                      <span class="fa fa-book"></span> Blog{" "}
                      <span class="sr-only"> (current) </span>{" "}
                    </a>
                  </li>
                  <li class="nav-item active">
                    <a
                      class="btn btn-outline-info my-2 my-sm-0"
                      href={
                        "https://brown.co1.qualtrics.com/jfe/form/SV_cOMTf4CUsSf5iyp"
                      }
                    >
                      <span class="fa fa-comment"></span> Talk to us!{" "}
                      <span class="sr-only"> (current) </span>{" "}
                    </a>
                  </li>
                  <li class="nav-item active">
                    <Link class="nav-link " to="/select_course">
                      <span class="fa fa-th-list"></span> Select Course
                    </Link>
                  </li>
                  <li id="dialog-confirm" class="nav-item active">
                    <a
                      className="delete-button"
                      onClick={(e) => {
                        if (
                          window.confirm(
                            "You can save your guest progress first. Still want to logout?"
                          )
                        )
                          value.logout();
                      }}
                      class="nav-link"
                      href="javascript:void(0);"
                    >
                      <span class="fa fa-sign-out"></span> Logout
                      <span class="sr-only">(current)</span>
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
            <br />
            <br />
            <br />
            {/*<!-- Modal -->*/}
            <div
              class="modal fade"
              id="exampleModalLong"
              tabindex="-1"
              role="dialog"
              data-show="true"
              aria-labelledby="exampleModalLongTitle"
              aria-hidden="true"
            >
              <div
                class="modal-dialog modal-dialog-centered modal-lg"
                role="document"
              >
                <div class="modal-content">
                  <div class="modal-header">
                    <h1 id="notessubheading">
                      {" "}
                      <i class="fas fa-crosshairs fa-spin"></i> LESSON NOTES{" "}
                    </h1>
                  </div>
                  <div class="modal-body">
                    <div id="lessonnotes">
                      <h3 id="notessubheading">
                        Grammar:
                        {/* Subject + Verb + Object */}{" "}
                        <span id="word-color1">Subject</span> +{" "}
                        <span id="word-color2b">Verb</span> +{" "}
                        <span id="word-color3">Object</span>
                      </h3>
                      <p id="goals2">
                        Here are a few more examples of the{" "}
                        <span id="word-color1">subject</span> +{" "}
                        <span id="word-color2b">verb</span> +{" "}
                        <span id="word-color3">object</span> structure in Twi -
                        similar to basic grammar rules used in English.
                      </p>
                      <p id="goals2">
                        1. <span id="word-color1">I</span>{" "}
                        <span id="word-color2b">am</span>{" "}
                        <span id="word-color3">a man</span>{" "}
                        <i
                          id="myarrownocolor"
                          class="fa fa-long-arrow-right"
                        ></i>{" "}
                        <span id="word-color1">Me</span>{" "}
                        <span id="word-color2b">yɛ</span>{" "}
                        <span id="word-color3">bɛɛma</span>{" "}
                        {/* <i
                          id="myarrownocolor"
                          class="fa fa-long-arrow-right"
                        ></i>{" "}
                        <span id="word-color1">subject</span> +{" "}
                        <span id="word-color2b">verb</span> +{" "}
                        <span id="word-color3">object</span> */}
                      </p>{" "}
                      <p id="goals2">
                        {" "}
                        {/* In Ghanaian culture,  */}
                        2. <span id="word-color1">It</span>
                        <span id="word-color2b">'s</span>{" "}
                        <span id="word-color3">today</span>{" "}
                        <i
                          id="myarrownocolor"
                          class="fa fa-long-arrow-right"
                        ></i>{" "}
                        <span id="word-color1">Ɛ</span>
                        <span id="word-color2b">yɛ</span>{" "}
                        <span id="word-color3">ɛnɛ</span>{" "}
                        {/* <i
                          id="myarrownocolor"
                          class="fa fa-long-arrow-right"
                        ></i>{" "}
                        <span id="word-color1">subject</span> +{" "}
                        <span id="word-color2b">verb</span> +{" "}
                        <span id="word-color3">object</span> */}
                        {/* {" "}
                        </b>{" "} */}
                        {/* from , - since the left-hand is often considered
                        unsanitary / designated for use in the bathroom. fa fa-long-arrow-left*/}
                      </p>
                      <h3 id="notessubheading">No Subject-Verb Agreement</h3>
                      <p id="goals2">
                        In English there is subject-verb agreement - you have to
                        change the verb to fit the subject (e.g. I{" "}
                        <span id="word-color2b">am</span> vs You{" "}
                        <span id="word-color2b">are</span>).{" "}
                      </p>
                      <p id="goals2">
                        In Twi, you don't have to do this (e.g. Me{" "}
                        <span id="word-color2b">yɛ</span> vs Wo{" "}
                        <span id="word-color2b">yɛ</span>)
                      </p>
                      <h3 id="notessubheading">
                        Form Sentences like I am ... & It is ...
                      </h3>
                      <p id="goals2">
                        Use the following as building blocks to form sentences
                        that describe the state of a person, a thing or a
                        situation.
                      </p>
                      <p id="goals2">
                        <span id="word-color1">I</span>{" "}
                        <span id="word-color2b">am</span> <span>...</span>{" "}
                        <i
                          id="myarrownocolor"
                          class="fa fa-long-arrow-right"
                        ></i>{" "}
                        <span id="word-color1">Me</span>{" "}
                        <span id="word-color2b">yɛ</span> <span>...</span>{" "}
                      </p>
                      <p id="goals2">
                        <span id="word-color1">You</span>{" "}
                        <span id="word-color2b">are</span> <span>...</span>{" "}
                        <i
                          id="myarrownocolor"
                          class="fa fa-long-arrow-right"
                        ></i>{" "}
                        <span id="word-color1">Wo</span>{" "}
                        <span id="word-color2b">yɛ</span> <span>...</span>{" "}
                      </p>
                      <p id="goals2">
                        <span id="word-color1">He/She</span>{" "}
                        <span id="word-color2b">is</span> <span>...</span>{" "}
                        <i
                          id="myarrownocolor"
                          class="fa fa-long-arrow-right"
                        ></i>{" "}
                        <span id="word-color1">Ɔ</span>
                        <span id="word-color2b">yɛ</span> <span>...</span>{" "}
                      </p>
                      <p id="goals2">
                        <span id="word-color1">It</span>{" "}
                        <span id="word-color2b">is</span> <span>...</span>{" "}
                        <i
                          id="myarrownocolor"
                          class="fa fa-long-arrow-right"
                        ></i>{" "}
                        <span id="word-color1">Ɛ</span>
                        <span id="word-color2b">yɛ</span> <span>...</span>{" "}
                      </p>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button
                      onClick={this.stopAudio}
                      type="button"
                      data-dismiss="modal"
                      id="closetips"
                    >
                      <p>
                        <i class="fas fa-door-open"></i> Close{" "}
                      </p>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <h2 id="heading">Kasa Mmara | Basic Grammar</h2>

            <section className="flexbox-container">
              <div id="leftcard">
                <div id="gc1a"></div>
                <div id="leftcardbase">
                  <div className="greetingscard1b">
                    <Link id="nodecor" to="/basics-1-blended-quiz">
                      <button
                        id="enterlesson"
                        class="btn btn-outline-orange my-2 my-sm-0"
                      >
                        <p id="entersentence">
                          <span
                            class="fa fa-play-circle"
                            aria-hidden="true"
                          ></span>
                          Ma Yensua Nkyea
                        </p>
                      </button>
                    </Link>
                    <img src={basic_grammar_image} />
                  </div>
                </div>
              </div>

              <div id="rightcard">
                <div id="greetingscard2ablue" className="greetingscard2a"></div>
                <div className="greetingscard2b">
                  <div className="playandimage">
                    <img src={basic_grammar_image} />
                    <Link id="nodecor2" to="/basics-1-blended-quiz">
                      <button
                        id="highenterlessonblue"
                        class="btn btn-outline-orange my-2 my-sm-0 highenterlesson"
                      >
                        <p id="entersentence">
                          <span
                            class="fa fa-play-circle"
                            aria-hidden="true"
                          ></span>{" "}
                          Learn Basic Grammar
                        </p>
                      </button>
                    </Link>
                  </div>
                  <div id="descriptionTips">
                    <h1 id="subheadingA"> Lesson Description</h1>
                  </div>
                  <hr id="hridblue" class="hrid" />
                  <p id="goals">
                    <span class="fas fa-chevron-rightd"></span>In this lesson
                    you will learn how to use simple grammar rules and basic
                    vocab to form and understand casual statements.
                  </p>{" "}
                  <button
                    type="button"
                    id="entertips"
                    data-toggle="modal"
                    data-target="#exampleModalLong"
                    data-backdrop="static"
                  >
                    <p>
                      {" "}
                      <i class="fas fa-crosshairs fa-spin"></i> Lesson Notes
                    </p>
                  </button>
                  <h1 id="subheading">
                    {" "}
                    After completing this short quiz, you'll be able to{" "}
                  </h1>
                  <ul id="goals">
                    <li>
                      <span class="fas fa-chevron-rightd"></span>Use 'yes' and
                      'no' with casual statements.
                    </li>
                    <li>
                      <span class="fa fa-chevron-rightd"></span>Talk about today
                      and tomorrow.
                    </li>
                    <li>
                      <span class="fa fa-chevron-rightd"></span>Understand how
                      simple sentences are formed using basic vocab and grammar
                      rules.
                    </li>

                    <li>
                      <span class="fa fa-chevron-rightd"></span>Get comfortable
                      with the flow of pauses and punctuations in casual
                      conversation.
                    </li>
                  </ul>
                </div>
              </div>
            </section>
          </div>
        )}
      </UserConsumer>
    );
  }
}

export default Welcomeculture;
