import React from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { FullStoryAPI } from "react-fullstory";

const BCKND_PIPELINE_STAGE = process.env.REACT_APP_DEV_PIPELINE_STAGE;
const API_VERSION = 1.2;
//Create a context for the signed in user
export const UserContext = withRouter(
  React.createContext({
    isLoggedIn: false,
    user: "Guest",
    firstName: null,
    isGuest: false,
    hasPremiumAccess: null,
    token: "",
    backend_pipeline_stage: BCKND_PIPELINE_STAGE,
    apiVersion: API_VERSION,
    logout: () => {},
    login: () => {},
    login_redirect: () => {},
    signup: () => {},
    convert_using_token: () => {},
    update_using_token: () => {},
    guestlogin: () => {},
    retrievepassword: () => {},
    user_profile_details: () => {},
    getUsingToken: () => {},
    postUsingToken: () => {},
    postUsingCustomHeaders: () => {},
    post: () => {},
    get: () => {},
    select_course_route: () => {},
    recent_select_course_redirect: () => {},
    set_most_recent_language: () => {},
    reset_state_and_local_storage: () => {},
    checkout_session_redirect: () => {},
    fetch_premium_expiry: () => {},
    get_first_name_or_username: () => {},
    increment_paywall_hits: () => {},
    redirect: () => {},
  })
);

// Consumer to allow for components to access Context state
export const UserConsumer = withRouter(UserContext.Consumer);

// Provider class component that renders its children within
// a context provider filled with defined member states and
// functions
class UserProviderKernel extends React.Component {
  // Current React Context API storage system depends on localStorage
  state = {
    isLoggedIn:
      localStorage.getItem("isLoggedIn") !== null &&
      localStorage.getItem("isLoggedIn") === "true"
        ? true
        : false,
    firstName:
      localStorage.getItem("firstName") !== null
        ? localStorage.getItem("firstName")
        : null,
    user:
      localStorage.getItem("username") != null
        ? localStorage.getItem("username")
        : "guest",
    isGuest:
      localStorage.getItem("gueststatus") !== null &&
      localStorage.getItem("gueststatus") === "true"
        ? true
        : false,
    token:
      localStorage.getItem("token") !== null
        ? localStorage.getItem("token")
        : "",
    backend_pipeline_stage: BCKND_PIPELINE_STAGE,
    apiVersion: API_VERSION,
    hasPremiumAccess:
      localStorage.getItem("accessStatus") !== null &&
      localStorage.getItem("accessStatus") == "true"
        ? true
        : null,
  };
  reset_state_and_local_storage = async () => {
    localStorage.removeItem("token");
    localStorage.setItem("username", "guest");
    localStorage.setItem("isLoggedIn", false);
    localStorage.setItem("gueststatus", false);
    localStorage.removeItem("accessStatus");
    localStorage.removeItem("firstName");
    this.setState({
      user: "Guest",
      isGuest: false,
      token: "",
      isLoggedIn: false,
      hasPremiumAccess: false,
      firstName: null,
    });
  };
  logout = async () => {
    // Send token to get session
    var path = "logout/";
    await this.getUsingToken(path);
    await this.reset_state_and_local_storage();
    this.props.history.push("/login");
  };

  convert_using_token = (e, data) => {
    e.preventDefault();
    var path = "converter/";
    const headers = {
      Authorization: `JWT ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    };
    return this.postUsingCustomHeaders(path, data, headers)
      .then((res) => {
        return res;
      })
      .catch(function (error) {
        console.error(error);
        alert("Username, email, or password was invalid.");
      });
  };
  update_using_token = async (e, data) => {
    e.preventDefault();
    var path = "profile_updater/";
    const headers = {
      Authorization: `JWT ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    };
    let response = await this.postUsingCustomHeaders(path, data, headers);
    // console.log("nn response..:", response);
    if (!response.ok) {
      alert("Try again. Username already taken."); //or email
    }
    let json = await response.json();
    // console.log("nn json..:", json);
    this.setState(
      {
        user: json.username,
        isLoggedIn: true,
        isGuest: false,
        token: json.token,
        firstName: json.first_name,
      },
      () => {
        localStorage.setItem("token", this.state.token);
        localStorage.setItem("username", this.state.user);
        localStorage.setItem("isLoggedIn", this.state.isLoggedIn);
        localStorage.setItem("gueststatus", this.state.isGuest);
        localStorage.setItem("firstName", this.state.firstName);
      }
    );
    this.recent_select_course_redirect();
    return response;
  };
  signup = async (e, data, redirect) => {
    var path = "users/";

    let response = await this.post(path, data);
    let json = await response.json();

    // Handle signup errors
    if (!response.ok) {
      let msg_array = json.email;
      if (msg_array != null) {
        let msg = msg_array[0];
        alert(`E-mail issue: ${msg}`);
      } else {
        alert(`Signup issue: ${json}`);
      }
      return false;
    }

    // Set user state and local storage on success
    this.setState(
      {
        user: json.user_data.username,
        isLoggedIn: true,
        isGuest: false,
        token: json.user_data.token,
        hasPremiumAccess: false,
      },
      () => {
        localStorage.setItem("token", this.state.token);
        localStorage.setItem("username", this.state.user);
        localStorage.setItem("isLoggedIn", this.state.isLoggedIn);
        localStorage.setItem("gueststatus", this.state.isGuest);
        localStorage.setItem("accessStatus", false);
      }
    );

    // Signup redirect handling
    const signup_type = json.signup_type;
    if (signup_type == "normal" || signup_type == "free_tier_referral") {
      redirect = "/";
    } else if (
      signup_type == "promo_referral" ||
      signup_type == "3-day-errpu" || !isNaN(signup_type)
    ) {
      redirect = "/checkout-redirect";
    }
    // else signup_type == "normal"
    // take redirect from caller/function parameter
    // #TODO: Better fix
    // Currently uses patch to handle anchor redirect
    // since anchor redirect with a dict of multiple
    // kv pairs passed to history.push causes redirect issues

    if (redirect == "/#choose_language_jump_link") {
      this.props.history.push(redirect);
      return true;
    }

    this.props.history.push({
      pathname: redirect,
      state: { signup_type: signup_type },
    });
    return true;
  };
  get_base_url = () => {
    let baseURL = "https://dialogue-rest-api.herokuapp.com/"; // production
    switch (this.state.backend_pipeline_stage) {
      case "development":
        baseURL = "http://localhost:8000/";
        break;
      case "staging":
        baseURL = "https://dialogue-africa.herokuapp.com/";
        break;
    }
    return baseURL;
  };
  redirect = (path) => {
    this.props.history.push(path);
  };
  get_first_name_or_username = () => {
    if (
      this.state.firstName == "" ||
      this.state.firstName == null ||
      this.state.firstName == undefined ||
      // !this.state.firstName ||
      this.state.firstName == "undefined"
    ) {
      return this.state.user;
    } else {
      return this.state.firstName;
    }
  };
  helper_login_and_fetch = (data, redirect) => {
    var path = "token-general-auth/";
    return this.post(path, data)
      .then((res) => {
        if (res.ok) {
          return res.json().then(async (response) => {
            this.setState({
              user: response.user.username,
              isLoggedIn: true,
              isGuest: false,
              token: response.token,
              firstName: response.user.first_name,
            });

            // // FullStory API identify
            // const u_name = response.user.username;
            // let spec_dict = {
            //   benjellica7042: "r9660",
            //   jessicashavers10: "r10426",
            //   akospawa: "t9753",
            //   yawpawa: "t10517",
            //   cristigaldi: "f10758",
            //   nysheaveal1608: "r11428",
            //   mikebed33: "r11163",
            //   aansong: "r11334",
            //   hrhprincessaliya1414: "fr10885",
            //   "allie.reefer8953": "r10983",
            //   letia_green1485: "r9177",
            //   imo5792: "r9876",
            // };
            // if (u_name in spec_dict) {
            //   let userId = spec_dict[u_name];
            //   FullStoryAPI("identify", userId, {
            //     displayName: this.state.user,
            //     // email: 'World'
            //   });
            // }

            localStorage.setItem("token", this.state.token);
            localStorage.setItem("username", this.state.user);
            localStorage.setItem("isLoggedIn", this.state.isLoggedIn);
            localStorage.setItem("gueststatus", this.state.isGuest);
            localStorage.setItem("firstName", this.state.firstName);

            let provisioned = !(await this.fetch_premium_expiry());
            this.setState({
              hasPremiumAccess: provisioned,
            });
            localStorage.setItem("accessStatus", provisioned);
            console.log(`getcomp login hPA: ${this.state.hasPremiumAccess}`);
            console.log(
              `getcomp login localStorage accessStatus: ${localStorage.getItem(
                "accessStatus"
              )}`
            );

            // Redirect to language-specific select course page.
            if (redirect != null) {
              this.props.history.push(redirect);
            }
            return true;
          });
        } else {
          alert("Login failed: Invalid username password combination");
          return false;
        }
      })
      .catch(function (error) {
        alert("Login failed: Invalid username password combination");
        return false;
      });
  };
  login_redirect = (e, data, redirect) => {
    e.preventDefault();
    this.helper_login_and_fetch(data, redirect);
  };
  login = (e, data) => {
    // refactor to make room calls associated with login
    e.preventDefault();
    const redirect = null;
    this.helper_login_and_fetch(data, redirect).then((ok) => {
      if (ok) {
        // redirect to select course page for a particular language
        this.recent_select_course_redirect();
      }
    });
  };
  select_course_route = (language) => {
    if (language == "mòoré") {
      language = "moore";
    }
    let lang_set_2 = new Set(["yoruba", "igbo", "ga", "swahili"]);
    let lang_set_3 = new Set([
      "moore",
      "somali",
      "ewe",
      "bemba",
      "shona",
      "luganda",
    ]);
    // console.log("langs: user.js scr ", language);
    // if (language == "twi") {
    //   return "/select_course";
    //   // return "/#choose_language_jump_link";
    // }
    if (language == "twi") {
      return "/select_course";
    } else if (lang_set_2.has(language)) {
      return `/select-${language}-course`;
    } else if (lang_set_3.has(language)) {
      return `/select_${language}_course`;
    } else {
      // redirect to twi select course if language not found
      return "/select_course";
    }
  };
  get_quiz_association_info = (language) => {
    // var qa_dict = {}
    if (language == null || language == "twi") {
      return TwiQuizAssociationInfo;
    }
    return [];
  };
  next_quiz_name = (language) => {
    return "professions";
  };

  next_quiz_link = (language) => {
    var qa_dict = this.get_quiz_association_info(language);
    let quiz_name = this.next_quiz_name(language);
    let info = qa_dict[quiz_name];
    let url_link = info[0];
    return url_link;
  };

  recent_select_course_redirect = async (e) => {
    if (e) {
      e.preventDefault();
    }
    let mrlanguage = await this.most_recent_language();
    // console.log("langs: mrlanguage: ", mrlanguage);
    // const mrl_redirect_promise = await this.most_recent_language_redirect();
    let provisioned = !(await this.fetch_premium_expiry());
    this.setState({
      hasPremiumAccess: provisioned,
    });
    localStorage.setItem("accessStatus", provisioned);
    console.log(`getcomp recent_select hPA: ${this.state.hasPremiumAccess}`);
    console.log(
      `getcomp recent_select localStorage accessStatus: ${localStorage.getItem(
        "accessStatus"
      )}`
    );
    let mrl_redirect_link = this.select_course_route(mrlanguage);
    // const mrl_redirect_link = mrl_redirect_promise;
    if (mrl_redirect_link != null) {
      await this.props.history.push(
        mrl_redirect_link
        // {
        //   pathname: mrl_redirect_link,
        //   state: { language: mrlanguage },
        // }
      );
    } else {
      this.logout();
    }
  };

  first_view_select_course = async () => {
    const language = await this.most_recent_language();
    // first view of select course since
    // most recent language data not available
    if (language === null) {
      return true;
    }
    return false;
  };
  most_recent_language = async () => {
    const api_path = "most_recent_language/";
    return this.getUsingToken(api_path)
      .then((res) => {
        if (res.ok) {
          return res.json().then((response) => {
            const language = response.most_recent_language;
            return language;
          });
        }
        console.error("login error.. Try again");
        return false;
      })
      .catch((error) => {
        console.error("Error accessing user profile info: Try again");
        return false;
      });
  };
  most_recent_language_redirect = () => {
    const api_path = "most_recent_language/";
    const mrl_path = this.getUsingToken(api_path)
      .then((res) => {
        var res_json_path = null;
        if (res.ok) {
          res_json_path = res.json().then((response) => {
            const language = response.most_recent_language;
            const path = this.select_course_route(language);
            return path;
          });
        } else {
          console.error("login error.. Try again");
        }
        // console.log("langs: user.js mrl_res_json_path: ", mrl_path);
        return res_json_path;
      })
      .catch((error) => {
        console.error("Error accessing user profile info: Try again");
      });
    // console.log("langs: user.js mrl_path: ", mrl_path);
    return mrl_path;
  };
  retrievepassword = (input_data) => {
    var path = "api/pwd_reset/";
    var data = {
      email: input_data.email,
    };
    return this.post(path, data)
      .then((res) => {
        return res;
      })
      .catch(function (error) {
        alert("password reset retrieval failed");
        return false;
      });
  };
  guestlogin = async (e, in_language) => {
    if (e != null) {
      e.preventDefault();
    }
    // TODO: test before turning on. Lesson retrieval issues.
    const SKIP_TWI_SKILL_TREE_ON_FIRST_VIEW = false;
    var baseURL = this.get_base_url();
    var path1 = "guestlogin/";
    var path2 = "current_user/";
    var requesturlGuestLogin = baseURL + path1;
    var requesturlCurrentUser = baseURL + path2;
    var headerInputs = {};
    var finalrequest = "";
    var callguestlogin =
      localStorage.getItem("token") === null ||
      localStorage.getItem("token") === "";
    var calledcurrentuser = !callguestlogin;
    if (callguestlogin) {
      finalrequest = requesturlGuestLogin;
      headerInputs = {
        "Content-Type": "application/json",
      };
    } else {
      //valid token
      finalrequest = requesturlCurrentUser;
      headerInputs = {
        Authorization: `JWT ${localStorage.getItem("token")}`,
      };
    }
    fetch(finalrequest, {
      method: "GET",
      headers: headerInputs,
    })
      .then((res) => {
        if (res.ok) {
          res.json().then(async (response) => {
            this.setState({ user: response.username });
            if (!this.state.isLoggedIn) {
              //handles non-loggedin member users who click guest login
              var name = "Guest"; // + stru
              localStorage.setItem("username", name);
              this.setState({
                isLoggedIn: true,
                isGuest: true,
                token: response.token,
                hasPremiumAccess: false,
              });
              localStorage.setItem("token", this.state.token);
              localStorage.setItem("isLoggedIn", this.state.isLoggedIn);
              localStorage.setItem("gueststatus", this.state.isGuest);
              localStorage.setItem("accessStatus", this.state.hasPremiumAccess);
              console.log(
                `getcomp guestlogin:Guest hPA: ${this.state.hasPremiumAccess}`
              );
              console.log(
                `getcomp guestlogin:Guest localStorage accessStatus: ${localStorage.getItem(
                  "accessStatus"
                )}`
              );
            } else {
              // Handles guests already logged in.
              let provisioned = !(await this.fetch_premium_expiry());
              this.setState({
                hasPremiumAccess: provisioned,
              });
              localStorage.setItem("accessStatus", provisioned);
              console.log(
                `getcomp guestlogin:currentUser hPA: ${this.state.hasPremiumAccess}`
              );
              console.log(
                `getcomp guestlogin:currentUser localStorage accessStatus: ${localStorage.getItem(
                  "accessStatus"
                )}`
              );
            }

            //Redirect to main page
            let redirectPath = this.select_course_route(in_language);

            // if first time user quiz experience
            // and language is twi, jump straight to
            // greetings quiz intro
            // TODO: fix: Currently has to come before set_most_language because first_view depends on most recent langauge call.
            // TODO: Test before turning on. Lesson retrieval issues.
            if (SKIP_TWI_SKILL_TREE_ON_FIRST_VIEW) {
              const first_view = await this.first_view_select_course();
              alert("first view: ", first_view);
              if (first_view) {
                // async call to set most recent language
                this.set_most_recent_language("twi");

                // jump staight into first quiz flow
                redirectPath = "/welcome_greetings";
              }
            }
            // async call to set most recent language
            await this.set_most_recent_language(in_language);

            //accomodate language prop passing with history push
            let stateinfo = {
              pathname: redirectPath,
              state: { language: in_language },
            };
            // console.log("langs: guestlogin push: ", stateinfo);
            this.props.history.push(stateinfo);
          });
        } else {
          //response not okay
          if (calledcurrentuser && this.state.isLoggedIn) {
            //Likely expired token, since loggedIn but current user returned null.
            //Proceed with Expired User
            alert("User session expired. Please log-in");
            this.logout();

            //Or log user out, report with alert and encourage Try Again.
            //Manually logout giving trouble with async logout in retired components
            //Redirect to homepage.
            // this.props.history.push("/");
          } else {
            alert(
              "We're sorry, guest log-in is temporarily unavailable. Try member login or signup."
            );
          }
        }
      })
      .catch(function (error) {
        //Need to display some informative message.
        alert(
          "We're sorry, guest login is temporarily unavailable. Try member login or signup."
        );
      });
  };
  // TODO: test local storage, chained to setState path for correctness.
  user_profile_details = () => {
    var path = "user_profile_data/";
    this.getUsingToken(path)
      .then((res) => {
        if (res.ok) {
          res.json().then((response) => {
            this.setState(
              {
                hasPremiumAccess: response.has_premium_access,
              },
              () => {
                localStorage.setItem(
                  "accessStatus",
                  response.has_premium_access
                );
              }
            );
          });
        } else {
          console.error("Error processing profile details");
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  };
  post = (path_input, data) => {
    const headers = {
      "Content-Type": "application/json",
    };
    return this.postUsingCustomHeaders(path_input, data, headers);
  };
  postUsingToken = (path_input, data) => {
    const headers = {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    };
    return this.postUsingCustomHeaders(path_input, data, headers);
  };
  postUsingCustomHeaders = (path_input, data, headersDict) => {
    var baseURL = this.get_base_url();
    var path = path_input;
    var requesturl = baseURL + path;
    return fetch(requesturl, {
      method: "POST",
      headers: headersDict,
      body: JSON.stringify(data),
    })
      .then((response) => {
        return response;
      })
      .catch((e) => {
        console.error(e);
        alert(
          "Failed to fetch data. Please retry shortly. Contact info@dialogue-africa.com if issue persists."
        );
        this.props.history.go(0);
      });
  };
  get = (path_input) => {
    var baseURL = this.get_base_url();
    var path = path_input;
    var requesturl = baseURL + path;
    return fetch(requesturl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };
  set_most_recent_language = async (language) => {
    // async call to set most recent language
    let api_path = `set_most_recent_language/`;
    const mrl_headers = {
      "Content-Type": "application/json",
      Authorization: `JWT ${localStorage.getItem("token")}`,
    };
    await this.postUsingCustomHeaders(
      api_path,
      {
        language_name: language,
        platform_name: "web",
      },
      mrl_headers
    );
  };
  getUsingToken = (path_input) => {
    var baseURL = this.get_base_url();
    var path = path_input;
    var requesturl = baseURL + path;
    return fetch(requesturl, {
      method: "GET",
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };
  checkout_session_redirect = async (stripe, subscription_period) => {
    let CHECKOUT_SESSION_PATH = `payments/create-checkout-session/`;
    const headers = {
      Authorization: `JWT ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    };
    // increment paywall hits counter
    this.increment_paywall_hits();
    const response = await this.postUsingCustomHeaders(
      CHECKOUT_SESSION_PATH,
      { period: subscription_period },
      headers
    );
    const session = await response.json();
    console.log("stripe checkout session obj: ", session);
    // When the customer clicks on the button, redirect them to Checkout.
    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    });
    if (result.error) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
    }
  };
  fetch_premium_expiry = async () => {
    let path = `premium_access_expiry/`;
    const response = await this.getUsingToken(path);
    const json = await response.json();
    let is_provisioned = !json.access_expired;
    this.setState({
      hasPremiumAccess: is_provisioned,
    });
    localStorage.setItem("accessStatus", is_provisioned);
    // console.log(`getcomp fetch expiry hPA: ${this.state.hasPremiumAccess}`);
    // console.log(
    //   `getcomp fetch expiry localStorage accessStatus: ${localStorage.getItem(
    //     "accessStatus"
    //   )}`
    // );

    return json.access_expired;
  };
  increment_paywall_hits = async () => {
    let path = "increment_paywall_hits/";
    await this.postUsingToken(path, {});
  };

  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };
  render() {
    return (
      <UserContext.Provider
        value={{
          user: this.state.user,
          firstName: this.state.firstName,
          isLoggedIn: this.state.isLoggedIn,
          isGuest: this.state.isGuest,
          hasPremiumAccess: this.state.hasPremiumAccess,
          token: this.state.token,
          devMode: this.state.backend_pipeline_stage,
          apiVersion: this.state.apiVersion,
          login: this.login,
          login_redirect: this.login_redirect,
          signup: this.signup,
          convert_using_token: this.convert_using_token,
          update_using_token: this.update_using_token,
          guestlogin: this.guestlogin,
          retrievepassword: this.retrievepassword,
          logout: this.logout,
          user_profile_details: this.user_profile_details,
          getUsingToken: this.getUsingToken,
          postUsingToken: this.postUsingToken,
          post: this.post,
          postUsingCustomHeaders: this.postUsingCustomHeaders,
          get: this.get,
          select_course_route: this.select_course_route,
          recent_select_course_redirect: this.recent_select_course_redirect,
          set_most_recent_language: this.set_most_recent_language,
          reset_state_and_local_storage: this.reset_state_and_local_storage,
          checkout_session_redirect: this.checkout_session_redirect,
          fetch_premium_expiry: this.fetch_premium_expiry,
          redirect: this.redirect,
          get_first_name_or_username: this.get_first_name_or_username,
          increment_paywall_hits: this.increment_paywall_hits,
        }}
      >
        {this.props.children}
      </UserContext.Provider>
    );
  }
}
export const UserProvider = withRouter(UserProviderKernel);
