import React, { Component } from "react";
import Choicesfour from "./Choicesfour";
import sbimage from "./app_content/navigation_pages_content/images/speakerwhitebg.png";

class Questionfour extends Component {
  constructor(props) {
    super(props);
    this.myaudio = new Audio();
    this.playQuestionAudio = this.playQuestionAudio.bind(this);
  } //constructor close

  componentDidMount() {
    return setTimeout(
      function () {
        this.playQuestionAudio();
      }.bind(this),
      850
    );
  }
  setQuestionAudio = () => {
    var question_audio_url = "";
    if (this.props.auto_generated_choices) {
      console.log("quad:1");
      if (!this.props.using_african_word) {
        console.log("quad:1a");
        question_audio_url = this.props.questionObject.word.twi_audio_url;
      } else {
        console.log("quad:1b");
        question_audio_url = this.props.questionObject.african_word
          .native_audio_url;
      }
    } else {
      // console.log("quad:2");
      question_audio_url = this.props.questionObject.text_audio_url;
    }
    return question_audio_url;
  };
  playQuestionAudio() {
    var question_audio_url = this.setQuestionAudio();
    this.myaudio.src = question_audio_url;
    var promise = this.myaudio.play();
    if (promise !== undefined) {
      promise
        .catch((error) => {
          // Auto-play was prevented
          // Show a UI element to let the user manually start playback
        })
        .then(() => {
          // Auto-play started
        });
    }
  }

  render() {
    var question_text = "";
    if (this.props.auto_generated_choices) {
      question_text = this.props.questionObject.question_instruction;
    } else {
      question_text = "Select the image that matches the audio :";
    }
    return (
      <div>
        <div className="questiontext_four_group">
          <p className="questiontext_four">
            {question_text}{" "}
            <img id="sbi" onClick={this.playQuestionAudio} src={sbimage} />
          </p>
        </div>
        <Choicesfour
          mute={false}
          incQN={this.props.incQN}
          incS={this.props.incS}
          currentQuestion={this.props.currentQuestion}
          questionObject={this.props.questionObject}
          questionText={question_text}
          idx={this.props.questionObject.id}
          setSelectedChoice={this.props.setSelectedChoice}
          setCurrentQuestionObject={this.props.setCurrentQuestionObject}
          answerSubmitted={this.props.answerSubmitted}
          auto_generated_choices={this.props.auto_generated_choices}
          using_african_word={this.props.using_african_word}
        />
      </div>
    );
  }
}
export default Questionfour;
