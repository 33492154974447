import React from "react";
import "./styles/Signupform.css";
import { UserConsumer } from "../User/User.js";
import { Link } from "react-router-dom";
import background from "./images/bg-01.jpg"; //'./images/africanpattern1.jpg'
import { Navbar, Nav, NavItem, MenuItem, NavDropdown } from "react-bootstrap";
// import dialoguelogo from '../../Images/da-logo-lineart.png'
import $ from "jquery";
// import "../../../node_modules/jquery/dist/jquery.min.js";
// import "../../../node_modules/bootstrap/dist/js/bootstrap.min.js";

export default class SubscribeForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      confirmPassword: "",
      username: "",
      submitted: false,
      language: "",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handleLanguage = this.handleLanguage.bind(this);
    this.handleUsername = this.handleUsername.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.handleConfirmPassword = this.handleConfirmPassword.bind(this);
    this.redirectUser = this.redirectUser.bind(this);
  }
  componentDidMount() {
    const query = new URLSearchParams(window.location.search);
    const token = query.get("langculture");
    this.setState({ language: token });
    // let my_language = token.toLowerCase();
    // let ctx = this.props.contextapivalue;
    // ctx.set_most_recent_language(my_language);
  }
  redirectUser(path) {
    this.props.history.push(path);
  }
  submitInfo(ctx) {
    var timestamp = Date.now(); // This would be the timestamp you want to format
    var timestring = timestamp.toString(); // new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(timestamp);
    var timestrlen = timestring.length;
    var myemail = timestring + this.state.email;
    var mysubscriber =
      "subscriber" +
      timestring.substring(timestrlen - 4, timestrlen) +
      "||" +
      this.state.language;
    const data = {
      username: mysubscriber,
      password: "qwer1234",
      email: myemail,
      usingReferralCode: "",
      referralCode: "",
    };
    var path = "users/";
    return ctx.post(path, data);
  }
  handleSubmit(e, ctx) {
    e.preventDefault();
    this.submitInfo(ctx)
      .then((res) => {
        if (res.ok) {
          res.json().then((response) => {
            //Logout
            localStorage.removeItem("token");
            localStorage.setItem("username", "guest");
            localStorage.setItem("isLoggedIn", false);
            localStorage.setItem("gueststatus", true);
            alert("Great! You've been subscribed!");
            this.redirectUser("/");
          });
        } else {
          console.error("Subscribe error");
        }
      })
      .catch(function (error) {
        //Alert username or password is invalid
        console.error(error);
      });
  }
  handleEmail(event) {
    this.setState({ email: event.target.value });
  }
  handleLanguage(event) {
    this.setState({ language: event.target.value });
  }
  handleUsername(event) {
    this.setState({ username: event.target.value });
  }

  handlePassword(event) {
    this.setState({ password: event.target.value });
  }

  handleConfirmPassword(event) {
    this.setState({ confirmPassword: event.target.value });
  }

  render() {
    return (
      <UserConsumer>
        {(value) => (
          <div>
            <div>
              <div>
                <head>
                  <title>
                    Stay Informed! Learn African languages and connect to
                    culture. Learn Twi, Yoruba, Swahili, Igbo, Ga, Fante and
                    more.
                  </title>
                  <meta
                    name="description"
                    content="Dialogue Africa provides an online platform and application to learn African languages and connect to culture. Learn Twi, Fante, Ga, Swahili, Yoruba and more"
                  />
                  <meta charset="UTF-8" />
                  <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1"
                  />
                  <link
                    rel="icon"
                    type="image/png"
                    href="./images/icons/favicon.ico"
                  />
                  <link
                    rel="stylesheet"
                    type="text/css"
                    href="vendor/bootstrap/css/bootstrap.min.css"
                  />
                  <link
                    rel="stylesheet"
                    type="text/css"
                    href="fonts/font-awesome-4.7.0/css/font-awesome.min.css"
                  />
                  <link
                    rel="stylesheet"
                    type="text/css"
                    href="fonts/iconic/css/material-design-iconic-font.min.css"
                  />
                  <link
                    rel="stylesheet"
                    type="text/css"
                    href="vendor/animate/animate.css"
                  />
                  <link
                    rel="stylesheet"
                    type="text/css"
                    href="vendor/css-hamburgers/hamburgers.min.css"
                  />
                  <link
                    rel="stylesheet"
                    type="text/css"
                    href="vendor/animsition/css/animsition.min.css"
                  />
                  <link
                    rel="stylesheet"
                    type="text/css"
                    href="vendor/select2/select2.min.css"
                  />
                  <link
                    rel="stylesheet"
                    type="text/css"
                    href="vendor/daterangepicker/daterangepicker.css"
                  />
                  <link rel="stylesheet" type="text/css" href="css/util.css" />
                  <link rel="stylesheet" type="text/css" href="css/main.css" />

                  <link
                    href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
                    rel="stylesheet"
                  />
                  <link
                    rel="stylesheet"
                    href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css"
                    integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm"
                    crossorigin="anonymous"
                  />
                  <link
                    rel="stylesheet"
                    href="./styleshannon1.css"
                    type="text/css"
                  />
                  <link
                    href="https://fonts.googleapis.com/css?family=Open+Sans|Raleway"
                    rel="stylesheet"
                  />
                  <link
                    href="https://fonts.googleapis.com/css?family=Montserrat"
                    rel="stylesheet"
                  />
                  <link
                    rel="stylesheet"
                    type="text/css"
                    href="//fonts.googleapis.com/css?family=Varela+Round"
                  />
                </head>
                {/*	<nav class="navbar navheight navbar-expand-lg fixed-top navbar-light">*/}
                <nav class="navbar fixed-top navbar-expand-lg navbar-light bg-light">
                  <Link class="navbar-brand" to="/">
                    <img
                      class="favicon dialoguelogobar"
                      alt="Brand"
                      src="./Images/da-logo-lineart.png"
                    />
                  </Link>
                  <button
                    class="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarTogglerDemo02"
                    aria-controls="navbarTogglerDemo02"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span class="navbar-toggler-icon"></span>
                  </button>
                  <div
                    class="collapse navbar-collapse"
                    id="navbarTogglerDemo02"
                  >
                    <ul class="navbar-nav ml-auto">
                      <li class="nav-item active">
                        <Link class="nav-link" to="/">
                          <span class="fa fa-home"></span> Home
                          <span class="sr-only">(current)</span>
                        </Link>
                      </li>
                      <li class="nav-item active">
                        <a class="nav-link" href="/blog-main.html">
                          <span class="fa fa-book"></span> Blog
                        </a>
                      </li>
                    </ul>
                  </div>
                </nav>

                <div class="limiter">
                  <div
                    class="container-login100"
                    style={{ backgroundImage: "url(" + background + ")" }}
                  >
                    <div class="wrap-login100 p-l-55 p-r-55 p-t-65 p-b-54">
                      <form
                        class="login100-form validate-form"
                        onSubmit={(e) => this.handleSubmit(e, value)}
                      >
                        <span class="login100-form-title p-b-49">
                          Stay Informed
                        </span>
                        <div
                          class="wrap-input100 validate-input m-b-23"
                          data-validate="Email is required"
                        >
                          <i class="far fa-envelope grey"> </i>{" "}
                          <span class="label-input100">E-mail</span>
                          <input
                            class="input100"
                            type="text"
                            name="email"
                            placeholder="Enter e-mail"
                            value={this.state.email}
                            onChange={this.handleEmail}
                          />
                        </div>
                        <div
                          class="wrap-input100 validate-input m-b-23"
                          data-validate="Enter language/culture"
                        >
                          <i class="fa fa-language grey"> </i>{" "}
                          <span class="label-input100">Language / Culture</span>
                          <input
                            class="input100"
                            type="text"
                            name="language"
                            placeholder="Enter language/culture"
                            value={this.state.language}
                            onChange={this.handleLanguage}
                          />
                        </div>
                        {/*
											<div class="wrap-input100 validate-input m-b-23" data-validate = "Username is required">
												<span class="label-input100">Username</span>
												<input class="input100" type="text" name="username" placeholder="Type your username" value={this.state.username}
												onChange={this.handleUsername} />
												<span class="focus-input100" data-symbol="&#xf206;"></span>
											</div>

											<div class="wrap-input100 validate-input" data-validate="Password is required">
												<span class="label-input100">Password (>= 8 alphanumeric characters)</span>
												<input class="input100" type="password" name="pass" placeholder="Type your password"
												pattern="\w{8,}"
												value={this.state.password}
												onChange={this.handlePassword}/>
												<span class="focus-input100" data-symbol="&#xf190;"></span>
											</div>
											<div class="wrap-input100 validate-input" data-validate="Password confirmation required">
												<span class="label-input100">Confirm Password </span>
												<input class="input100" type="password" name="pass" placeholder="Type your password"
												pattern="\w{8,}" value={this.state.confirmPassword} onChange={this.handleConfirmPassword}/>
												<span class="focus-input100" data-symbol="&#xf190;"></span>
											</div>
											*/}

                        <div class="text-right p-t-8 p-b-31"></div>

                        <div class="container-login100-form-btn">
                          <div class="wrap-login100-form-btn">
                            <div class="login100-form-bgbtn"></div>
                            <button class="login100-form-btn">Submit</button>
                          </div>
                        </div>

                        {/*
											<div class="txt1 text-center p-t-54 p-b-20">
												<span>
													Or Sign Up Using
												</span>
											</div>

											<div class="flex-c-m">
												<a href="#" class="login100-social-item bg1">
													<i class="fa fa-facebook"></i>
												</a>

												<a href="#" class="login100-social-item bg2">
													<i class="fa fa-twitter"></i>
												</a>

												<a href="#" class="login100-social-item bg3">
													<i class="fa fa-google"></i>
												</a>
											</div>
										*/}
                      </form>
                      {/*
										<br/>

										<div class="container-login100-form-btn">
											<div class="wrap-login100-form-btn">
												<div class="login100-form-bgbtn"></div>

												<button class="login100-form-btn" onClick={e => guestlogin(e)}>
													OR Proceed as Guest
												</button>

											</div>
										</div>
										*/}
                      <br />
                      {/*<div class="flex-col-c p-t-155"> //original styling of div tag below*/}
                      <div class="flex-col-c">
                        <span class="txt1 p-b-17">
                          Want to create an account?{" "}
                          <Link to="/signup"> Sign Up </Link>
                        </span>
                      </div>
                      {/*
											<div id="d-inline" class="flex-col-c">
											<Link to="/"> Home Page </Link>
											<Link to="/select_course"> Select Course </Link>
											</div>
                    */}
                    </div>
                  </div>
                </div>

                <div id="dropDownSelect1"></div>

                <script src="vendor/jquery/jquery-3.2.1.min.js"></script>
                <script src="vendor/animsition/js/animsition.min.js"></script>
                <script src="vendor/bootstrap/js/popper.js"></script>
                <script src="vendor/bootstrap/js/bootstrap.min.js"></script>
                <script src="vendor/select2/select2.min.js"></script>
                <script src="vendor/daterangepicker/moment.min.js"></script>
                <script src="vendor/daterangepicker/daterangepicker.js"></script>
                <script src="vendor/countdowntime/countdowntime.js"></script>
                <script src="js/main.js"></script>
              </div>
            </div>
          </div>
        )}
      </UserConsumer>
    );
  }
}
