import React from 'react';
import '../Multiplechoice.css';
import './TranslateQuestion.css';
import CheckmarksThree  from '../CheckmarksThree'

function TranslateChoice(props) {
	const choiceAudio = new Audio();
	choiceAudio.src = props.audio;

	const onSelect = () => {
		// choiceAudio.play();
		props.onClick();
	};

	return (
		<li>
			<button className={props.answerSubmitted ? "correct-answer-word": "translate-choice"}
				onClick = {onSelect} >
				{  props.value }
			</button>
		</li>
	);
}

class TranslateQuestion extends React.Component {
	constructor(props) {
		super(props);
		this.checkAnswer = this.checkAnswer.bind(this);
		this.renderArray = this.renderArray.bind(this);
		this.renderChoice = this.renderChoice.bind(this);
		this.removeElemont = this.removeElement.bind(this);
		this.getUserAnswerColor = this.getUserAnswerColor.bind(this);

		this.state = {
			sentence : props.sentence,
			correctAnswer : props.correctAnswerWordsArray(),
			userAnswer: [],
			selectableChoices :
			// props.selectableChoices,
			  props.selectableChoicesFunc(),
		}

	}

	componentDidMount(){
		this.props.setClick2(this.checkAnswer)
	}
	// FIXME: Change the following to componentDidUpdate.
	// Plus of its current state is that storing the props into
	// state variables means the state variables can be manipulated
	// without affectnig the props. But its worth trying simply to
	// manipulate the props.
	// componentWillReceiveProps(nextProps) {
	// 	this.setState({
	// 		sentence : nextProps.sentence,
	// 		correctAnswer : nextProps.correctAnswer,
	// 		selectableChoices :
	// 		// nextProps.selectableChoices,
	// 		 nextProps.selectableChoicesFunc(),
	// 		userAnswer: [],
	// 	});
	// }

	renderArray(choiceArray) {
		return choiceArray.map((choice) => <TranslateChoice
			key = { choice.position }
			value= { choice.text }
			answerSubmitted = {this.props.answerSubmitted}
			onClick = { () => this.updateAvailableChoices(choice)}
			/>);
	}

	renderChoice(choice) {
		return <TranslateChoice
			key = { choice.position }
			value= { choice.text }
			audio={ choice.audio }
			answerSubmitted = {this.props.answerSubmitted}
			onClick = { () => this.updateAvailableChoices(choice)}
			/>
	}

	removeElement(array, element) {
		//https://stackoverflow.com/questions/5767325/how-do-i-remove-a-particular-element-from-an-array-in-javascript
		const index = array.indexOf(element);
		if (index > -1) {
			array.splice(index, 1);
		}
		return array;
	}

	updateAvailableChoices(word) {
		// Move word from the array it currently exist in to
		// the next.
		const selectableChoices = this.state.selectableChoices;
		const userAnswer = this.state.userAnswer;
		if (selectableChoices.includes(word)) {
			this.removeElement(selectableChoices, word);
			userAnswer.push(word);
		} else {
			this.removeElement(userAnswer, word);
			selectableChoices.push(word);
		}
		this.setState({
			userAnswer: userAnswer,
			selectableChoices : selectableChoices,
		});
	}

	checkAnswer() {
		console.debug("TranslateQuestion checking answer");
		// Check correct answer array against user answer
		let isCorrect = true;
		const userAnswer = this.state.userAnswer;
		// const correctAnswer = this.state.correctAnswer;
		var correctAnswer = this.props.correctAnswerWordsArray();

		if (correctAnswer.length !== userAnswer.length) {
			return !isCorrect;
		}
		console.log("wordbank correctAnswer: ", correctAnswer);
		console.log("wordbank userAnswer   :", userAnswer);

		for (var i = 0; i < correctAnswer.length; i++) {
			isCorrect = isCorrect && (correctAnswer[i].text === userAnswer[i].text);
		}
		console.log("TranslateQuestion isCorrect?: ", isCorrect);
		return isCorrect;
	}
	getUserAnswerColor(){
		console.log("getUserAnswerColor---");
		var correct = this.props.answerSubmitted && this.props.answerCorrect;
		var wrong = this.props.answerSubmitted && !this.props.answerCorrect;
		var unanswered = !this.props.answersubmitted;
		console.log(`getUserAnswerColor:: answerSubmitted: ${this.props.answerSubmitted}, answerCorrect: ${this.props.answerCorrect}`);

		console.log(`getUserAnswerColor::  correct: ${correct}, wrong: ${wrong}, unanswered: ${unanswered} `);
	  if (correct) {
			console.log("getUserAnswerColor correct");
			return "user-answer-correct";
		} else if (wrong) {
			console.log("getUserAnswerColor wrong");
			return "user-answer-wrong";
		} else{
			console.log("getUserAnswerColor none");
			return "";
		}
	}

	render() {
		const selectableChoices = this.state.selectableChoices.sort((a, b) =>  a.position - b.position).map((choice) => this.renderChoice(choice));
		const userAnswerChoices = this.renderArray(this.state.userAnswer);
		const isCorrect = this.checkAnswer();
		const correctAnswer = this.state.correctAnswer.sort((a, b) =>  a.position - b.position).map((choice) => this.renderChoice(choice));
		var optionsDisplay;
		var choice_list_style_string = "available-choices";
		if (this.props.answerSubmitted){
				optionsDisplay = correctAnswer;
				choice_list_style_string = "correct-answer-display";
		} else {
				optionsDisplay = selectableChoices;
		}
		return (
		<div>
			<div id="question-body">
				<div id={this.getUserAnswerColor()} className="user-answer translate-selectable-choices">
					<ul className="translate-choice-list"> {userAnswerChoices} </ul>
				</div>

				<div id={choice_list_style_string} className="translate-selectable-choices">
					<ul className="translate-choice-list"> {optionsDisplay} </ul>
				</div>
			</div>
		</div>
		)

	}
}

export default TranslateQuestion;
