import React, { Component } from 'react';
import './Multiplechoice.css';
import Progress  from './Progress'
import Questionthree  from './Questionthree'
import QuestionMCSentence  from './QuestionMCSentence'
import CheckmarksThree  from './CheckmarksThree'
import {Router, Route} from "react-router";
import {Link } from "react-router-dom";
import correctmark from "./app_content/culture_quiz_content/images/checkmark.gif"
import wrongmark from "./app_content/culture_quiz_content/images/xmark.gif"

import hy from './app_content/grammar_quiz_content/images/hy.PNG'
import gy from './app_content/grammar_quiz_content/images/gy.PNG'
import ny from './app_content/grammar_quiz_content/images/ny.PNG'
import ky from './app_content/grammar_quiz_content/images/ky.PNG'
import kw from './app_content/grammar_quiz_content/images/kw.PNG'
import sbimage from './app_content/grammar_quiz_content/images/soundbuttonimage.jpg'
// //audios
import hyaudio from './app_content/grammar_quiz_content/audio/hy.wav'
import gyaudio from './app_content/grammar_quiz_content/audio/gy.wav'
import nyaudio from './app_content/grammar_quiz_content/audio/ny.wav'
import kyaudio from './app_content/grammar_quiz_content/audio/ky.wav'
import kwaudio from './app_content/grammar_quiz_content/audio/kw.wav'

class Multiplechoicethree extends Component {
  constructor() {
    super();
    this.state = {
      //lessonDescription: 'Welcome to Culture Lesson One: Drumming & Dancing',
      activated: false,
      score: 0,
      currentQuestion: 1,
      currentQuestionObject: '',
      choiceSelected: false,
      answerSubmitted: false,
      submittedOrSkippedAnswersCount: 0,
      totalQuestions: 5,
      selectedChoice: '',
      checkmark: '',

      questions: [

          {

            id: 1,

            image: hy,

            example: 'hy as in hyi (burn)',

            audio: hyaudio,

            text: 'Select the words that sound out this digraph?',

            choices: [

              {

                multiple_choice_option: 'a',

                text: 'jam / gym',

                picture:'',

                audio:'',

              },

              {

                multiple_choice_option: 'b',

                text: 'ship / shorts',

                picture:'',

                audio:'',

              },

              {

                multiple_choice_option: 'c',

                text: 'queen / quick',

                picture:'',

                audio:'',

              }

            ],

            correct: 'b'

          },



        {

            id: 2,

            image: gy,

            example: 'gy as in gyata (lion)',

            audio: gyaudio,

            text: 'Select the words that sound out this digraph?',

            choices: [

              {

              multiple_choice_option:'a' ,

              text: 'goal / game' ,

              picture:'',

              audio:'',

              },



              {

              multiple_choice_option: 'b' ,

              text: 'jam / gym',

              picture:'',

              audio: '',

              },



              {

              multiple_choice_option: 'c' ,

              text: 'canyon / onion',

              picture: '' ,

              audio: '',

              }

              ],

          correct: 'b'

        },

        {

            id: 3,

            image: kw,

            example: 'kw as in kwadu (banana)',

            audio: kwaudio,

            text: 'Select the words that sound out this digraph?',

            choices: [

              {

              multiple_choice_option:'a' ,

              text: 'queen / quick' ,

              picture:'',

              audio:'',

              },



              {

              multiple_choice_option: 'b' ,

              text: 'check / chain',

              picture:'',

              audio:'',

              },



              {

              multiple_choice_option: 'c' ,

              text: 'king / kilt',

              picture:'' ,

              audio:'',

              }

              ],

          correct: 'a'

        },
        {

            id: 4,

            image: ny,

            example: 'ny as in nyansa (wisdom)',

            audio: nyaudio,

            text: 'Select the words that sound out this digraph?',

            choices: [

              {

              multiple_choice_option:'a' ,

              text: 'name / noun' ,

              picture:'',

              audio:'',

              },



              {

              multiple_choice_option: 'b' ,

              text: 'canyon / onion',

              picture:'',

              audio:'',

              },



              {

              multiple_choice_option: 'c' ,

              text: 'near / nail',

              picture:'',

              audio:'',

              }

              ],

          correct: 'b'

        },
        {

            id: 5,

            image: ky,

            example: 'ky as in kyiniiɛ (umbrella)',

            audio: kyaudio,

            text: 'Select the words that sound out this digraph?',

            choices: [

              {

              multiple_choice_option:'a' ,

              text: 'queen / quick' ,

              picture:'',

              audio:'',

              },



              {

              multiple_choice_option: 'b' ,

              text: 'king / kilt',

              picture:'',

              audio:'',

              },



              {

              multiple_choice_option: 'c' ,

              text: 'check / chain',

              picture:'',

              audio:'',

              }

              ],

          correct: 'c'

        },


      ]//end of questions//question close bracket

    }
    this.toggleActivation = this.toggleActivation.bind(this);
    //Bind toggleActivation to the right context.
    //this.incrementQuestionNumber = this.incrementQuestionNumber.bind(this, this.state.currentQuestion);
    this.toggleSubmission         = this.toggleSubmission.bind(this);
    this.incrementQuestionNumber  = this.incrementQuestionNumber.bind(this);
    this.nextQuestion             = this.nextQuestion.bind(this);
    this.skipQuestion             = this.skipQuestion.bind(this);
    this.incrementSubmittedOrSkippedAnswersCount  = this.incrementSubmittedOrSkippedAnswersCount.bind(this);
    this.incrementScore           = this.incrementScore.bind(this);
    this.setSelectedChoice        = this.setSelectedChoice.bind(this);
    this.resetSelectedChoice      = this.resetSelectedChoice.bind(this);
    this.setCurrentQuestionObject = this.setCurrentQuestionObject.bind(this);
    this.checkAnswer              = this.checkAnswer.bind(this);
    this.restartAll               = this.restartAll.bind(this);
    this.resetPerQuestion         = this.resetPerQuestion.bind(this);
    this.getNextButtonStyle       = this.getNextButtonStyle.bind(this);

  }

  setCurrentQuestionObject(questionObject){
    this.setState({currentQuestionObject: questionObject});
  }
  setCheckmarkCorrect(){
    this.setState({checkmark: 'correct'});
  }
  setCheckmarkWrong(){
    this.setState({checkmark: 'wrong'});
  }
  checkAnswer(){

    //console.log(this.state.selectedChoice);
    this.incrementSubmittedOrSkippedAnswersCount();
    //assert this.state.currentQuestionObject.id == currentQuestion
    if (this.state.selectedChoice === this.state.currentQuestionObject.correct) {
      this.incrementScore();
      this.setCheckmarkCorrect();
    } else {
      this.setCheckmarkWrong();
    }
    this.setState({answerSubmitted: true});
  }
  resetSelectedChoice(){
    this.setState({selectedChoice: ''});
  }
  setSelectedChoice(choiceId){
    if (this.state.answerSubmitted === false) {
      this.setState({selectedChoice: choiceId});
      this.setState({choiceSelected: true});
    }
  }
  //toggleActivation can be used for correct & wrong signs
  //but dangerous, if one thing goes off, the mistake will persist
  //add resetActivaton() to be safer
  toggleActivation() {
    if (this.state.activated === true) {
      this.setState({activated: false});
    }else{
      this.setState({activated: true});
    }
  }
  toggleSubmission() {
    if (this.state.answerSubmitted === true) {
      this.setState({answerSubmitted: false});
    }else{
      this.setState({answerSubmitted: true});
    }
  }
  resetActivaton() {
    this.setState({activated: true});
  }
  handleChange(e){
    //console.log(e.target.value);
    //this.setState({name: e.target.value});
    //e.target -> input box
    //e.target.value -> value in input box
  }
  //Dialogue Quiz
  setScore(s){
    this.setState({score: s});
  }
  incrementScore(){
    var inc = this.state.score + 1;
    this.setState({score: inc});
  }
  incrementSubmittedOrSkippedAnswersCount(){
    var inc = this.state.submittedOrSkippedAnswersCount + 1;
    this.setState({submittedOrSkippedAnswersCount: inc});
  }
  setCurrentQuestion(cq){
    this.setState({currentQuestion: cq});
  }
  incrementQuestionNumber(){
    var inc = this.state.currentQuestion + 1;
    this.setState({currentQuestion: inc});
  }
  resetPerQuestion(){
    this.setState({checkmark:''});
    this.setState({choiceSelected: false});
    this.setState({answerSubmitted:false});
    this.setState({currentQuestionObject: ''});
    this.setState({selectedChoice: ''});
    this.resetSelectedChoice();
  }
  nextQuestion(){
    this.incrementQuestionNumber();
    this.resetPerQuestion();
  }
  skipQuestion(){
    this.incrementSubmittedOrSkippedAnswersCount();
    this.incrementQuestionNumber();
    this.resetPerQuestion();
  }
  restartAll(){
      this.setState({score: 0});
      this.setState({currentQuestion: 1});
      this.setState({currentQuestionObject: ''});
      this.setState({choiceSelected: false});
      this.setState({answerSubmitted: false});
      this.setState({submittedOrSkippedAnswersCount: 0});
      this.setState({totalQuestions: 5});
      this.setState({selectedChoice: ''});
      this.setState({checkmark: ''});
  }
  getNextButtonStyle(){
    if (this.state.checkmark  === "wrong" ){
      return "wrongbuttonstyle"
    }else{
      return "buttonstyle"
    }
  }
  //Div description/order
  //Progress Bar
  //Question
  //Choices
  //Marker Display
  //Continue
  // <div>
  //         <h2>{helloWorld}</h2>
  //         <button onClick={this.toggleActivation}>Toggle something in ReactQuiz! </button>
  //         {this.state.activated ? <Reactquiz name ={this.state.name}/> : <div> reactquiz component code: deactivated </div>}
  // </div>
// <div class="panel-footer">
//           Your Progress<br/>
//           <p id="questCount"></p>
//           <div id="barra" class="progress">
//             <div id="bar" class="progress-bar" role="progressbar" aria-valuenow="1" aria-valuemin="0" aria-valuemax="100" style="width:1%">
//             </div>
//           </div>
//       </div>
  render() {
    var quizWelcome = '';//'Welcome to Grammar Lesson One: Digraphs';
    var checkmarkCorrectImagePath = correctmark;
    var checkmarkWrongImagePath   = wrongmark;
    return (
    <div>



      <div className="Multiplechoice">

        <div>
          <h2 className = "quizheader">{quizWelcome}</h2>
        </div>
        <div>
              <Progress totalQuestions = {this.state.totalQuestions} currentQuestion = {this.state.currentQuestion}
               score = {this.state.score} submittedOrSkippedAnswersCount = {this.state.submittedOrSkippedAnswersCount}
              />
        </div>
        <br/>
        <br/>
        <br/>

        <div>
              <Questionthree
                question  = {this.state.questions.id}
                currentQuestion ={this.state.currentQuestion} incQN = {this.incrementQuestionNumber}
                incS = {this.incrementScore} setSelectedChoice = {this.setSelectedChoice}
                setCurrentQuestionObject = {this.setCurrentQuestionObject}
                currentQuestionObject = {this.state.currentQuestionObject}
                answerSubmitted = {this.state.answerSubmitted}
              />
        </div>
        <div>
              <CheckmarksThree
                answerSubmitted = {this.state.answerSubmitted}
                answerCorrect = {this.state.selectedChoice === this.state.currentQuestionObject.correct}
               />
        </div>
        <br/>
        <br/>
        <div>
              {this.state.currentQuestion <= this.state.totalQuestions
                ? <div>
                    { this.state.answerSubmitted
                      ? <div>{ this.state.currentQuestion  == this.state.totalQuestions
                            ? <button id = {this.getNextButtonStyle()} onClick={this.nextQuestion}> Continue </button>
                             : <button id = {this.getNextButtonStyle()} onClick={this.nextQuestion}>Next Question</button>
                        }
                        </div>
                      : <button id = "buttonstyle" onClick={this.skipQuestion}>Skip</button>
                    }
                    { this.state.choiceSelected === true && this.state.answerSubmitted === false
                      ? <button id = "buttonstyle" onClick={this.checkAnswer}>Submit Answer! </button>
                       : <div></div>}
                  </div>
                : <div>
                      <button id = "buttonstyle" onClick={this.restartAll}>Retry </button>
                      <Link to={"select_course"} style={{ textDecoration: 'none' }}><button id = "buttonstyle">  Continue </button></Link>
                  </div>
              }
        </div>




      </div>
    </div>
    );
  }
}

export default Multiplechoicethree;
