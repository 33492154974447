import React from "react";
import "./styles/Signupform.css";
import { UserConsumer } from "../User/User.js";
import { Link } from "react-router-dom";
import background from "./images/bg-01.jpg";

export default class ConvertForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      confirmPassword: "",
      username: "",
      first_name: "",
      last_name: "",
      language_select_course_link: this.get_language_select_course_link(
        this.props.location.state ? this.props.location.state.language : null
      ),
    };

    this.handleEmail = this.handleEmail.bind(this);
    this.handleUsername = this.handleUsername.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.redirectUser = this.redirectUser.bind(this);
    this.get_language_select_course_link = this.get_language_select_course_link.bind(
      this
    );
  }

  redirectUser(path) {
    this.props.history.push(path);
  }
  get_language_select_course_link(language) {
    // console.log("convert: lang", language)
    if (language == null || language == "twi") {
      return "/select_course";
    }
    return `/select-${language}-course`;
  }
  cleanInput = (email) => {
    return email.toLowerCase();
  };
  handleSubmit = (e, ctx) => {
    e.preventDefault();
    // if redirected to signup from payments, then redirect
    // back to payments page after signup, if homepage, then
    // to select lang jump link. Anything else goes to select
    // lang jump link
    const router_state = this.props.location.state;
    const curr_language = router_state ? router_state.language : null;
    const select_lang_jump_link = ctx.select_course_route(curr_language);
    const login_data = {
      username_or_email: this.state.email,
      password: this.state.password,
    };
    const convert_data = this.state;
    // const redirect_to = router_state
    //   ? router_state.from
    //   : select_lang_jump_link;

    if (this.props.profile_already_exists) {
      ctx.update_using_token(e, convert_data);
    } else {
      ctx.convert_using_token(e, convert_data).then((res) => {
        if (res.ok) {
          res.json().then(() => {
            ctx.login(e, login_data);
          });
        } else {
          alert("Email or username already exits. Try again.");
          res.json().then((json) => {
            console.log("nn json: ", json);
          });
        }
      });
    }
  };
  handleEmail(event) {
    let my_email = event.target.value;
    my_email = this.cleanInput(my_email);
    this.setState({ email: my_email });
  }

  handleUsername(event) {
    let my_username = event.target.value;
    my_username = this.cleanInput(my_username);
    this.setState({ username: my_username });
  }
  handleFirstName = (e) => {
    this.setState({ first_name: e.target.value });
  };
  handleLastName = (e) => {
    this.setState({ last_name: e.target.value });
  };

  handlePassword(event) {
    this.setState({ password: event.target.value });
  }

  handleConfirmPassword(event) {
    this.setState({ confirmPassword: event.target.value });
  }
  displayTitle = () => {
    let page_title = "User Profile";
    if (this.props.profile_already_exists) {
      page_title = "Update Profile";
    }
    return page_title;
  };
  render() {
    return (
      <UserConsumer>
        {(value) => (
          <div>
            <div>
              <div>
                <head>
                  <title>User Profile</title>
                  <meta charset="UTF-8" />
                  <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1"
                  />
                  <meta
                    name="description"
                    content="Dialogue Africa provides an online platform and application to learn African languages and connect to culture. Learn Twi, Fante, Ga, Swahili, Yoruba and more"
                  />
                  <link
                    rel="icon"
                    type="image/png"
                    href="./images/icons/favicon.ico"
                  />
                  <link
                    rel="stylesheet"
                    type="text/css"
                    href="vendor/bootstrap/css/bootstrap.min.css"
                  />
                  <link
                    rel="stylesheet"
                    type="text/css"
                    href="fonts/font-awesome-4.7.0/css/font-awesome.min.css"
                  />
                  <link
                    rel="stylesheet"
                    type="text/css"
                    href="fonts/iconic/css/material-design-iconic-font.min.css"
                  />
                  <link
                    rel="stylesheet"
                    type="text/css"
                    href="vendor/animate/animate.css"
                  />
                  <link
                    rel="stylesheet"
                    type="text/css"
                    href="vendor/css-hamburgers/hamburgers.min.css"
                  />
                  <link
                    rel="stylesheet"
                    type="text/css"
                    href="vendor/animsition/css/animsition.min.css"
                  />
                  <link
                    rel="stylesheet"
                    type="text/css"
                    href="vendor/select2/select2.min.css"
                  />
                  <link
                    rel="stylesheet"
                    type="text/css"
                    href="vendor/daterangepicker/daterangepicker.css"
                  />
                  <link rel="stylesheet" type="text/css" href="css/util.css" />
                  <link rel="stylesheet" type="text/css" href="css/main.css" />

                  <link
                    href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
                    rel="stylesheet"
                  />
                  <link
                    rel="stylesheet"
                    href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css"
                    integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm"
                    crossorigin="anonymous"
                  />
                  <link
                    rel="stylesheet"
                    href="./styleshannon1.css"
                    type="text/css"
                  />
                  <link
                    href="https://fonts.googleapis.com/css?family=Open+Sans|Raleway"
                    rel="stylesheet"
                  />
                  <link
                    href="https://fonts.googleapis.com/css?family=Montserrat"
                    rel="stylesheet"
                  />
                  <link
                    rel="stylesheet"
                    type="text/css"
                    href="//fonts.googleapis.com/css?family=Varela+Round"
                  />
                </head>

                <nav class="navbar fixed-top navbar-expand-lg navbar-light bg-light">
                  <Link class="navbar-brand" to="/">
                    <img
                      class="favicon dialoguelogobar"
                      alt="Brand"
                      src="./Images/da-logo-lineart.png"
                    />
                  </Link>
                  <button
                    class="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarTogglerDemo02"
                    aria-controls="navbarTogglerDemo02"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span class="navbar-toggler-icon"></span>
                  </button>

                  <div
                    class="collapse navbar-collapse"
                    id="navbarTogglerDemo02"
                  >
                    <ul class="navbar-nav ml-auto">
                      <li class="nav-item active">
                        <Link class="nav-link" to="/">
                          <span class="fa fa-home"></span> Home
                          <span class="sr-only">(current)</span>
                        </Link>
                      </li>
                      <li class="nav-item active">
                        <a class="nav-link" href="/blog-main.html">
                          <span class="fa fa-book"></span> Blog
                        </a>
                      </li>
                      <li class="nav-item active">
                        <Link class="nav-link " to="/login">
                          <span class="fa fa-sign-in" aria-hidden="true"></span>{" "}
                          Login
                        </Link>
                      </li>
                      <li class="nav-item active">
                        <Link class="nav-link " to="/select_course">
                          <span class="fa fa-user"></span> Select Course
                        </Link>
                      </li>
                    </ul>
                  </div>
                </nav>

                <div class="limiter">
                  <div
                    class="container-login100"
                    style={{ backgroundImage: "url(" + background + ")" }}
                  >
                    <div class="wrap-login100 p-l-55 p-r-55 p-t-65 p-b-54">
                      <form
                        class="login100-form validate-form"
                        onSubmit={(e) => this.handleSubmit(e, value)}
                      >
                        <span class="login100-form-title p-b-49">
                          {this.displayTitle()}
                        </span>
                        {this.props.profile_already_exists ? (
                          <>
                            <div
                              class="wrap-input100 validate-input m-b-23"
                              data-validate="First name is required"
                            >
                              <i class="fas fa-user-edit"></i>
                              <span class="label-input100">First name</span>
                              <input
                                id="username-input"
                                class="input100"
                                type="text"
                                name="first_name"
                                placeholder="Update first name"
                                value={this.state.first_name}
                                onChange={this.handleFirstName}
                              />
                            </div>
                            <div
                              class="wrap-input100 validate-input m-b-23"
                              data-validate="Last name is required"
                            >
                              <i class="fas fa-user-edit"></i>
                              <span class="label-input100">Last name</span>
                              <input
                                id="username-input"
                                class="input100"
                                type="text"
                                name="last_name"
                                placeholder="Update last name"
                                value={this.state.last_name}
                                onChange={this.handleLastName}
                              />
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              class="wrap-input100 validate-input m-b-23"
                              data-validate="Email is required"
                            >
                              <i class="far fa-envelope grey"> </i>{" "}
                              <span class="label-input100">E-mail</span>
                              <input
                                id="email-input"
                                class="input100"
                                type="text"
                                name="email"
                                placeholder={
                                  this.props.profile_already_exists
                                    ? "Update e-mail"
                                    : "Enter e-mail"
                                }
                                value={this.state.email}
                                onChange={this.handleEmail}
                              />
                            </div>
                          </>
                        )}

                        <div
                          class="wrap-input100 validate-input m-b-23"
                          data-validate="Username is required"
                        >
                          <i class="fas fa-user-edit"></i>
                          <span class="label-input100">Username</span>
                          <input
                            id="username-input"
                            class="input100"
                            type="text"
                            name="username"
                            placeholder={
                              this.props.profile_already_exists
                                ? "Update username"
                                : "Enter username"
                            }
                            value={this.state.username}
                            onChange={this.handleUsername}
                          />
                        </div>
                        {this.props.profile_already_exists ? (
                          <></>
                        ) : (
                          <div
                            class="wrap-input100 validate-input m-b-23"
                            data-validate="Password is required"
                          >
                            <i class="fas fa-unlock-alt nicegreen"></i>
                            <span class="label-input100">
                              Create Password (6+ characters)
                            </span>
                            <input
                              id="password-input"
                              class="input100"
                              type="password"
                              name="pass"
                              placeholder="Type your password"
                              pattern="^(.*).{6,}$"
                              value={this.state.password}
                              onChange={this.handlePassword}
                            />
                          </div>
                        )}

                        <div class="text-right p-t-8 p-b-31">
                          <a href="#"></a>
                        </div>

                        <div class="container-login100-form-btn">
                          <div class="wrap-login100-form-btn">
                            <div class="login100-form-bgbtn"></div>
                            <button class="login100-form-btn">Done</button>
                          </div>
                        </div>
                      </form>
                      <br />
                      <div class="flex-col-c">
                        <span class="txt1 p-b-17">
                          Already have an account?{" "}
                          <Link to="/login"> Login </Link>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="dropDownSelect1"></div>

                <script src="vendor/jquery/jquery-3.2.1.min.js"></script>
                <script src="vendor/animsition/js/animsition.min.js"></script>
                <script src="vendor/bootstrap/js/popper.js"></script>
                <script src="vendor/bootstrap/js/bootstrap.min.js"></script>
                <script src="vendor/select2/select2.min.js"></script>
                <script src="vendor/daterangepicker/moment.min.js"></script>
                <script src="vendor/daterangepicker/daterangepicker.js"></script>
                <script src="vendor/countdowntime/countdowntime.js"></script>
                <script src="js/main.js"></script>
              </div>
            </div>
          </div>
        )}
      </UserConsumer>
    );
  }
}
