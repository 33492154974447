import React from "react";
import "./styles/Signupform.css";
import renderHTML from "react-render-html";
import "./css/main.css";
import "./css/util.css";
import background from "./images/africanpattern1.jpg";
import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import { PostData } from "../../PostData";
import { Redirect } from "react-router-dom";

import { UserConsumer } from "../User/User.js";
import { Link } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";
if (process.env.REACT_APP_DEV_PIPELINE_STAGE == "production") {
  const options = {
    autoConfig: true, // set pixel's autoConfig
    debug: false, // enable logs
  };
  ReactPixel.init(process.env.REACT_APP_FB_PIXEL_CODE, options);
  ReactPixel.pageView();
}

export default class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username_or_email: "",
      password: "",
      loginError: false,
      redirect: false,
    };

    this.handleUsernameOrEmail = this.handleUsernameOrEmail.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.signup = this.signup.bind(this);
  }

  signup(res, type) {
    let postData;
    if (type === "facebook" && res.email) {
      postData = {
        name: res.name,
        provider: type,
        email: res.email,
        provider_id: res.id,
        token: res.accessToken,
        provider_pic: res.picture.data.url,
      };
    }
    console.log("res.w3.U3 -->");
    // console.log(res.w3.U3)
    // if (type === 'google' && res.w3.U3) {
    //     postData = {
    //       name: res.w3.ig,
    //       provider: type,
    //       email: res.w3.U3,
    //       provider_id: res.El,
    //       token: res.Zi.access_token,
    //       provider_pic: res.w3.Paa
    //     };
    // }
    console.log("check postdata");
    if (postData) {
      console.log("check postdata receipt");
      PostData("login", postData).then((result) => {
        let responseJson = result;
        console.log("responseJson");
        console.log(responseJson);

        sessionStorage.setItem("userData", JSON.stringify(responseJson));
        this.setState({ redirect: true });
      });
    } else {
    }
  }
  cleanInput = (input) => {
    return input.toLowerCase();
  };
  handleUsernameOrEmail(event) {
    var u_or_e = this.cleanInput(event.target.value);
    this.setState({ username_or_email: u_or_e });
  }

  handlePassword(event) {
    this.setState({ password: event.target.value });
  }

  render() {
    if (this.state.redirect || sessionStorage.getItem("userData")) {
      return <Redirect to={"/select_course"} />;
    }

    const responseFacebook = (response) => {
      console.log("facebook console");
      console.log(response);
      this.signup(response, "facebook");
    };

    const responseGoogle = (response) => {
      console.log("google console");
      console.log(response);
      this.signup(response, "google");
    };
    return (
      <UserConsumer>
        {(value) => (
          <div>
            <div>
              <head>
                <meta
                  name="description"
                  content="Dialogue Africa provides an online platform and application to learn African languages and connect to culture. Learn Twi, Fante, Ga, Swahili, Yoruba and more"
                />
                <title>
                  Login: Learn African languages and connect to culture. Learn
                  Twi, Yoruba, Swahili, Igbo, Ga, Fante and more.
                </title>
                <meta charset="UTF-8" />
                <meta
                  name="viewport"
                  content="width=device-width, initial-scale=1"
                />
                <link
                  rel="icon"
                  type="image/png"
                  href="./images/icons/favicon.ico"
                />
                <link
                  rel="stylesheet"
                  type="text/css"
                  href="vendor/bootstrap/css/bootstrap.min.css"
                />
                <link
                  rel="stylesheet"
                  type="text/css"
                  href="fonts/font-awesome-4.7.0/css/font-awesome.min.css"
                />
                <link
                  rel="stylesheet"
                  type="text/css"
                  href="fonts/iconic/css/material-design-iconic-font.min.css"
                />
                <link
                  rel="stylesheet"
                  type="text/css"
                  href="vendor/animate/animate.css"
                />
                <link
                  rel="stylesheet"
                  type="text/css"
                  href="vendor/css-hamburgers/hamburgers.min.css"
                />
                <link
                  rel="stylesheet"
                  type="text/css"
                  href="vendor/animsition/css/animsition.min.css"
                />
                <link
                  rel="stylesheet"
                  type="text/css"
                  href="vendor/select2/select2.min.css"
                />
                <link
                  rel="stylesheet"
                  type="text/css"
                  href="vendor/daterangepicker/daterangepicker.css"
                />
                <link rel="stylesheet" type="text/css" href="css/util.css" />
                <link rel="stylesheet" type="text/css" href="css/main.css" />

                <link
                  rel="stylesheet"
                  href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css"
                  integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm"
                  crossorigin="anonymous"
                />
                <link
                  rel="stylesheet"
                  href="../../styleshannon1.css"
                  type="text/css"
                />
                <link
                  href="https://fonts.googleapis.com/css?family=Open+Sans|Raleway"
                  rel="stylesheet"
                />
                <link
                  href="https://fonts.googleapis.com/css?family=Montserrat"
                  rel="stylesheet"
                />
                <link
                  rel="stylesheet"
                  type="text/css"
                  href="//fonts.googleapis.com/css?family=Varela+Round"
                />
              </head>
              <nav class="navbar fixed-top navbar-expand-lg navbar-light bg-light">
                <Link class="navbar-brand" to="/">
                  <img
                    class="favicon dialoguelogobar"
                    alt="Brand"
                    src="../../Images/da-logo-lineart.png"
                  />
                </Link>
                <button
                  class="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarTogglerDemo02"
                  aria-controls="navbarTogglerDemo02"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
                  <ul class="navbar-nav ml-auto">
                    <li class="nav-item active">
                      {value.isLoggedIn ? (
                        <span class="fontB fontregular nav-link" href="#">
                          &nbsp;&nbsp;
                          <span class="fa fa-user-circle">&nbsp;</span> Hi{" "}
                          {value.get_first_name_or_username()}
                          &nbsp;
                        </span>
                      ) : (
                        <span></span>
                      )}
                    </li>
                    <li class="nav-item active">
                      <Link
                        class="fontB fontregular btn btn-outline-info my-2 my-sm-0"
                        to="/crowdsource"
                      >
                        <span class="fa fa-hands-helping"></span> Crowdsource
                        with us
                      </Link>
                    </li>
                    <li class="nav-item active">
                      <a
                        class="fontB fontregular nav-link"
                        href="/blog-main.html"
                      >
                        <span class="fa fa-book"></span> Blog
                      </a>
                    </li>
                    {value.isLoggedIn ? (
                      <li class="nav-item active">
                        <a
                          onClick={(e) => {
                            value.recent_select_course_redirect();
                          }}
                          class="fontB fontregular nav-link"
                          href="javascript:void(0);"
                        >
                          <span class="fa fa-user"></span> Select Course
                        </a>
                      </li>
                    ) : (
                      <li class="nav-item active">
                        <Link
                          class="fontB fontregular btn btn-outline-success my-2 my-sm-0"
                          to="/signup"
                        >
                          <span class="fa fa-user"></span> Register
                        </Link>
                      </li>
                    )}
                  </ul>
                </div>
              </nav>
              <div class="limiter">
                <div
                  class="container-login100"
                  style={{ backgroundImage: "url(" + background + ")" }}
                >
                  <div class="wrap-login100 p-l-55 p-r-55 p-t-65 p-b-54">
                    <form
                      class="login100-form validate-form"
                      onSubmit={(e) => value.login(e, this.state)}
                    >
                      <span class="login100-form-title p-b-49">
                        Member Login
                      </span>

                      <div
                        class="wrap-input100 validate-input m-b-23"
                        data-validate="Username is required"
                      >
                        <i class="fas fa-user-edit"></i>
                        <span class="label-input100">E-mail or Username</span>
                        <input
                          id="email-input"
                          class="input100"
                          type="text"
                          name="username"
                          placeholder="Enter e-mail or username"
                          value={this.state.username_or_email}
                          onChange={this.handleUsernameOrEmail}
                        />
                      </div>

                      <div
                        class="wrap-input100 validate-input"
                        data-validate="Password is required"
                      >
                        <i class="fas fa-unlock-alt"></i>
                        <span class="label-input100">Password</span>
                        <input
                          id="password-input"
                          class="input100"
                          type="password"
                          name="pass"
                          placeholder="Type password"
                          value={this.state.password}
                          onChange={this.handlePassword}
                        />
                      </div>

                      <div class="text-right p-t-8 p-b-31">
                        <Link to="/retrieve_password">
                          <span>Forgot password?</span>
                        </Link>
                      </div>

                      <div class="container-login100-form-btn">
                        <div class="wrap-login100-form-btn">
                          <div class="login100-form-bgbtn"></div>
                          <button class="login100-form-btn">
                            {" "}
                            <span> Login </span>
                          </button>
                        </div>
                      </div>
                      {/*
											<div class="txt1 text-center p-t-54 p-b-20">
												<span>
													Or Sign Up Using
												</span>
											</div>

											<div class="flex-c-m">
												<a href="#" class="login100-social-item bg1">
													<i class="fa fa-facebook"></i>
												</a>

												<a href="#" class="login100-social-item bg2">
													<i class="fa fa-twitter"></i>
												</a>

												<a href="#" class="login100-social-item bg3">
													<i class="fa fa-google"></i>
												</a>
											</div>
										*/}

                      <div class="flex-col-c p-t-155">
                        <span class="txt1 p-b-17">
                          Don't have an account?{" "}
                          <Link to="/signup"> Sign Up </Link>
                        </span>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div id="dropDownSelect1"></div>
              {/*
							<div className="row body">
									<div className="medium-12 columns">
									<div className="medium-12 columns">
									<h2 id="welcomeText"></h2>

									<FacebookLogin
									appId="281984522392722"
									autoLoad={false}
									fields="name,email,picture"
									callback={responseFacebook}/>
									<br/><br/>

									<GoogleLogin
									clientId="765414436861-87m6o1sqbglco0n6mbb7tgq6a8vpctn0.apps.googleusercontent.com"
									buttonText="LoginP with Google"
									onSuccess={responseGoogle}
									onFailure={responseGoogle}/>

									</div>
									</div>
								</div>
*/}
              <script src="vendor/jquery/jquery-3.2.1.min.js"></script>
              <script src="vendor/animsition/js/animsition.min.js"></script>
              <script src="vendor/bootstrap/js/popper.js"></script>
              <script src="vendor/bootstrap/js/bootstrap.min.js"></script>
              <script src="vendor/select2/select2.min.js"></script>
              <script src="vendor/daterangepicker/moment.min.js"></script>
              <script src="vendor/daterangepicker/daterangepicker.js"></script>
              <script src="vendor/countdowntime/countdowntime.js"></script>
              <script src="js/main.js"></script>
            </div>
          </div>
        )}
      </UserConsumer>
    );
  }
}
