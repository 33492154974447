import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import one from "./Images/onelineicon.svg";
import two from "./Images/twolineicon.svg";
import three from "./Images/threelineicon.svg";
import facebookicon from "./Images/newfacebookicon.png";
import ghanaflaglineicon from "./Images/flag-ghana.png";
import tanzaniaflaglineicon from "./Images/flag-tanzania.png";
import naijaflaglineicon from "./Images/flag-nigeria.png";
import blogicon from "./Images/blog-icon.jpeg";
import { UserConsumer } from "./components/User/User.js";
import ReactPixel from "react-facebook-pixel";
import ScrollableAnchor from "react-scrollable-anchor";
import { configureAnchors } from "react-scrollable-anchor";
import manonkeyboardsmall from "./Images/man-on-keyboard-small.png";
// import mainfavicon from "./Images/COMMUNICATION-AFRICA-DIF-COLORS.png";
// Offset all anchors by -60 to account for a fixed header
// and scroll more quickly than the default 400ms
configureAnchors({
  offset: -60,
  scrollDuration: 100,
  keepLastAnchorHash: true,
});
if (process.env.REACT_APP_DEV_PIPELINE_STAGE == "production") {
  const options = {
    autoConfig: true, // set pixel's autoConfig
    debug: false, // enable logs
  };
  ReactPixel.init(process.env.REACT_APP_FB_PIXEL_CODE, options);
  ReactPixel.pageView();
}

/**
 * Can add more routes: <Route path={"user"} />
 * <Route path='/multiplechoice' component={Multiplechoice } />
 * example.com/#/multiplechoice
 * html5 can automatically can handle different url start, deep links or hashtag links.
 */

class GaSelectlanguage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visited_login_func: false,
      display_name_ret: false,
    };
    this.displayName = this.displayName.bind(this);
  }
  // componentDidMount() {
  //   this.setState({
  //     visited_login_func: true,
  //   })
  // }
  displayName(myctx) {
    if (this.state.visited_login_func == true) {
      // return precomputed answer
      return this.state.display_name_ret;
    }
    var loggedIn = true;
    // compute answer
    if (!myctx.isLoggedIn) {
      loggedIn = false;
    }
    this.setState({
      display_name_ret: loggedIn,
      visited_login_func: true,
    });
    return loggedIn;
  }
  displayDemosList(value) {
    return (
      <div class="btn-group">
        <button
          id="try-demo"
          type="button"
          class="btn btn-lg btn-outline-danger dropdown-toggle dropdown-toggle-split"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i class="fas fa-laptop-code">&nbsp;</i> Try Beta &nbsp;&nbsp;
          {"  "}
          <span class="sr-only"> Toggle Dropdown</span>
        </button>{" "}
        <div class="dropdown-menu">
          <ul>
            <li>
              <a
                id="darkyellow"
                class="dropdown-item fontB fontregular text-warning my-2 my-sm-0"
                onClick={(e) => value.guestlogin(e, "ga")}
                href="javascript:void(0);"
              >
                <i class="fas fa-laptop-code">&nbsp;</i> GA
              </a>
            </li>
            <div class="dropdown-divider"></div>
            <li class="nav-item active">
              <a
                class="dropdown-item fontB fontregular text-success my-2 my-sm-0"
                onClick={(e) => value.guestlogin(e, "yoruba")}
                href="javascript:void(0);"
              >
                <span class="fas fa-laptop-code">&nbsp;</span> YORUBA
              </a>
            </li>
            <div class="dropdown-divider"></div>
            <li class="nav-item active">
              <button
                id="twi-demo-1"
                class="textdarkorange dropdown-item fontB fontregular  my-2 my-sm-0"
                onClick={(e) => value.guestlogin(e, "twi")}
                // href="javascript:void(0);"
              >
                <span class="fas fa-laptop-code">&nbsp;</span> TWI
              </button>
            </li>

            <div class="dropdown-divider"></div>

            <li>
              <a
                class="dropdown-item fontB fontregular text-secondary my-2 my-sm-0"
                onClick={(e) => value.guestlogin(e, "swahili")}
                href="javascript:void(0);"
              >
                <span class="fas fa-laptop-code">&nbsp;</span> SWAHILI
              </a>
            </li>
            <div class="dropdown-divider"></div>
            <li>
              <a
                class="dropdown-item fontB fontregular text-danger my-2 my-sm-0"
                onClick={(e) => value.guestlogin(e, "igbo")}
                href="javascript:void(0);"
              >
                <span class="fas fa-laptop-code">&nbsp;</span> IGBO
              </a>
            </li>
            {/* <div class="dropdown-divider"></div>
            <li>
              <a
                class="dropdown-item fontB fontregular text-info my-2 my-sm-0"
                onClick={(e) =>
                  value.guestlogin(e, "kinyarwanda")
                }
                href="javascript:void(0);"
              >
                <span class="fas fa-laptop-code">&nbsp;</span> KINYARWANDA
              </a>
            </li> */}
          </ul>
        </div>
      </div>
    );
  }
  render() {
    return (
      <UserConsumer>
        {(value) => (
          <html>
            <head>
              <meta charset="utf-8" />
              <meta http-equiv="X-UA-Compatible" content="IE=edge" />
              <meta
                name="viewport"
                content="width=device-width,initial-scale=1,shrink-to-fit=no"
              />
              <meta
                name="description"
                content="Dialogue Africa provides an online platform and application to learn Ga language and connect to Ghana and Ga culture."
              />
              <Helmet>
                <title>
                  {" "}
                  Learn Ga language and culture, and connect to Ghana - Dialogue
                  Africa{" "}
                </title>
              </Helmet>

              <link
                rel="stylesheet"
                href="https://use.fontawesome.com/releases/v5.6.3/css/all.css"
                integrity="sha384-UHRtZLI+pbxtHCWp1t77Bi1L4ZtiqrqD80Kn4Z8NTSRyMA2Fd33n5dQ8lWUE00s/"
                crossorigin="anonymous"
              />
              <link
                rel="stylesheet"
                href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css"
                integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm"
                crossorigin="anonymous"
              />
              <link
                rel="stylesheet"
                href="./../styleshannon1.css"
                type="text/css"
              />
              <link
                href="https://fonts.googleapis.com/css?family=Baloo+Da+2&display=swap"
                rel="stylesheet"
              />
              <link
                rel="stylesheet"
                type="text/css"
                href="//fonts.googleapis.com/css?family=Varela+Round"
              />
            </head>

            <body class="graybackground">
              <nav class="fontB navbar fixed-top navbar-expand-lg navbar-light bg-light">
                <a class="navbar-brand" href="/">
                  <img
                    class="favicon dialoguelogobar"
                    alt="learn African language platform app"
                    src="../Images/da-logo-lineart.png"
                  />
                </a>
                <button
                  id="nav-toggle"
                  class="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarTogglerDemo02"
                  aria-controls="navbarTogglerDemo02"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span class="navbar-toggler-icon"></span>
                </button>

                <div
                  class="fontB collapse navbar-collapse"
                  id="navbarTogglerDemo02"
                >
                  <ul class="fontB navbar-nav ml-auto">
                    {this.displayDemosList(value)}
                    <li class="nav-item active">
                      {!this.displayName(value) ? (
                        <Link class="fontB fontregular nav-link" to="/login">
                          &nbsp;&nbsp;
                          <span class="fa fa-sign-in-alt" aria-hidden="true">
                            &nbsp;
                          </span>
                          Login &nbsp;
                        </Link>
                      ) : (
                        <span class="fontB fontregular nav-link" href="#">
                          &nbsp;&nbsp;
                          <span class="fa fa-user-circle">&nbsp;</span> Hi{" "}
                          {value.isGuest ? "Guest" : value.user}
                          &nbsp;
                        </span>
                      )}
                    </li>
                    {/* <li class="nav-item active">
                      <a
                        class="fontB fontregular nav-link"
                        href="/blog-main.html"
                      >
                        <span class="far fa-newspaper"></span> Blog{" "}
                      </a>
                    </li> */}
                    {/* <li class="nav-item active">
                      <a class="fontB fontregular nav-link" href="/subscribe">
                        <span class="fas fa-satellite-dish"></span> Stay
                        Informed! <span class="sr-only"> (current) </span>{" "}
                      </a>
                    </li> */}
                    <li class="nav-item active">
                      <Link
                        class="fontB fontregular btn btn-outline-info my-2 my-sm-0"
                        to="/crowdsource"
                      >
                        <span class="fa fa-hands-helping">&nbsp;</span>{" "}
                        Crowdsource
                      </Link>
                    </li>
                    <li class="nav-item active">
                      <a
                        class="fontB fontregular nav-link"
                        href="/blog-main.html"
                      >
                        &nbsp;<span class="fa fa-book"></span> Blog &nbsp;
                      </a>
                    </li>
                    {!value.isLoggedIn ? (
                      <li class="nav-item active">
                        <Link
                          class="fontB fontregular btn btn-outline-success my-2 my-sm-0"
                          to="/signup"
                        >
                          <i class="fas fa-user-plus">&nbsp;</i> Register
                        </Link>
                      </li>
                    ) : (
                      <></>
                    )}
                    {this.displayName(value) ? (
                      <li class="nav-item active">
                        <a
                          className="delete-button"
                          onClick={value.logout}
                          class="fontB fontregular nav-link"
                          href="javascript:void(0);"
                        >
                          <span class="fa fa-sign-out"></span> Logout
                          <span class="sr-only">(current)</span>
                        </a>
                      </li>
                    ) : (
                      <span></span>
                    )}
                  </ul>
                </div>
              </nav>

              <div class="container-fluid">
                <div class="row">
                  <div class="col-md-6 carouselContainer">
                    <div
                      id="carouselExampleSlidesOnly"
                      class="carousel slide"
                      data-ride="carousel"
                    >
                      <div class="carousel-inner">
                        <div class="carousel-item active">
                          <img
                            class="d-block w-100"
                            src="../Images/favicon-dialogue-africa-ghana-pb.png"
                            alt="learn african languages app"
                          />
                        </div>
                        <div class="carousel-item">
                          <img
                            class="d-block w-100"
                            src="../Images/suckyourmuddac.png"
                            alt="learn twi, speak akan twi language app"
                          />
                        </div>
                        <div class="carousel-item">
                          <img
                            class="d-block w-100"
                            src="../Images/oldman-hausa-woman-pi.png"
                            alt="african languages app"
                          />
                        </div>
                        <div class="carousel-item">
                          <img
                            class="d-block w-100"
                            src="../Images/ghanaman-ipadwoman-rs.png"
                            alt="african culture app"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div id="rpadding2" class="col-md-6 my-auto rpadding">
                    <div class="text-center">
                      <p class="h1large fontB"> Dialogue Africa</p>
                    </div>
                    <p class="fonto fontB text-center bolder tagline">
                      {" "}
                      Learn. Connect. Access!
                    </p>
                    <p
                      id="startinfo"
                      class="fontB text-justify twentypixf mx-5"
                    >
                      Oobakɛ! Begin your journey to build the foundation and
                      connections you need to better access Ghanaian
                      experiences. Start with fun lessons to prepare you for
                      real world interactions! Learn Ga - one of the main
                      coastal languages spoken in southeastern Ghana - and the
                      rich culture of the Ga people.
                      {/* Join the community! */}
                    </p>

                    <button
                      onClick={(e) => value.guestlogin(e, "ga")}
                      id="thinbuttonextrastyle"
                      class="btn btn-success d-block mx-auto rpadding"
                    >
                      {value.isLoggedIn ? (
                        <p class="fontB"> Continue to Ga Beta </p>
                      ) : (
                        <p class="fontB"> Ga Beta Sign Up </p>
                      )}
                    </button>
                  </div>
                </div>
              </div>

              <div class="container-fluid">
                <div class="row">
                  <div class="col-md-12 col-sm-12 platformblue">
                    <p class="text-center fontwhite freeplatform fontB ">
                      {" "}
                      Dialogue Africa provides a platform for Ga language and
                      cultural immersion.
                    </p>
                  </div>
                </div>

                <br />
              </div>
              <div class="row whitespace">
                <div class="col-md-4 my-5 px-5">
                  <div>
                    <img
                      class="iconsizer iconcenter zoom"
                      alt="Yoruba, Hausa, Bambara, Igbo, Twi, Shona, Swahili, Oromo, Amharic, Ewe, Ga app"
                      src="../Images/icon-onedestination.png"
                    />
                    <h5 class="fontB fontregular  bold text-center textorange">
                      {" "}
                      One Destination, Multiple African Languages{" "}
                    </h5>
                    <p class="fontbs fontsmaller text-justify">
                      Learn Ga and many other Ghanaian and African languages all
                      through one application. The early versions of the Ga and
                      Twi language courses are now available!
                    </p>
                  </div>
                  <div class="paddingtop15">
                    <Link class="text-danger" to="/crowdsource">
                      <img
                        class="iconsizer iconcenter zoom"
                        alt="crowdsource africa language app african community language application"
                        src="../Images/icon-crowd-sourced.png"
                      />
                      <h5 class="fontB fontregular bold text-justify text-center">
                        {" "}
                        Crowd-Sourced. User Focused
                      </h5>
                    </Link>
                    <p class="fontbs fontsmaller text-justify">
                      {" "}
                      Users can contribute by building Ga lessons, adding
                      vocabulary and sharing stories! Join the community effort!
                      <Link
                        id="homepage_feedback"
                        class="bold text-danger"
                        alt="africa platform app"
                        to="/crowdsource"
                      >
                        {" "}
                        Contact us{" "}
                      </Link>
                      to sign up as a Ga contributor and help build Ghanaian
                      language courses.
                    </p>
                  </div>
                </div>

                <div class="col-md-4 my-auto px-5">
                  <img
                    class="laptop"
                    src="../Images/computer-first-screen.png"
                    alt="Dialogue Africa. Digital learning africa"
                  />
                </div>

                <div class="col-md-4 my-5 px-5">
                  <div>
                    <img
                      class="iconsizer iconcenter zoom"
                      alt="celebrate african culture pride app"
                      src="../Images/icon-telescope.png"
                    />
                    <h5 class="fontB fontregular bold text-center text-info">
                      {" "}
                      Ga & Ghanaian Point-of-View:{" "}
                    </h5>
                    <p class="fontbs fontsmaller text-justify">
                      {" "}
                      We celebrate the uniqueness of Ga language and culture.
                      Our courses are designed with the cultural and linguistic
                      specificities of Ghana in mind.
                    </p>
                  </div>
                  <div class="paddingtop15">
                    <img
                      class="iconsizer iconcenter zoom"
                      alt="african community, africa social learning app"
                      src="../Images/icon-dna.png"
                    />
                    <h5 class="fontB fontregular text-justify bolder text-center text-success">
                      {" "}
                      Community-Based. Socially Driven.
                    </h5>
                    <p class="fontbs fontsmaller text-justify">
                      {" "}
                      Engage with other users, share stories, ask questions and
                      contribute to Ga quizzes. We believe that is learning is
                      social! So let's socialize!
                    </p>
                  </div>
                </div>

                <div class="container-fluid white">
                  <div class="row">
                    {/*    <div class="col-md-12 col-sm-12 greybanner"></div> */}
                    <div class="col-md-12 easystepsorange">
                      <p class=" fontB fontwhite freeplatform text-center">
                        {" "}
                        Get Access & Start Learning Ga in 3 Simple Steps
                      </p>
                    </div>
                    {/* <!--    <div class="col-md-12 col-sm-12 greybanner"></div>--> */}
                  </div>

                  <div class="row">
                    <div class="col-md-7 threestepsdivs ">
                      <a href="Learn-Ga#choose_language_jump_link"></a>
                      <ScrollableAnchor id="choose_language_jump_link">
                        <Link
                          to="/course/Learn-Ga"
                          onclick="return false;"
                          style={{
                            textDecoration: "none",
                            color: "black",
                            cursor: "default",
                          }}
                        >
                          <h2
                            id="fontB"
                            class="zoomer fontB movedown2 paddingfordivs text-center mx-5"
                          >
                            <img
                              class="lineiconimg"
                              src={one}
                              alt="african language dialogue"
                            />{" "}
                            <span id="language-select"></span>
                            Select Ga Language & Culture
                          </h2>
                          <p class="fontbs nobreak text-justify fontsmall mx-5">
                            Dialogue Africa provides users with multiple options
                            for immersion and is expanding to include the major
                            languages and cultures across Ghana and the African
                            continent. Learn Ga today with our beta access pass.
                            Other African languages currently available are
                            Igbo, Twi, Yoruba and Swahili. In addition, Hausa
                            and Ewe are in the pipeline.
                          </p>
                          <div class="demostable">
                            <ul id="trydemoswrapper">
                              <li>
                                <a
                                  id="darkyellow"
                                  class="buttonmargin fontB fontregular btn btn-outline-warning my-2 my-sm-0"
                                  onClick={(e) => value.guestlogin(e, "ga")}
                                  href="javascript:void(0);"
                                >
                                  <img
                                    id="selectlangimage"
                                    src={ghanaflaglineicon}
                                  ></img>
                                  {/* {" "}
                                  🇬🇭{" "}
                                  <span id="try-ga-demo-1" class="fa fa-user">
                                    {" "}
                                  </span> */}{" "}
                                  Ga Beta
                                </a>
                              </li>
                              <li>
                                <a
                                  class="buttonmargin fontB fontregular btn btn-outline-success my-2 my-sm-0"
                                  onClick={(e) => value.guestlogin(e, "yoruba")}
                                  href="javascript:void(0);"
                                >
                                  <img
                                    id="selectlangimage"
                                    src={naijaflaglineicon}
                                  ></img>{" "}
                                  {/* {" "}
                                  🇳🇬{" "}
                                  <span
                                    id="try-wolof-demo-1"
                                    class="fa fa-user"
                                  ></span> */}{" "}
                                  Yoruba Beta{" "}
                                </a>
                              </li>
                              <li>
                                <button
                                  id="textorangeid"
                                  class="buttonmargin fontB fontregular btn btn-outline-warning my-2 my-sm-0"
                                  onClick={(e) => value.guestlogin(e, "twi")}
                                  href="javascript:void(0);"
                                >
                                  <img
                                    id="selectlangimage"
                                    src={ghanaflaglineicon}
                                  ></img>{" "}
                                  {/* 🇬🇭{" "}
                                  <span id="try-twi-demo-1" class="fa fa-user">
                                    {" "}
                                  </span>{" "}
                                  */}
                                  Twi Beta
                                </button>
                              </li>

                              <li>
                                <a
                                  class="buttonmargin fontB fontregular btn btn-outline-secondary my-2 my-sm-0"
                                  onClick={(e) =>
                                    value.guestlogin(e, "swahili")
                                  }
                                  href="javascript:void(0);"
                                >
                                  <img
                                    id="selectlangimage"
                                    src={tanzaniaflaglineicon}
                                  ></img>
                                  {/* {" "}
                                  🇹🇿{" "}
                                  <span
                                    id="try-swahili-demo-1"
                                    class="fa fa-user"
                                  >
                                    {" "}
                                  </span> */}{" "}
                                  Swahili Beta
                                </a>
                              </li>

                              <li>
                                <a
                                  id="textsoftredid"
                                  class="buttonmargin fontB fontregular btn btn-outline-danger my-2 my-sm-0"
                                  onClick={(e) => value.guestlogin(e, "igbo")}
                                  href="javascript:void(0);"
                                >
                                  <img
                                    id="selectlangimage"
                                    src={naijaflaglineicon}
                                  ></img>{" "}
                                  Igbo Beta{" "}
                                </a>
                              </li>
                              {/* <li>
                                <a
                                  class="fontB fontregular btn btn-outline-info my-2 my-sm-0"
                                  onClick={(e) =>
                                    value.guestlogin(
                                      e,
                                      "kinyarwanda"
                                    )
                                  }
                                  href="javascript:void(0);"
                                >
                                  <span class="fa fa-user"> </span> Try
                                  Kinyarwanda Beta
                                </a>
                                </li> */}
                            </ul>
                          </div>
                        </Link>
                      </ScrollableAnchor>
                    </div>

                    <div class="col-md-5">
                      <img
                        class="img-fluid chooselanguage center-block my-5 px-3"
                        alt="Ga language, Twi, Hausa, Fante, Igbo, Swahili, Amharic, Shona"
                        src="../Images/learn-ga-language-select-dialogue-africa.svg"
                        // {chooselangimg}
                      />
                    </div>
                  </div>
                </div>

                <div class="container-fluid threestepsdivs grey">
                  <div class="row">
                    <div class="col-md-7">
                      <img
                        class="img-fluid my-5 pl-3"
                        alt="africa business fashion travel tourism family tour invest africa study art app"
                        src="../Images/study-focus-graphics-kente.png"
                      />
                    </div>

                    <div class="col-md-5 ">
                      <h2 class="zoomer bold fontB movedownfocus text-center mx-5">
                        <img
                          class="lineiconimg"
                          src={two}
                          alt="africa language for business travel"
                        />{" "}
                        Choose Ga Lesson Topic
                      </h2>
                      <p class=" text-justify fontbs studyfocuschoice fontsmall mx-5">
                        {" "}
                        Learn Ga language and culture in a manner that is
                        immediately useful. We want to help you learn Ga phrases
                        that will quickly help you build better relationships,
                        convey your own unique thoughts and navigate realistic
                        and culturally accurate situations.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="container-fluid white threestepsdivs westafricanpov ">
                  <div class="row">
                    <div class="col-md-6">
                      <h2 class="zoomer fontB text-center bold paddingfordivs movedown2 mx-3">
                        <img
                          class="lineiconimg"
                          src={three}
                          alt="learn africa"
                        />{" "}
                        Start Learning Ga!
                      </h2>
                      <p class=" text-justify fontbs fontsmall pchooseyourlanguage mx-5">
                        {" "}
                        Begin learning with the early versions of our Ga
                        language and culture courses. We continue to develop our
                        platform and lessons to best serve our users. Remember
                        to give us some
                        <a
                          id="homepage_feedback"
                          class="bold text-info"
                          alt="africa platform app"
                          href={
                            "https://brown.co1.qualtrics.com/jfe/form/SV_cOMTf4CUsSf5iyp"
                          }
                        >
                          {" "}
                          feedback{" "}
                        </a>
                        so we can innovate and build with you in mind!{" "}
                      </p>
                    </div>

                    <div class="col-md-6">
                      <img
                        class=" d-block mx-auto img-fluid my-5"
                        src={manonkeyboardsmall}
                        alt="learn twi: travel and speak akan with Ga language app "
                      />
                      {!value.isLoggedIn ? (
                        <Link
                          to={{
                            pathname: "/signup",
                            state: {
                              from: "/",
                            },
                          }}
                          to="/signup"
                          id="thinbuttonextrastyle"
                          class="fontB fontregular d-block mx-auto btn btn-danger center-block westafrican "
                        >
                          Ga Beta Sign Up
                        </Link>
                      ) : (
                        <a
                          onClick={(e) => {
                            value.guestlogin(e, "ga");
                          }}
                          href="javascript:void(0);"
                          id="thinbuttonextrastyle"
                          class="fontB fontregular d-block mx-auto btn btn-danger center-block westafrican "
                        >
                          {value.isLoggedIn
                            ? "Continue to Ga Beta"
                            : "Ga Beta Sign Up"}
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/*<!--Footer-->*/}
              <footer class="fontB footer page-footer font-small pt-4 mt-4">
                {/*<!--Footer Links-->*/}
                <div class="container-fluid text-center">
                  <div class="row">
                    {/*<!--First column-->*/}
                    <div class="col-md-6">
                      <h5 class="text-uppercase fontbs">Connect with us</h5>
                      <a href="https://facebook.com/DialogueAfricaPlatform">
                        <img
                          id="fbmediaicon"
                          class="img-fluid"
                          alt="africa language culture facebook"
                          src={facebookicon}
                        />
                      </a>
                      <a href="/blog-main.html">
                        <img
                          id="blogicon"
                          class="img-fluid"
                          alt="africa language culture blog"
                          src={blogicon}
                        />
                      </a>

                      <a href="https://instagram.com/dialogue_africa">
                        <img
                          id="socialmediaicon"
                          class="img-fluid"
                          alt="africa language culture instagram"
                          src="../Images/instagramicon.PNG"
                        />
                      </a>

                      <a href="https://medium.com/@dialogueafrica">
                        <img
                          id="mediumicon"
                          class="img-fluid"
                          alt="africa language culture patreon donate"
                          src="../Images/mediumicon.png"
                        />
                      </a>
                    </div>

                    {/*<!--Second column-->*/}
                    <div class="col-md-6">
                      <h5 class="text-uppercase fontbs">Available Betas</h5>
                      <ul class="list-unstyled fontbs">
                        <li>
                          <Link
                            class="fontB fontregular"
                            id="footertext"
                            onClick={(e) => value.guestlogin(e, "ga")}
                            to="javascript:void(0);"
                          >
                            {" "}
                            Ga Beta{" "}
                          </Link>
                        </li>
                        <li>
                          <Link
                            class="fontB fontregular"
                            id="footertext"
                            onClick={(e) => value.guestlogin(e, "yoruba")}
                            to="javascript:void(0);"
                          >
                            {" "}
                            Yoruba Beta{" "}
                          </Link>
                        </li>
                        <li>
                          <Link
                            class="fontB fontregular"
                            id="footertext"
                            onClick={(e) => value.guestlogin(e, "twi")}
                            to="javascript:void(0);"
                          >
                            {" "}
                            Twi Beta{" "}
                          </Link>
                        </li>

                        <li>
                          <Link
                            class="fontB fontregular"
                            id="footertext"
                            onClick={(e) => value.guestlogin(e, "swahili")}
                            to="javascript:void(0);"
                          >
                            {" "}
                            Swahili Beta{" "}
                          </Link>
                        </li>
                        <li>
                          <Link
                            class="fontB fontregular"
                            id="footertext"
                            onClick={(e) => value.guestlogin(e, "igbo")}
                            to="javascript:void(0);"
                          >
                            {" "}
                            Igbo Beta{" "}
                          </Link>
                        </li>
                      </ul>
                    </div>
                    {/*<!--/.Second column-->*/}
                  </div>
                </div>
                {/*<!--/.Footer Links-->*/}

                {/*<!--Copyright-->*/}
                <div class="footer-copyright py-3 text-center">
                  <p class="fontB fontregular" id="footertext">
                    Made with{" "}
                    <span class="heart" style={{ color: "#ff0d4e" }}>
                      ♥
                    </span>{" "}
                    by Dialogue Africa.{" "}
                    {/* <span class="heart" style={{ color: "#794121" }}>
                      ♥
                    </span>
                    <span class="heart" style={{ color: "#130c0e" }}>
                      ♥
                    </span>{" "} */}
                  </p>

                  <a href="mailto:info@dialogue-africa.com">
                    <p class="fontB fontregular" id="footertext">
                      <span class="fa fa-envelope" aria-hidden="true"></span>{" "}
                      info@dialogue-africa.com{" "}
                    </p>
                  </a>
                  <p id="footertext" class="fontB fontregular">
                    <span class="fa fa-copyright"></span> 2021 Copyright:
                    Dialogue Africa. All rights reserved.{" "}
                    <a
                      id="footertext"
                      class="fontB fontregular"
                      href="privacy-policy.html"
                    >
                      Privacy Policy
                    </a>
                    {". "}
                    <a
                      id="footertext"
                      class="fontB fontregular"
                      href="terms-and-conditions.html"
                    >
                      Terms & Conditions
                    </a>{" "}
                  </p>
                </div>
                {/*<!--/.Copyright-->*/}
              </footer>
              {/*<!--/.Footer-->*/}

              <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.4/jquery.min.js"></script>
              <script
                src="https://code.jquery.com/jquery-3.2.1.slim.min.js"
                integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN"
                crossorigin="anonymous"
              ></script>
              <script
                src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js"
                integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q"
                crossorigin="anonymous"
              ></script>
              <script
                src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js"
                integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl"
                crossorigin="anonymous"
              ></script>
            </body>
          </html>
        )}
      </UserConsumer>
    );
  }
}

export default GaSelectlanguage;
