import React, { Component } from 'react';
import Choices   from './Choices'

class Question extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mute: false,
    }
  }

  render() {
    var question_text = ""
    var specificQuestion = this.props.currentQuestion;
    if (this.props.auto_generated_choices) {
      question_text = this.props.questionObject.question_instruction;
    } else {
      question_text = this.props.questionObject.text;
    }
    return (
      <div>
        <p className = "questiontext_one">{question_text}</p>
        <div>{
            <Choices
              mute = {this.state.mute}
              incQN = {this.props.incQN}
              incS={this.props.incS} currentQuestion = {this.props.currentQuestion}
              questionObject = {this.props.questionObject} questionText={question_text}
              idx = {this.props.questionObject.id} setSelectedChoice = {this.props.setSelectedChoice}
              setCurrentQuestionObject = {this.props.setCurrentQuestionObject}
              answerSubmitted = {this.props.answerSubmitted}
              checkAnswer = {this.props.checkAnswer}
              nextQuestion = {this.props.nextQuestion}
              auto_generated_choices = {this.props.auto_generated_choices}
              using_african_word = {this.props.using_african_word}
            />
        }</div>
      </div>
    );
  }
}
export default Question;
