import React, { Component } from "react";
import "./Choicesthree.css";

class ChoicesAlternative extends Component {
  constructor(props) {
    super(props);
    this.playChoiceAudio = this.playChoiceAudio.bind(this);
    this.getbuttonstyle = this.getbuttonstyle.bind(this);
    this.audio = new Audio();
    this.state = {
      phrase: this.props.questionObject.phrase,
      using_african_word: this.props.using_african_word,
      selectedChoiceObject: "",
      selectedChoiceId: "",
      tempChoiceId: "", //the choice id used for audio pronunciation - to allow pronunciation both before and after submission
      choices: !this.props.auto_generated_choices
        ? this.props.questionObject.choices
        : this.props.questionObject.auto_generated_choices.choices,
      correct_answer: !this.props.auto_generated_choices
        ? this.props.questionObject.correct_answer
        : this.props.questionObject.auto_generated_choices
            .correct_answer_option,
    };
  }

  handleChange(e) {

    // Updated parent quiz controller component with selected options
    const selected = e.target.value;
    this.props.setSelectedChoice(selected);
    this.props.setCurrentQuestionObject(this.props.questionObject);

    if (this.props.answerSubmitted == false) {
      this.setState({ selectedChoiceId: selected });
      this.setState({ tempChoiceId: selected });
    }
  }
  playChoiceAudio() {
    this.audio.pause();
    this.audio.src = this.getPhraseAudio();
    this.audio.play();
  }
  getbuttonstyle(choiceId) {
    let style =  "sentence_phrasebuttonstyle";
    var correcta = this.state.correct_answer === choiceId;
    var submitted = this.props.answerSubmitted === true;
    var selecteda = this.state.selectedChoiceId === choiceId;
    var anscorrect = submitted && correcta;
    var answrong = submitted && !correcta && selecteda;
    if (answrong) {
      style = "sentence_wrongansbutton_phrase";
    } else if (anscorrect) {
      style = "sentence_correctansbutton_phrase";
    } else if (choiceId == this.state.selectedChoiceId) {
      style =  "selectedchoicebuttonstyle_phrase";
    }
    return style;
  }
  getPhraseAlternative(num) {
    var mispell = "";
    switch (num) {
      case 1:
        mispell = this.state.phrase.disorder_one;
        break;
      case 2:
        mispell = this.state.phrase.disorder_two;
        break;
      case 3:
        mispell = this.state.phrase.disorder_three;
        break;
      default:
    }
    return mispell;
  }
  getPhraseNativeText() {
    return this.state.phrase.underlying_native_phrase
  }
  getPhraseMeaning(){
    return this.state.phrase.understood_meaning_source_phrase
  }
  getPhraseAudio() {
    return this.state.phrase.underlying_native_phrase_audio_url;
  }
  choiceDisplay(text, letter) {
    if (letter == this.state.correct_answer) {
      console.log("Correct Answer: ", letter);
      text = this.getPhraseMeaning();
    }

    // let cs = `choicebutton_three ${this.getbuttonstyle(letter)} sentence_phrasebuttonstyle`;

    return (
      <label className="sentence_choicethreeitem">
        <div
          className="choicebutton_three"
          id={this.getbuttonstyle(letter)}
          // className={cs}
        >
          <input
            onChange={this.handleChange.bind(this)}
            type="radio"
            name={this.props.questionObject.question_number}
            value={letter}
          />{" "}
          {this.smartAddPeriod(text)}
        </div>
      </label>
    );
  }

  smartAddPeriod(phrase){
    if (phrase.length > 0 && phrase[phrase.length - 1] !== "." && phrase[phrase.length - 1] !== "?") {
      return phrase + ".";
    }
    return phrase;
  }

  render() {
    return (
      <div>
        <div className="choicethreeitemcollection">
          {["a", "b", "c"].map((letter, index) => (
            this.choiceDisplay(this.getPhraseAlternative(index + 1), letter)
          ))}
        </div>
      </div>
    );
  }
}
export default ChoicesAlternative;
