import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import Root from "./Root";
import registerServiceWorker from "./registerServiceWorker";
import { UserProvider } from "./components/User/User.js";
import HttpsRedirect from "react-https-redirect";
import Analytics from "react-router-ga";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import FullStory from "react-fullstory";

// console.log("process.env: ", process.env);
if (process.env.NODE_ENV === "production") {
  console.log(
    "Running production-like settings in pipeline stage: %s",
    process.env.REACT_APP_DEV_PIPELINE_STAGE
  );
}
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePublishableKeyTest =
  "pk_test_51HbjhUIiIVCLXDURW7uYO7RrD2BFQi2Rak6jPNsGmM8HrzcJOzUTcrlLKXlPUYtnlI84txsCpLlevpz85z3b8Z5a00Czvy4W25";
const stripePublishableKeyLive =
  "pk_live_51HbjhUIiIVCLXDURJrNfA6U4iDNb8LsBuAqaJwJ4KGHiZvHyqjHgui4SABwtqKOiXRHfzxrYEje7ZPYCF5Grfm9c00Ntx0Otr4";
let stripePublishableKey = "";
if (process.env.REACT_APP_STRIPE_MODE === "test") {
  stripePublishableKey = stripePublishableKeyTest;
} else {
  stripePublishableKey = stripePublishableKeyLive;
}
const stripePromise = loadStripe(stripePublishableKey);
const fs_org_id = process.env.REACT_APP_FULLSTORY_ORG_ID;

ReactDOM.render(
  <HttpsRedirect>
    <BrowserRouter>
      <UserProvider>
        <FullStory org={fs_org_id} />
        <Analytics id="UA-118341536-1">
          <Elements stripe={stripePromise}>
            <Root />
          </Elements>
        </Analytics>
        {/* </FullStory> */}
      </UserProvider>
    </BrowserRouter>
  </HttpsRedirect>,
  document.getElementById("root")
);
registerServiceWorker();
